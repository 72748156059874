import { createSelector } from 'reselect';
import get from 'lodash/get';

import { SubscriptionCheckoutSession } from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): SubscriptionCheckoutSession => {
    const id = get(response, 'session.id', '');
    const subtotal = parseFloat(
      get(response, 'session.amount_subtotal', '0.00')
    );
    const total = parseFloat(get(response, 'session.amount_total', '0.00'));
    const name = get(response, 'session.shipping.name', '');
    const email = get(response, 'session.customer_details.email', '');
    const address = get(response, 'session.shipping.address', {});
    const productHandle = get(response, 'product.metadata.shopify_handle', '');
    const productName = get(response, 'product.name', '');
    const productPrice = parseFloat(
      get(response, 'subscription.plan.amount', '0.00')
    );
    const productQuantity = get(response, 'subscription.quantity', '');

    return {
      id,
      name,
      email,
      address,
      subtotal: (subtotal / 100).toFixed(2),
      total: (total / 100).toFixed(2),
      productHandle,
      productName,
      productPrice: (productPrice / 100).toFixed(2),
      productQuantity,
    };
  }
);
