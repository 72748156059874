import React from 'react';

import { Img } from 'components';

export interface IFoodMarquee {
  type: 'food_marquee';
}

const FoodMarquee: React.FC = () => (
  <div className="FoodMarquee col-12 mb3 md:mb8 overflow-hidden">
    <div className="FoodMarquee__image-container">
      <Img
        className="FoodMarquee__image col-12"
        src="/assets/images/for-the-love-of-food.svg"
        alt="For the love of Food."
      />
    </div>
  </div>
);

export default FoodMarquee;
