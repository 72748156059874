import { createSelector } from 'reselect';
import get from 'lodash/get';

import slices from 'state/serializers/slices';
import { GenericPage, Slice } from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): GenericPage => {
    const result = get(response, 'results[0]', {});
    const body: Slice[] = slices(get(result, 'data.body', []));

    return {
      title: get(result, 'data.title', ''),
      addHeroPadding: get(result, 'data.hero_padding', 'No') === 'No',
      backgroundColor: get(result, 'data.background_color', 'Default'),
      slices: body,
    };
  }
);
