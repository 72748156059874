import React from 'react';
import hashify from 'object-hash';

import { Slice } from 'types';
import {
  ImpactHeroCollage,
  ProductCollage,
  ProductGrid,
  Testimonials,
  ImageText,
  ImageCaption,
  PressLogos,
  FoodMarquee,
  ProductRecirculation,
  IconGrid,
  ImageCollageHero,
  RichText,
  Stockist,
  LinkableCarousel,
  Recirculation,
  FullWidthImage,
  Reads,
  Events,
  SignUpForm,
  WholesaleLogin,
  ContactUsForm,
  Accordions,
  TitleBlock,
  FullWidthImageCarousel,
  ShopBlock,
  KeepReading,
  Questionnaire,
  RichTextPicks,
  RichTextProducts,
  Recipe,
  YouTubeVideoEmbed,
  Videos,
  ReadsCollage,
} from 'components/slices';

interface Props {
  slices: Slice[];
}

const SliceSwitch: React.FC<Props> = ({ slices }) => {
  return (
    <>
      {slices.map((slice: Slice) => {
        switch (slice.type) {
          case 'hero_collage': {
            return <ImpactHeroCollage key={hashify(slice)} slice={slice} />;
          }
          case 'product_collage': {
            return <ProductCollage key={hashify(slice)} slice={slice} />;
          }
          case 'product_grid': {
            return <ProductGrid key={hashify(slice)} slice={slice} />;
          }
          case 'testimonials': {
            return <Testimonials key={hashify(slice)} slice={slice} />;
          }
          case 'image_text': {
            return <ImageText key={hashify(slice)} slice={slice} />;
          }
          case 'image_caption': {
            return <ImageCaption key={hashify(slice)} slice={slice} />;
          }
          case 'press_logos': {
            return <PressLogos key={hashify(slice)} slice={slice} />;
          }
          case 'food_marquee': {
            return <FoodMarquee key={hashify(slice)} />;
          }
          case 'product_recirculation': {
            return <ProductRecirculation slice={slice} key={hashify(slice)} />;
          }
          case 'icon_grid': {
            return <IconGrid slice={slice} key={hashify(slice)} />;
          }
          case 'image_collage_hero': {
            return <ImageCollageHero slice={slice} key={hashify(slice)} />;
          }
          case 'rich_text': {
            return <RichText slice={slice} key={hashify(slice)} />;
          }
          case 'code': {
            return <Stockist slice={slice} key={hashify(slice)} />;
          }
          case 'linkable_carousel': {
            return <LinkableCarousel slice={slice} key={hashify(slice)} />;
          }
          case '2-up_recirculation': {
            return <Recirculation slice={slice} key={hashify(slice)} />;
          }
          case 'full-width_image': {
            return <FullWidthImage slice={slice} key={hashify(slice)} />;
          }
          case 'reads': {
            return <Reads slice={slice} key={hashify(slice)} />;
          }
          case 'events': {
            return <Events slice={slice} key={hashify(slice)} />;
          }
          case 'sign-up_form': {
            return <SignUpForm slice={slice} key={hashify(slice)} />;
          }
          case 'wholesale_login': {
            return <WholesaleLogin slice={slice} key={hashify(slice)} />;
          }
          case 'contact-us_form': {
            return <ContactUsForm slice={slice} key={hashify(slice)} />;
          }
          case 'accordions': {
            return <Accordions slice={slice} key={hashify(slice)} />;
          }
          case 'title_block': {
            return <TitleBlock slice={slice} key={hashify(slice)} />;
          }
          case 'full-width_image_carousel': {
            return (
              <FullWidthImageCarousel slice={slice} key={hashify(slice)} />
            );
          }
          case 'shop_block': {
            return <ShopBlock slice={slice} key={hashify(slice)} />;
          }
          case 'keep_reading': {
            return <KeepReading slice={slice} key={hashify(slice)} />;
          }
          case 'questionnaire': {
            return <Questionnaire slice={slice} key={hashify(slice)} />;
          }
          case 'rich_text_and_picks': {
            return <RichTextPicks slice={slice} key={hashify(slice)} />;
          }
          case 'rich_text___products': {
            return <RichTextProducts slice={slice} key={hashify(slice)} />;
          }
          case 'recipe': {
            return <Recipe slice={slice} key={hashify(slice)} />;
          }
          case 'youtube_video': {
            return <YouTubeVideoEmbed slice={slice} key={hashify(slice)} />;
          }
          case 'youtube_videos': {
            return <Videos slice={slice} key={hashify(slice)} />;
          }
          case 'reads_collage': {
            return <ReadsCollage slice={slice} key={hashify(slice)} />;
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );
};

export default SliceSwitch;
