import StripeClient, { StripeCheckoutData } from 'lib/StripeClient';

export const fetchSubscriptionProducts = () => ({
  type: 'FETCH_SUBSCRIPTION_PRODUCTS',
  payload: StripeClient.fetchProducts(),
});

export const fetchSubscriptionPrices = () => ({
  type: 'FETCH_SUBSCRIPTION_PRICES',
  payload: StripeClient.fetchPrices(),
});

export const createSubscriptionCheckout = (data: StripeCheckoutData) => ({
  type: 'CREATE_SUBSCRIPTION_CHECKOUT',
  payload: new Promise(async (resolve) => {
    const checkout = await StripeClient.createCheckout(data);
    return resolve(checkout);
  }),
});

export const fetchSubscriptionCheckoutSession = (id: string) => ({
  type: 'FETCH_SUBSCRIPTION_CHECKOUT_SESSION',
  payload: StripeClient.fetchCheckoutSession(id),
});
