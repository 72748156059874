export default {
  DC: {
    id: 'DC',
    slug: 'washington-dc',
    label: 'Washington DC',
  },
  NYC: {
    id: 'NYC',
    slug: 'new-york-city',
    label: 'New York City',
  },
  LA: {
    id: 'LA',
    slug: 'los-angeles',
    label: 'Los Angeles',
  },
  SF: {
    id: 'SF',
    slug: 'san-francisco',
    label: 'San Francisco',
  },
  DIGITAL: {
    id: 'DIGITAL',
    slug: 'digital',
    label: 'Digital',
  },
  COOKING: {
    id: 'COOKING',
    slug: 'cooking-class',
    label: 'Cooking Class',
  },
};
