import { ApplicationAction } from 'state/actions/types/appActionTypes';
import { GenericPage } from 'types';

export interface GenericPageReducer extends GenericPage {}
const initialState: GenericPageReducer = {
  title: '',
  addHeroPadding: false,
  backgroundColor: 'Default',
  slices: null,
};

export default (
  state: GenericPageReducer = initialState,
  action: ApplicationAction
): GenericPageReducer => {
  switch (action.type) {
    case 'FETCH_GENERIC_PAGE_FULFILLED':
      return {
        ...state,
        ...action.payload,
      } as GenericPageReducer;
    default:
      return state;
  }
};
