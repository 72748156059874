import React from 'react';
import { BagIconFilled } from './icons';

interface Props {
  quantity: number;
  color: string;
  textColor: string;
}

const BagNumber: React.FC<Props> = ({ quantity, color, textColor }) => (
  <div className="BagNumber relative text-center">
    <div className="BagNumber__icon-container absolute t0 l0 col-12">
      <BagIconFilled fill={color} className="BagNumber__icon" />
    </div>
    <div
      className={`BagNumber__quantity relative color-${textColor} text-label z-1`}
    >
      {quantity}
    </div>
  </div>
);

export default BagNumber;
