import { createSelector } from 'reselect';

import { CheckoutReducer } from 'state/reducers/checkout';
import products from 'state/selectors/products';
import {
  GlobalState,
  Product,
  LineItem,
  MergedLineItem,
  ShopifyProductVariant,
} from 'types';

export default createSelector(
  (state: GlobalState): { [key: string]: Product } | null => products(state),
  (state: GlobalState): CheckoutReducer => state.checkout,
  (
    products: { [key: string]: Product } | null,
    checkout: CheckoutReducer
  ): MergedLineItem[] => {
    if (!products) return [];

    const activeLineItems = checkout.checkout.lineItems.filter((item) => {
      return item.productHandle in products;
    });

    return activeLineItems.map(
      (lineItem: LineItem): MergedLineItem => {
        const product: Product = products[lineItem.productHandle];
        const variant: ShopifyProductVariant =
          product.variants.find((variant: ShopifyProductVariant) => {
            return variant.id === lineItem.variantId;
          }) || product.variants[0];

        const image = product.content.lineItemImage.src
          ? product.content.lineItemImage
          : variant.image;

        return {
          ...lineItem,
          image,
          title: product.title,
          url: product.url,
          price: variant.price,
          isFreeGift: product.content.isFreeGift,
        };
      }
    );
  }
);
