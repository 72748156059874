import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const StarBurst: React.FC<Props> = ({ className = '', fill = '#F2F2E8' }) => {
  return (
    <svg
      className={className}
      width="53"
      height="50"
      viewBox="0 0 53 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6495 0L28.7819 11.1397L35.1726 1.36749L33.901 12.8412L43.5391 5.9433L38.0336 16.2021L49.6201 13.1121L40.6233 20.7674L52.588 21.9034L41.3194 25.9217L52.0482 31.1287L40.0259 30.9709L48.0718 39.5466L36.921 35.2312L41.196 46.0159L32.4212 38.1265L32.3472 49.6634L27.1376 39.2652L22.7255 50L21.78 38.4973L13.6245 46.9757L17.072 35.9228L6.27737 41.0035L13.6546 31.8913L1.67344 32.8854L11.983 26.9447L0.4375 23.7206L12.2872 21.7562L2.73399 14.7452L14.5234 17.0226L8.25597 7.17404L18.3901 13.3829L16.2526 2.02756L23.3695 11.3291L25.6495 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default StarBurst;
