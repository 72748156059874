import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ContainerBase from 'lib/ContainerBase';

import products from 'state/selectors/products';

import HomeView from 'views/HomeView';

import { GlobalState, Product } from 'types';

interface StoreProps {
  products: { [key: string]: Product } | null;
}

interface DispatchProps {}

type Props = RouteComponentProps & StoreProps & DispatchProps;

class HomeContainer extends ContainerBase<Props, typeof HomeView> {
  view = import('views/HomeView');
}

const mapStateToProps = (state: GlobalState) => ({
  products: products(state),
  homepage: state.homepage,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeContainer));
