import ContentClient from 'lib/ContentClient';

export const fetchReads = () => ({
  type: 'FETCH_READS',
  payload: ContentClient.fetchReads(),
});

export const fetchRead = (slug: string) => ({
  type: 'FETCH_READ',
  payload: ContentClient.fetchRead(slug),
});

export const fetchLatestReads = (
  pageSize: number,
  page: number,
  category?: string
) => ({
  type: 'FETCH_LATEST_READS',
  payload: ContentClient.fetchLatestReads(pageSize, page, category),
});
