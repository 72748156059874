import React, { Component } from 'react';
import { connect } from 'react-redux';
import jsonp from 'jsonp';

import { Button, TextField } from 'components';
import { GlobalState, Status } from 'types';
import { SettingsReducer } from 'state/reducers/settings';

interface State {
  emailAddress: string;
  firstName: string;
  lastName: string;
  status: Status;
  responseMsg: Error | string | null;
  showErrorMsg: boolean;
  showSuccessMsg: boolean;
}

interface StateProps {
  settings: SettingsReducer;
}

interface Props extends StateProps {
  listId: string | null;
}

class Mailchimp extends Component<Props, State> {
  private submitButton = React.createRef<HTMLButtonElement>();

  state: State = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    status: Status.IDLE,
    responseMsg: null,
    showErrorMsg: false,
    showSuccessMsg: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { status, responseMsg } = this.state;

    if (prevState.status === Status.PENDING && status === Status.FULFILLED) {
      this.setState({
        emailAddress: '',
        firstName: '',
        lastName: '',
        showSuccessMsg: true,
        showErrorMsg: false,
      });
      setTimeout(() => this.setState({ showSuccessMsg: false }), 3000);
    }

    if (prevState.status === Status.PENDING && status === Status.REJECTED) {
      this.setState({ showErrorMsg: true, showSuccessMsg: false });
      setTimeout(() => this.setState({ showErrorMsg: false }), 3000);
    }

    if (prevState.responseMsg !== responseMsg) {
      console.log(responseMsg);
    }
  }

  focus = () => {
    if (this.submitButton.current && this.submitButton.current.focus)
      this.submitButton.current.focus();
  };

  subscribe = () => {
    const listId = this.props.listId || this.props.settings.mailchimpListId;

    if (listId) {
      const url =
        listId.replace('/post?', '/post-json?') +
        `&EMAIL=${this.state.emailAddress}&FNAME=${this.state.firstName}&LNAME=${this.state.lastName}`;
      this.setState({ status: Status.PENDING });

      jsonp(
        url,
        {
          param: 'c',
        },
        (err, data) => {
          if (err) {
            this.setState({
              status: Status.REJECTED,
              responseMsg: err,
            });
            this.focus();
          } else if (data.result !== 'success') {
            if (data.msg.includes('subscribed')) {
              this.setState({
                status: Status.FULFILLED,
                responseMsg: null,
              });
              this.focus();
            } else {
              this.setState({
                status: Status.REJECTED,
                responseMsg: data.msg,
              });
              this.focus();
            }
          } else {
            this.setState({
              status: Status.FULFILLED,
              responseMsg: data.msg,
            });
            this.focus();
          }
        }
      );
    }
  };

  getButtonMessage = (): string => {
    const { showErrorMsg, showSuccessMsg } = this.state;

    if (showErrorMsg) {
      return 'Something went wrong, try again';
    } else if (showSuccessMsg) {
      return 'Subscribed!';
    }

    return 'Subscribe';
  };

  getAriaLabel = (): string => {
    const { showErrorMsg, showSuccessMsg } = this.state;

    if (showErrorMsg) {
      return 'Something went wrong, try again';
    } else if (showSuccessMsg) {
      return 'You are subscribed';
    }

    return 'Subscribe to newsletter';
  };

  render() {
    const { emailAddress, firstName, lastName } = this.state;

    return (
      <div className="Mailchimp col-12">
        <TextField
          className="col-12 mb1"
          name="email"
          ariaLabel="Enter email"
          label="Email"
          variant="primary"
          placeholder="hello@example.com"
          type="email"
          onChange={(value) => this.setState({ emailAddress: value as string })}
          value={emailAddress}
        />
        <TextField
          className="col-12 mb1"
          name="first_name"
          ariaLabel="Enter first name"
          label="First Name"
          variant="primary"
          placeholder="Pineapple"
          type="text"
          onChange={(value) => this.setState({ firstName: value as string })}
          value={firstName}
        />
        <TextField
          className="col-12 mb2"
          name="last_name"
          ariaLabel="Enter last name"
          label="Last Name"
          variant="primary"
          placeholder="Collaborative"
          type="text"
          onChange={(value) => this.setState({ lastName: value as string })}
          value={lastName}
        />
        <Button
          className="col-12"
          onClick={() => {
            this.subscribe();
          }}
          variant="primary"
          ariaLabel={this.getAriaLabel()}
          label={this.getButtonMessage()}
          elemRef={this.submitButton}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(Mailchimp);
