import { createSelector } from 'reselect';

import PageSize from 'constants/PageSize';
import { GlobalState, Read } from 'types';

export default createSelector(
  (state: GlobalState): Read | null => {
    return state.reads.read;
  },
  (state: GlobalState): Read[] | null => {
    return state.reads.reads;
  },
  (currentRead: Read | null, reads: Read[] | null): Read[] => {
    if (!reads) return [];
    if (!currentRead) return reads;

    const readsSansCurrent = reads.filter(
      (read) => currentRead.slug !== read.slug
    );

    return readsSansCurrent.slice(0, PageSize.KEEP_READING);
  }
);
