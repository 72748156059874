import React, { Component } from 'react';
import Flickity from 'react-flickity-component';
import marked from 'marked';

import { Img, Button } from 'components';
import { Image } from 'types';
import prismicImageUtil from 'utils/prismicImageUtil';

export interface IFullWidthImageCarousel {
  type: 'full-width_image_carousel';
  data: {
    images: Image[];
    autoplay: boolean;
  };
}

interface Props {
  slice: IFullWidthImageCarousel;
}

class FullWidthImageCarousel extends Component<Props> {
  private flickity: any = null;

  handleNext = (): void => {
    if (this.flickity && this.flickity.next) this.flickity.next();
  };

  handlePrevious = (): void => {
    if (this.flickity && this.flickity.previous) this.flickity.previous();
  };

  render() {
    const { slice } = this.props;

    return (
      <div className="FullWidthImageCarousel col-12 relative mb2 md:mb4 overflow-hidden">
        {slice.data.images.length !== 1 && !slice.data.autoplay && (
          <div className="FullWidthImageCarousel__controls">
            <Button
              className="FullWidthImageCarousel__controls--previous"
              variant="no-style"
              ariaLabel="Go back to previous image"
              onClick={this.handlePrevious}
            ></Button>
            <Button
              className="FullWidthImageCarousel__controls--next"
              variant="no-style"
              ariaLabel="Advance to next image"
              onClick={this.handleNext}
            ></Button>
          </div>
        )}
        <Flickity
          flickityRef={(c) => (this.flickity = c)}
          options={{
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            adaptiveHeight: true,
            autoPlay: slice.data.autoplay,
          }}
        >
          {slice.data.images.map((image) => (
            <div
              className="FullWidthImageCarousel__container col-12 relative"
              key={image.src}
            >
              <Img
                className="FullWidthImageCarousel__desktop col-12 h100"
                src={prismicImageUtil(image.src, '1900', 'pjpg')}
                alt={image.caption || ''}
              />
              <div className="col-12 py_5 items-start justify-between flex flex-wrap px1_5">
                <div
                  className="col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(image.caption || ''),
                  }}
                />
                <div
                  className="pl_5 md:pl1 text-right col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(image.credit || ''),
                  }}
                />
              </div>
            </div>
          ))}
        </Flickity>
        {slice.data.images.length !== 1 && !slice.data.autoplay && (
          <div className="FullWidthImageCarousel__controls--mobile">
            <Button
              className="FullWidthImageCarousel__controls__button FullWidthImageCarousel__controls__previous mx1"
              variant="no-style"
              ariaLabel="Go back to previous image"
              onClick={this.handlePrevious}
            >
              <Img alt="Left Arrow" src="/assets/images/squared-arrow.svg" />
            </Button>
            <Button
              className="FullWidthImageCarousel__controls__button FullWidthImageCarousel__controls__next mx1"
              variant="no-style"
              ariaLabel="Advance to next image"
              onClick={this.handleNext}
            >
              <Img alt="Right Arrow" src="/assets/images/squared-arrow.svg" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default FullWidthImageCarousel;
