import React, { useState, useRef } from 'react';

import { RichText as PrismicRichText } from 'prismic-reactjs';
import emailjs from 'emailjs-com';
import cx from 'classnames';
import EmailValidator from 'email-validator';

import { Button, TextField, Dropdown } from 'components';

export interface IContactUsForm {
  type: 'contact-us_form';
  data: {
    title: string;
    description: string;
  };
}

interface Props {
  slice: IContactUsForm;
}

const ContactUsForm: React.FC<Props> = ({ slice }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [buttonLabel, setButtonLabel] = useState('Contact us');
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const submitButton = useRef<HTMLButtonElement>(null);

  const focus = () => {
    if (submitButton.current && submitButton.current.focus) {
      submitButton.current.focus();
    }
  };

  const validate = () => {
    const validEmail = EmailValidator.validate(email);
    setEmailError(!validEmail);
    setNameError(!name);
    setCategoryError(!category);
    setSubjectError(!subject);
    setMessageError(!message);

    if (!name || !email || !category || !subject || !message || !validEmail) {
      setButtonLabel('Fix errors and try again');
      focus();
      return false;
    } else {
      return true;
    }
  };

  const clearForm = () => {
    setEmail('');
    setName('');
    setCategory('');
    setSubject('');
    setMessage('');
  };

  const sendEmail = () => {
    if (!validate()) return;
    setButtonLabel('Sending…');

    const serviceID = 'service_tg34qbn';
    const templateID = 'template_xbykfna';
    const userID = 'user_c8LS919L250tBBrgOzOog';

    emailjs
      .send(
        serviceID,
        templateID,
        {
          email,
          name,
          category,
          subject,
          message,
        },
        userID
      )
      .then(
        (result) => {
          setButtonLabel('Sent!');
          focus();
          clearForm();
        },
        (error) => {
          setButtonLabel('Something went wrong, try again');
          focus();
          console.log(error.text);
        }
      );
  };

  const dropdownOptions = [
    'Just Saying Hi',
    'Wholesale',
    'Press',
    'Paid Partnership',
    'Collaboration',
    'Order Issues',
  ];

  return (
    <div className="ContactUsForm col-12 flex flex-wrap container-width mxauto px1_5 md:px2 justify-center pt2 mb3 md:mb8 text-center">
      <div className="col-12 md:col-6 mxauto">
        <h1 className="ContactUsForm__title mb1">{slice.data.title}</h1>
        {!!slice.data.description && (
          <div className="ContactUsForm__description text-medium bell mb1">
            <PrismicRichText render={slice.data.description} />
          </div>
        )}

        <div className="ContactUsForm--form col-12">
          <TextField
            className="col-12 mb1"
            name="email"
            ariaLabel={
              emailError ? 'Enter valid email, email is invalid' : 'Enter Email'
            }
            label="Your Email"
            variant="primary"
            placeholder="hello@example.com"
            type="email"
            onChange={(value) => setEmail(value as string)}
            value={email}
            errorMessage={emailError ? 'Invalid email' : ''}
          />
          <TextField
            className="col-12 mb1"
            name="name"
            ariaLabel="Enter name: Required"
            label="Name"
            variant="primary"
            placeholder="Pineapple Collaborative"
            type="text"
            onChange={(value) => setName(value as string)}
            value={name}
            errorMessage={nameError ? 'Required' : ''}
          />
          <Dropdown
            className={cx('col-12 mb1', { 'show-errors': categoryError })}
            name="category"
            ariaLabel="Choose category: Required"
            label="Category"
            variant="primary"
            options={dropdownOptions}
            onChange={(value) => setCategory(value as string)}
            value={category}
            errorMessage={categoryError ? 'Required' : ''}
          />
          <TextField
            className={cx('col-12 mb1', { 'show-errors': subjectError })}
            name="subject"
            ariaLabel="Enter subject: Required"
            label="Subject"
            variant="primary"
            placeholder="What's this about?"
            type="text"
            onChange={(value) => setSubject(value as string)}
            value={subject}
            errorMessage={subjectError ? 'Required' : ''}
          />
          <TextField
            className={cx('col-12 mb1', { 'show-errors': messageError })}
            name="message"
            ariaLabel="Enter message: Required"
            label="Message"
            variant="primary"
            placeholder=""
            type="textarea"
            onChange={(value) => setMessage(value as string)}
            value={message}
            errorMessage={messageError ? 'Required' : ''}
          />
          <Button
            className="col-12"
            onClick={sendEmail}
            variant="primary"
            ariaLabel={buttonLabel}
            label={buttonLabel}
            elemRef={submitButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
