import { ApplicationAction } from 'state/actions/types/appActionTypes';
import { Homepage } from 'types';

export interface HomepageReducer extends Homepage {}
const initialState: HomepageReducer = {
  addHeroPadding: false,
  slices: null
};

export default (
  state: HomepageReducer = initialState,
  action: ApplicationAction
): HomepageReducer => {
  switch (action.type) {
    case 'FETCH_HOMEPAGE_FULFILLED':
      return {
        ...state,
        ...action.payload
      } as HomepageReducer;
    default:
      return state;
  }
};
