import React, { Component } from 'react';

import { Mailchimp } from 'components';

export interface ISignUpForm {
  type: 'sign-up_form';
  data: {
    title: string;
    description: string;
    listId: string;
  };
}

interface Props {
  slice: ISignUpForm;
}

class SignUpForm extends Component<Props> {
  render() {
    const { slice } = this.props;

    return (
      <div className="SignUpForm col-12 flex flex-wrap container-width mxauto px1_5 md:px2 justify-center pt2 mb3 md:mb8 text-center">
        <div className="col-12 md:col-6 mxauto">
          <h2 className="SignUpForm__title mb1">{slice.data.title}</h2>
          {!!slice.data.description && (
            <p className="SignUpForm__description text-medium bell mb1">
              {slice.data.description}
            </p>
          )}
          <Mailchimp listId={slice.data.listId} />
        </div>
      </div>
    );
  }
}

export default SignUpForm;
