import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import get from 'lodash/get';
import cx from 'classnames';

import { Img, StarBurst } from 'components';
import { Read, GlobalState } from 'types';

import reads from 'state/selectors/reads';
import prismicImageUtil from 'utils/prismicImageUtil';

interface ReadCollageItem {
  read: string;
  buttonText: string;
}

export interface IReadsCollage {
  type: 'reads_collage';
  data: {
    title: string;
    description: string;
    showDecorativeStars: boolean;
    textAlignment: 'Left' | 'Center';
    reads: ReadCollageItem[];
  };
}

interface StateProps {
  reads: { [key: string]: Read } | null;
}

interface Props extends StateProps {
  slice: IReadsCollage;
}

const ReadsCollage: React.FC<Props> = ({ slice, reads }) => {
  return (
    <div className="ReadsCollage col-12 container-width mxauto px2 mb2 md:pb4 md:mb8 flex flex-col relative">
      {slice.data.showDecorativeStars && (
        <StarBurst className="ReadsCollage__starburst__left" fill="#ccb16a" />
      )}
      <div className="ReadsCollage__info text-center md:mb6 mb3">
        <h2 className="text-large mb_5">{slice.data.title}</h2>
        {!slice.data.description && (
          <div className="mxauto md:col-6 col-12 bell text-medium">
            <PrismicRichText render={slice.data.description} />
          </div>
        )}
      </div>

      <div
        className={cx('ReadsCollage__reads transition', {
          'opacity-0': !reads,
        })}
      >
        {slice.data.reads.map((item: ReadCollageItem, i: number) => {
          const read: Read | null = get(reads, item.read, null);

          if (!read) return null;

          return (
            <div
              key={item.read}
              className="ReadsCollage__reads__read col-12 relative"
            >
              <Link
                className="ReadsCollage__reads__read__link relative no-style no-hover block"
                to={read.url}
              >
                <Img
                  className="ReadsCollage__reads__read__image block mb1 col-12"
                  src={prismicImageUtil(
                    read.image.src,
                    '960',
                    read.image.src.includes('.gif') ? 'gif' : 'pjpg'
                  )}
                  alt={read.image.caption || ''}
                />
              </Link>
              <div
                className={cx({
                  'text-center': slice.data.textAlignment === 'Center',
                })}
              >
                <h3 className="ReadsCollage__reads__read__title my_5 mt1">
                  {read.title}
                </h3>
                {item.buttonText && (
                  <Link className="inline-block cta mt_5" to={read.url}>
                    {item.buttonText}
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  reads: reads(state),
});

export default connect(mapStateToProps)(ReadsCollage);
