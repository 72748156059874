import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';

import ContainerBase from 'lib/ContainerBase';

import {
  updateLineItems,
  updateLineItemsWithFreeGifts,
} from 'state/actions/checkoutActions';
import { createSubscriptionCheckout } from 'state/actions/subscriptionActions';
import products from 'state/selectors/products';

import ProductView from 'views/ProductView';

import { GlobalState, Product } from 'types';

interface StoreProps {
  products: { [key: string]: Product } | null;
}

interface DispatchProps {}

type Props = RouteComponentProps & StoreProps & DispatchProps;

class ProductContainer extends ContainerBase<Props, typeof ProductView> {
  view = import('views/ProductView');
}

const mapStateToProps = (state: GlobalState) => ({
  products: products(state),
  checkout: state.checkout,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      updateLineItems,
      updateLineItemsWithFreeGifts,
      createSubscriptionCheckout,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
