import get from 'lodash/get';

export const GA_TRACKING_ID = 'UA-110193888-1';
const gtag = get(window, 'gtag') || {};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  gtag('config', GA_TRACKING_ID, {
    page_location: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (config: {
  action: string;
  category: string;
  label: string;
  value: string;
}) => {
  const { action, category, label, value } = config;

  gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
