import { createSelector } from 'reselect';
import get from 'lodash/get';

import slices from 'state/serializers/slices';
import {
  PrismicProducts,
  PrismicProduct,
  VariantSpecificMessage,
  Image,
  Slice,
} from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): PrismicProducts => {
    const results = get(response, 'results', {});

    return results.map(
      (result: unknown): PrismicProduct => {
        const handle: string = get(result, 'uid', '');
        const title: string = get(result, 'data.title[0].text', '');
        const excerpt: string = get(result, 'data.excerpt[0].text', '');
        const description: any = get(result, 'data.description', '');
        const information: any = get(result, 'data.information', '');
        const inspiration: any = get(result, 'data.inspiration', '');
        const savingsPrice: string = get(
          result,
          'data.savings_price[0].text',
          ''
        );
        const subscriptionDescription: any = get(
          result,
          'data.subscription_description',
          ''
        );
        const heroButtonLabel: string = get(
          result,
          'data.hero_button_label[0].text',
          ''
        );
        const inventoryMessage: string = get(
          result,
          'data.inventory_message',
          ''
        );
        const showEmailCapture: boolean = get(
          result,
          'data.email_capture',
          false
        );
        const emailCaptureMailchimpListId = get(
          result,
          'data.email_capture_mailchimp_list_id[0].text',
          ''
        );
        const quickAdd: boolean = get(result, 'data.quick_add', false);
        const isFreeGift: boolean = get(result, 'data.is_free_gift', false);

        const variantSpecificMessages: VariantSpecificMessage[] = get(
          result,
          'data.variant_specific_messages',
          []
        ).map((variant: unknown) => ({
          variant: get(variant, 'variant_title', ''),
          buttonText: get(variant, 'button_text', ''),
          inventoryShippingMessage: get(
            variant,
            'inventory_shipping_message',
            ''
          ),
          quickAdd: get(variant, 'quick_add', false),
        }));

        const productImages: Image[] = get(
          result,
          'data.product_images',
          []
        ).map((productImage: unknown) => ({
          src: get(productImage, 'image.url', ''),
          credit: get(productImage, 'image.copyright', ''),
          caption: get(productImage, 'caption[0].text', ''),
          captionUrl: get(productImage, 'caption[0].spans[0].data.url', ''),
          variantTitle: get(productImage, 'variant_title[0].text', ''),
        }));

        const makeImageSquare: boolean = get(
          result,
          'data.make_image_square',
          false
        );

        const gridImage: Image = {
          src: get(result, 'data.grid_image.url', ''),
          credit: get(result, 'data.grid_image.copyright', ''),
          caption: get(result, 'data.grid_image.alt', ''),
        };
        const lineItemImage: Image = {
          src: get(result, 'data.line_item_image.url', ''),
          credit: get(result, 'data.line_item_image.copyright', ''),
          caption: get(result, 'data.line_item_image.alt', ''),
        };
        const subscriptionSticker: Image = {
          src: get(result, 'data.subscription_sticker.url', ''),
          credit: get(result, 'data.subscription_sticker.copyright', ''),
          caption: get(result, 'data.subscription_sticker.alt', ''),
        };
        const savingsSticker: Image = {
          src: get(result, 'data.savings_sticker.url', ''),
          credit: get(result, 'data.savings_sticker.copyright', ''),
          caption: get(result, 'data.savings_sticker.alt', ''),
        };

        const body: Slice[] = slices(get(result, 'data.body', []));

        return {
          handle,
          title,
          excerpt,
          description,
          information,
          inspiration,
          subscriptionDescription,
          productImages,
          gridImage,
          makeImageSquare,
          quickAdd,
          isFreeGift,
          showEmailCapture,
          emailCaptureMailchimpListId,
          variantSpecificMessages,
          lineItemImage,
          subscriptionSticker,
          savingsPrice,
          savingsSticker,
          heroButtonLabel,
          inventoryMessage,
          slices: body,
        };
      }
    );
  }
);
