import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import products from 'state/selectors/products';
import { Img } from 'components';

import { GlobalState, Product } from 'types';

export interface IProductRecirculation {
  type: 'product_recirculation';
  data: {
    title: string;
    description: string;
    product: string;
    buttonText: string;
  };
}

interface StateProps {
  products: { [key: string]: Product } | null;
}

interface Props extends StateProps {
  slice: IProductRecirculation;
}

const ProductRecirculation: React.FC<Props> = ({ slice, products }) => {
  if (!products) return null;

  const product = products[slice.data.product];

  return (
    <div className="ProductRecirculation col-12 container-width mxauto mb3 md:mb8 px2">
      <h2 className="text-large text-center mb2">You might also like</h2>
      <div className="col-12 md:col-8 flex flex-wrap items-center bg-color-brown mxauto radius-xs overflow-hidden">
        <div className="col-12 md:col-4">
          <Img
            className="col-12 block"
            src={product.content.gridImage.src}
            alt={product.content.gridImage.caption || ''}
          />
        </div>
        <div className="col-12 md:col-8 py1_5 px1_5 md:py0 md:px3 color-white">
          <span className="block text-large">{slice.data.title}</span>
          <span className="block my1 pr1 text-medium">
            {slice.data.description}
          </span>
          <Link className="cta white" to={product.url}>
            {slice.data.buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  products: products(state)
});

export default connect(mapStateToProps)(ProductRecirculation);
