import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Subnav,
  EventsIcon,
  Img,
  Button,
  LeftArrowIcon,
  RightArrowIcon,
} from 'components';
import { GlobalState, PineappleEvent, Color } from 'types';
import { RouteConstants } from 'constants/RouteMap';

interface OwnProps {
  isActive: boolean;
}

interface StateProps {
  events: PineappleEvent[] | null;
}

type Props = OwnProps & StateProps;

interface State {
  eventIndex: number;
  eventIndexChangePending: boolean;
}

class EventsSubnav extends Component<Props, State> {
  state = {
    eventIndex: 0,
    eventIndexChangePending: false,
  };

  static INDEX_LIMIT = 2;

  incrementEventIndex = () => {
    this.setState({ eventIndexChangePending: true });

    setTimeout(() => {
      this.setState({
        eventIndex:
          this.state.eventIndex === EventsSubnav.INDEX_LIMIT
            ? 0
            : this.state.eventIndex + 1,
      });
    }, 200);

    setTimeout(() => {
      this.setState({ eventIndexChangePending: false });
    }, 400);
  };

  decrementEventIndex = () => {
    this.setState({ eventIndexChangePending: true });

    setTimeout(() => {
      this.setState({
        eventIndex: this.state.eventIndex
          ? this.state.eventIndex - 1
          : EventsSubnav.INDEX_LIMIT,
      });
    }, 200);

    setTimeout(() => {
      this.setState({ eventIndexChangePending: false });
    }, 400);
  };

  render() {
    const { isActive, events } = this.props;
    const { eventIndex, eventIndexChangePending } = this.state;

    return (
      <Subnav isActive={isActive}>
        <div className="EventsSubnav p1">
          <div className="EventsSubnav__content mb1 flex flex-wrap">
            <div className="EventsSubnav__content__links col-5">
              <div className="flex items-center mb2">
                <EventsIcon className="mr_5" fill={Color.BROWN} />
                <span className="viksjoe text-small color-brown uppercase">
                  Events
                </span>
              </div>
              <ul className="list-style-none">
                <li className="mb_5">
                  <Link
                    className="secondary letter-spacing-small"
                    to={`${RouteConstants.EVENTS.path}/washington-dc`}
                  >
                    Washington DC
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="secondary letter-spacing-small"
                    to={`${RouteConstants.EVENTS.path}/new-york-city`}
                  >
                    New York City
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="secondary letter-spacing-small"
                    to={`${RouteConstants.EVENTS.path}/los-angeles`}
                  >
                    Los Angeles
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="secondary letter-spacing-small"
                    to={`${RouteConstants.EVENTS.path}/san-francisco`}
                  >
                    San Francisco
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="secondary letter-spacing-small"
                    to={`${RouteConstants.EVENTS.path}/digital`}
                  >
                    Digital Events
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="secondary letter-spacing-small"
                    to={`${RouteConstants.EVENTS.path}/cooking-class`}
                  >
                    The Cooking Class
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className={cx('EventsSubnav__content__preview col-7 pl1', {
                'EventsSubnav__content__preview--pending': eventIndexChangePending,
              })}
            >
              {events && events.length && events[EventsSubnav.INDEX_LIMIT] && (
                <Link className="secondary" to={events[eventIndex].url}>
                  <div className="mb1">
                    <Img
                      src={events[eventIndex].image.src}
                      alt={events[eventIndex].image.caption || ''}
                    />
                  </div>
                  <div className="">
                    <span className="ginto text-medium letter-spacing-small">
                      <span>Pineapple {events[eventIndex].city}: </span>
                      <br />
                      <span className="capitalize">
                        {events[eventIndex].title}
                      </span>
                    </span>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className="EventsSubnav__controls pt1_25 pb_25">
            {events && events.length && events[EventsSubnav.INDEX_LIMIT] && (
              <div className="flex justify-between">
                <div>
                  <Link className="cta" to={RouteConstants.EVENTS.path}>
                    View Calendar
                  </Link>
                </div>
                <div className="flex items-center">
                  <Button
                    ariaLabel="Previous Event"
                    variant="no-style"
                    onClick={this.decrementEventIndex}
                  >
                    <LeftArrowIcon
                      className="EventsSubnav__controls__arrow"
                      fill={Color.BLACK}
                    />
                  </Button>
                  <div className="EventsSubnav__controls__index text-center mx1 viksjoe text-small">
                    {eventIndex + 1} / {EventsSubnav.INDEX_LIMIT + 1}
                  </div>
                  <Button
                    ariaLabel="Previous Event"
                    variant="no-style"
                    onClick={this.incrementEventIndex}
                  >
                    <RightArrowIcon
                      className="EventsSubnav__controls__arrow"
                      fill={Color.BLACK}
                    />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Subnav>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  events: state.events.events,
});

export default connect(mapStateToProps)(EventsSubnav);
