import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

import ContainerBase from 'lib/ContainerBase';
import { fetchGenericPage } from 'state/actions/appActions';
import GenericPageView from 'views/GenericPageView';

import { GlobalState, GenericPage } from 'types';

interface StoreProps {
  generic: GenericPage | null;
}

interface DispatchProps {
  actions: {
    fetchGenericPage: (slug: string) => void;
  };
}

type Props = RouteComponentProps & StoreProps & DispatchProps;

class GenericPageContainer extends ContainerBase<
  Props,
  typeof GenericPageView
> {
  view = import('views/GenericPageView');

  beforeModel = () => {
    const city = get(this, 'props.match.params.city', '');

    return this.props.actions.fetchGenericPage(city);
  };
}

const mapStateToProps = (state: GlobalState) => ({
  generic: state.generic
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      fetchGenericPage
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericPageContainer);
