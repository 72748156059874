import { createSelector } from 'reselect';

import {
  GlobalState,
  ShopifyProducts,
  SubscriptionProducts,
  Image,
} from 'types';

export default createSelector(
  (state: GlobalState): ShopifyProducts | null => {
    return state.products.shopifyProducts;
  },
  (state: GlobalState): SubscriptionProducts | null => {
    return state.products.subscriptionProducts;
  },
  (
    shopifyProducts: ShopifyProducts | null,
    subscriptionProducts: SubscriptionProducts | null
  ): { [key: string]: Image } | null => {
    if (!shopifyProducts || !subscriptionProducts) return null;

    let subscriptionProductImages: { [key: string]: Image } | any = {};

    Object.keys(subscriptionProducts).forEach((productHandle) => {
      const subscriptionVariant = shopifyProducts[
        productHandle
      ].variants.find((variant) =>
        variant.title.toLowerCase().includes('subscription')
      );

      subscriptionProductImages[productHandle] = subscriptionVariant
        ? subscriptionVariant.image
        : null;
    });

    return subscriptionProductImages;
  }
);
