import React from 'react';
import { connect } from 'react-redux';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import { Product, GlobalState, Image } from 'types';
import { Img } from 'components';

import products from 'state/selectors/products';
import prismicImageUtil from 'utils/prismicImageUtil';

interface ProductGridItem {
  product: string;
}

export interface IProductGrid {
  type: 'product_grid';
  data: {
    title: string;
    logo: Image;
    linkableStickerImage: Image;
    linkableStickerPath: string;
    description: string;
    products: ProductGridItem[];
  };
}

interface OwnProps {
  slice: IProductGrid;
}

interface StateProps {
  products: { [key: string]: Product } | null;
}

type Props = OwnProps & StateProps;

const ProductGrid: React.FC<Props> = ({ slice, products }) => {
  return (
    <div className="ProductGrid mxauto col-12 px2 mb2 md:mb8 flex flex-col relative">
      <div className="ProductGrid__info text-center md:mb4 mb3">
        {!!slice.data.logo.src && (
          <Img
            className="ProductGrid__info__logo col-12 mb1"
            src={prismicImageUtil(slice.data.logo.src)}
            alt={slice.data.logo.caption || ''}
          />
        )}

        {!!slice.data.title && (
          <h2 className="text-xl mb_5">{slice.data.title}</h2>
        )}

        {!!slice.data.description && (
          <div className="ProductGrid__info__description mxauto md:col-5 col-12">
            <PrismicRichText render={slice.data.description} />
          </div>
        )}
      </div>

      <div className="ProductGrid__sticker">
        {!!slice.data.linkableStickerImage.src &&
        slice.data.linkableStickerPath ? (
          <Link to={slice.data.linkableStickerPath}>
            <Img
              className="ProductGrid__sticker__image"
              src={prismicImageUtil(slice.data.linkableStickerImage.src)}
              alt={slice.data.linkableStickerImage.caption || ''}
            />
          </Link>
        ) : (
          <Img
            className="ProductGrid__sticker__image"
            src={prismicImageUtil(slice.data.linkableStickerImage.src)}
            alt={slice.data.linkableStickerImage.caption || ''}
          />
        )}
      </div>

      <div className="ProductGrid__products">
        {slice.data.products.map((item: ProductGridItem, i: number) => {
          const product: Product | null = get(products, item.product, null);
          if (!product) return null;

          return (
            <div
              key={item.product}
              className="ProductGrid__products__product col-12 relative"
            >
              <Link
                className="ProductGrid__products__product__link no-style no-hover"
                to={product.url}
              >
                <Img
                  isBg={true}
                  className="ProductGrid__products__product__image col-12"
                  src={prismicImageUtil(
                    product.content.gridImage.src,
                    '960',
                    product.content.gridImage.src.includes('.gif')
                      ? 'gif'
                      : 'pjpg'
                  )}
                  alt={product.content.gridImage.caption || ''}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  products: products(state),
});

export default connect(mapStateToProps)(ProductGrid);
