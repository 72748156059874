import { RouterAction } from 'connected-react-router';

import { EventsAction } from 'state/actions/types/eventsActionTypes';
import { PineappleEvent } from 'types';

export interface EventsReducer {
  events: PineappleEvent[] | null;
  event: PineappleEvent | null;
  latest: PineappleEvent[];
}

const initialState: EventsReducer = {
  events: null,
  event: null,
  latest: []
};

export default (
  state: EventsReducer = initialState,
  action: EventsAction | RouterAction
): EventsReducer => {
  switch (action.type) {
    case 'FETCH_EVENTS_FULFILLED':
      return {
        ...state,
        events: action.payload
      };
    case 'FETCH_EVENT_FULFILLED':
      return {
        ...state,
        event: action.payload
      };
    case 'FETCH_LATEST_EVENTS_FULFILLED':
      return {
        ...state,
        latest: action.payload
          ? state.latest.concat(action.payload)
          : state.latest
      };
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        latest: []
      };
    default:
      return state;
  }
};
