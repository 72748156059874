import React, { Component } from 'react';
import cx from 'classnames';

import { Button, LeftArrowIcon, RightArrowIcon } from 'components';
import ColorMap from 'constants/Colors';
import { Color, Testimonial } from 'types';

export interface ITestimonials {
  type: 'testimonials';
  data: {
    testimonials: Testimonial[];
    color: string;
  };
}

interface Props {
  slice: ITestimonials;
}

interface State {
  index: number;
  changePending: boolean;
}

class Testimonials extends Component<Props, State> {
  state = {
    index: 0,
    changePending: false,
  };

  incrementIndex = () => {
    this.setState({ changePending: true });

    setTimeout(() => {
      this.setState({
        index:
          this.state.index === this.props.slice.data.testimonials.length - 1
            ? 0
            : this.state.index + 1,
      });
    }, 200);

    setTimeout(() => {
      this.setState({ changePending: false });
    }, 400);
  };

  decrementIndex = () => {
    this.setState({ changePending: true });

    setTimeout(() => {
      this.setState({
        index: this.state.index
          ? this.state.index - 1
          : this.props.slice.data.testimonials.length - 1,
      });
    }, 200);

    setTimeout(() => {
      this.setState({ changePending: false });
    }, 400);
  };

  render() {
    const {
      slice: {
        data: { testimonials, color },
      },
    } = this.props;
    const { index, changePending } = this.state;

    return (
      <div className="Testimonials col-12 container-width flex mxauto mb4 md:mb8 px1_5 md:px2">
        <div
          className="Testimonials__carousel col-12 p1 md:p2 flex items-center radius-xs"
          style={{ backgroundColor: ColorMap[color.toUpperCase()] }}
        >
          {testimonials.length > 1 && (
            <Button
              ariaLabel="Previous"
              variant="no-style"
              onClick={this.decrementIndex}
            >
              <LeftArrowIcon
                className="ReadsSubnav__controls__arrow"
                fill={Color.WHITE}
              />
            </Button>
          )}
          <div
            className={cx(
              'Testimonials__testimonial col-12 color-white md:px3 px_5 text-center',
              { 'Testimonials__testimonial--pending': changePending }
            )}
          >
            <span className="Testimonials__testimonial__quote mb1 block">
              &rdquo;{testimonials[index].quote}&ldquo;
            </span>
            <span className="viksjoe text-small uppercase">
              &mdash; {testimonials[index].person}
            </span>
          </div>
          {testimonials.length > 1 && (
            <Button
              ariaLabel="Next"
              variant="no-style"
              onClick={this.incrementIndex}
            >
              <RightArrowIcon
                className="ReadsSubnav__controls__arrow"
                fill={Color.WHITE}
              />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Testimonials;
