import HomeContainer from 'containers/HomeContainer';
import ProductContainer from 'containers/ProductContainer';
import GenericPageContainer from 'containers/GenericPageContainer';
import ReadContainer from 'containers/ReadContainer';
import EventContainer from 'containers/EventContainer';
import EventsCategoryContainer from 'containers/EventsCategoryContainer';
import PreviewContainer from 'containers/PreviewContainer';
import OrderSuccessContainer from 'containers/OrderSuccessContainer';
import BlogRedirectContainer from 'containers/BlogRedirectContainer';
import { RouteObject } from 'types';

export const RouteMap: { [id: string]: RouteObject } = {
  PREVIEW: {
    path: '/preview',
    exact: true,
    component: PreviewContainer,
  },
  HOME: {
    path: '/',
    exact: true,
    component: HomeContainer,
  },
  PRODUCT: {
    path: '/products/:product',
    exact: true,
    component: ProductContainer,
  },
  EVENT: {
    path: '/events/:city/:slug',
    exact: true,
    component: EventContainer,
  },
  READ: {
    path: '/reads/:category/:slug',
    exact: true,
    component: ReadContainer,
  },
  READS_CATEGORY: {
    path: '/reads/:slug',
    exact: true,
    component: GenericPageContainer,
  },
  GENERIC: {
    path: '/:slug',
    exact: true,
    component: GenericPageContainer,
  },
  EVENTS_CATEGORY: {
    path: '/events/:city',
    exact: true,
    component: EventsCategoryContainer,
  },
  ORDER_SUCCESS: {
    path: '/order/success/:id',
    exact: true,
    component: OrderSuccessContainer,
  },
  BLOG_REDIRECT: {
    path: '/blog/:year/:month/:day/:slug',
    exact: true,
    component: BlogRedirectContainer,
  },
};

export const RouteConstants: { [key: string]: RouteObject } = {
  ABOUT: {
    path: '/about',
    exact: true,
    component: GenericPageContainer,
  },
  PRESS: {
    path: '/press',
    exact: true,
    component: GenericPageContainer,
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
    exact: true,
    component: GenericPageContainer,
  },
  TERMS_OF_SERVICE: {
    path: '/terms-of-service',
    exact: true,
    component: GenericPageContainer,
  },
  PRODUCTS: {
    path: '/products',
    exact: true,
    component: GenericPageContainer,
  },
  EVENTS: {
    path: '/events',
    exact: true,
    component: GenericPageContainer,
  },
  FAQS: {
    path: '/faqs',
    exact: true,
    component: GenericPageContainer,
  },
  READS: {
    path: '/reads',
    exact: true,
    component: GenericPageContainer,
  },
  PANTRY: {
    path: '/reads/the-pantry',
    exact: true,
    component: GenericPageContainer,
  },
  RECIPE_BOX: {
    path: '/reads/recipes/the-recipe-box',
    exact: true,
    component: GenericPageContainer,
  },
  SIGN_UP: {
    path: '/sign-up',
    exact: true,
    component: GenericPageContainer,
  },
  STOCKISTS: {
    path: '/stockists',
    exact: true,
    component: GenericPageContainer,
  },
  VIDEOS: {
    path: '/videos',
    exact: true,
    component: GenericPageContainer,
  },
  CONTACT_US: {
    path: '/contact-us',
    exact: true,
    component: GenericPageContainer,
  },
  WHOLESALE: {
    path: '/wholesale',
    exact: true,
    component: GenericPageContainer,
  },
  WHOLESALE_PRODUCTS: {
    path: '/wholesale-products',
    exact: true,
    component: GenericPageContainer,
  },
  HOW_TO_OPEN: {
    path: '/how-to-open',
    exact: true,
    component: GenericPageContainer,
  },
  THE_FINDS: {
    path: '/the-finds',
    exact: true,
    component: GenericPageContainer,
  },
};
