import { createSelector } from 'reselect';
import get from 'lodash/get';

import { RouteConstants } from 'constants/RouteMap';
import slugify from 'utils/slugify';
import slices from 'state/serializers/slices';
import { Read, Image, ReadsCategory, Slice } from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): Read[] => {
    const results = get(response, 'results', []);

    return results.map((result: unknown) => {
      const title: string = get(result, 'data.title[0].text', '');
      const slug: string = get(result, 'uid', '');
      const excerpt: string = get(result, 'data.excerpt[0].text', '');
      const category: ReadsCategory = get(result, 'data.category', '');
      const image: Image = {
        src: get(result, 'data.featured_image.url', ''),
        caption: get(result, 'data.featured_image.alt', ''),
        credit: get(result, 'data.featured_image.copyright', ''),
        dimensions: get(result, 'data.featured_image.dimensions'),
      };

      const addHeroPadding: boolean =
        get(result, 'data.hero_padding', 'No') === 'No';

      const url: string = `${RouteConstants.READS.path}/${slugify(
        category
      )}/${slug}`;
      const body: Slice[] = slices(get(result, 'data.body', []));

      return {
        title,
        slug,
        category,
        image,
        excerpt,
        url,
        addHeroPadding,
        slices: body,
      } as Read;
    });
  }
);
