export default (text: string, goal: number, total: number, product: string) => {
  const diff = goal - total;
  const message = text
    .replace('{PRODUCT}', product)
    .replace('{DIFF}', `$${diff.toString()}`)
    .replace('{GOAL}', `$${goal.toString()}`)
    .replace('{TOTAL}', `$${total.toString()}`);

  return message;
};
