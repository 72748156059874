import React from 'react';

import { Img } from 'components';
import { Image } from 'types';

export interface IImageCollageHero {
  type: 'image_collage_hero';
  data: {
    title: string;
    eyebrow: string;
    description: string;
    image1: Image;
    image2: Image;
    image3: Image;
  };
}

interface Props {
  slice: IImageCollageHero;
}

const ImageCollageHero: React.FC<Props> = ({ slice }) => (
  <div className="ImageCollageHero col-12 container-width flex flex-wrap mxauto px1_5 md:px2 mt2 mb3 md:mb4">
    {slice.data.title && (
      <div className="ImageCollageHero__text col-12 md:col-8 mxauto text-center mb1_5 md:mb3">
        <span className="ImageCollageHero__eyebrow block mb_5 md:mb1 viksjoe text-medium uppercase">
          {slice.data.eyebrow}
        </span>
        <h1 className="ImageCollageHero__title mb2">{slice.data.title}</h1>
        {slice.data.description && (
          <p className="block text-medium">{slice.data.description}</p>
        )}
      </div>
    )}
    <div className="ImageCollageHero__images flex flex-wrap items-center">
      <div className="col-6 flex flex-wrap">
        <div className="col-12 pr_5 md:pr3 mb2">
          <Img
            src={slice.data.image1.src}
            alt={slice.data.image1.caption || ''}
            caption={slice.data.image1.caption || ''}
            credit={slice.data.image1.credit || ''}
          />
        </div>
        <div className="col-12 pl1 md:pl3">
          <Img
            src={slice.data.image3.src}
            alt={slice.data.image3.caption || ''}
            caption={slice.data.image3.caption || ''}
            credit={slice.data.image3.credit || ''}
          />
        </div>
      </div>
      <div className="col-6 pl1 md:pl3">
        <Img
          src={slice.data.image2.src}
          alt={slice.data.image2.caption || ''}
          caption={slice.data.image2.caption || ''}
          credit={slice.data.image2.credit || ''}
        />
      </div>
    </div>
  </div>
);

export default ImageCollageHero;
