import { SubscriptionAction } from 'state/actions/types/subscriptionActionTypes';
import { SubscriptionCheckoutSession } from 'types';

export interface SubscriptionReducer {
  checkoutSession: SubscriptionCheckoutSession | null;
}

const initialState: SubscriptionReducer = {
  checkoutSession: null,
};

export default (
  state: SubscriptionReducer = initialState,
  action: SubscriptionAction
): SubscriptionReducer => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTION_CHECKOUT_SESSION_FULFILLED':
      return {
        ...state,
        checkoutSession: action.payload,
      };
    default:
      return state;
  }
};
