import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import get from 'lodash/get';

import ShopifyClient from 'lib/ShopifyClient';
import resetLocalStorage from 'utils/resetLocalStorage';
import handleFreeGifts from 'utils/handleFreeGifts';

import { LineItemToUpdate } from 'types';

export const fetchOrCreateCheckout = (checkoutId: string) => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => ({
  type: 'FETCH_OR_CREATE_CHECKOUT',
  payload: new Promise((resolve) => {
    if (!checkoutId) {
      return resolve(dispatch(createCheckout()));
    }

    return resolve(dispatch(fetchCheckout(checkoutId)));
  }),
});

export const createCheckout = () => ({
  type: 'CREATE_CHECKOUT',
  payload: new Promise((resolve) => {
    return ShopifyClient.createCheckout().then((checkout) => resolve(checkout));
  }),
});

export const fetchCheckout = (checkoutId: string) => (dispatch: Dispatch) => ({
  type: 'FETCH_CHECKOUT',
  payload: new Promise((resolve) =>
    ShopifyClient.fetchCheckout(checkoutId).then((res) => {
      if (res.checkout.completedAt) {
        console.warn('Checkout completed, creating new checkout.');
        return dispatch(createCheckout());
      }

      return resolve(res);
    })
  ),
});

export const updateLineItems = (
  checkoutId: string,
  lineItems: LineItemToUpdate[]
) => ({
  type: 'UPDATE_LINE_ITEMS',
  payload: new Promise((resolve, reject) => {
    return ShopifyClient.updateCheckout(checkoutId, lineItems).then(
      (checkout) => {
        if (
          get(checkout, 'userErrors', []).length ||
          !get(checkout, 'checkout.id')
        ) {
          resetLocalStorage();
          window.location.href = window.location.href + '?resetCheckout=true';
          window.location.reload();
          return reject(get(checkout, 'userErrors[0].message', ''));
        }

        resolve(checkout);
      }
    );
  }),
});

export const updateLineItemsWithFreeGifts = (
  checkoutId: string,
  lineItems: LineItemToUpdate[]
) => (dispatch: Dispatch, getState: any) => ({
  type: 'UPDATE_LINE_ITEMS',
  payload: new Promise((resolve, reject) => {
    const updatedLineItems = handleFreeGifts(getState(), lineItems);

    return resolve(dispatch(updateLineItems(checkoutId, updatedLineItems)));
  }),
});

export const updateCartNote = (checkoutId: string, note: string) => ({
  type: 'UPDATE_CART_NOTE',
  payload: new Promise((resolve, reject) => {
    return ShopifyClient.updateCartNote(checkoutId, note).then(
      (checkout: unknown) => {
        if (
          get(checkout, 'userErrors', []).length ||
          !get(checkout, 'checkout.id')
        ) {
          resetLocalStorage();
          window.location.href = window.location.href + '?resetCheckout=true';
          window.location.reload();
          return reject(get(checkout, 'userErrors[0].message', ''));
        }

        return resolve(checkout);
      }
    );
  }),
});
