import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Location } from 'history';

import { RouteObject } from 'types';

import { RouteMap } from 'constants/RouteMap';

interface RoutesProps {
  location: Location;
}

const Routes: React.FC<RoutesProps> = ({ location }) => (
  <Switch location={location}>
    {Object.values(RouteMap).map((route: RouteObject) => (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    ))}
  </Switch>
);

export default Routes;
