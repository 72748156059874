import React, { Component } from 'react';
import { connect } from 'react-redux';
import jsonp from 'jsonp';
import cx from 'classnames';

import { Status, GlobalState } from 'types';
import { TextField, Button } from 'components';
import { SettingsReducer } from 'state/reducers/settings';

interface StateProps {
  settings: SettingsReducer;
}

interface Props extends StateProps {
  mode?: 'light' | 'dark';
  listId?: string;
  onSuccess?: () => void;
}

interface State {
  emailAddress: string;
  status: Status;
  responseMsg: Error | string | null;
  showErrorMsg: boolean;
  showSuccessMsg: boolean;
}

class EmailSignup extends Component<Props, State> {
  private submitButton = React.createRef<HTMLButtonElement>();

  state: State = {
    emailAddress: '',
    status: Status.IDLE,
    responseMsg: null,
    showErrorMsg: false,
    showSuccessMsg: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { status, responseMsg } = this.state;

    if (prevState.status === Status.PENDING && status === Status.FULFILLED) {
      this.setState({
        emailAddress: '',
        showSuccessMsg: true,
        showErrorMsg: false,
      });
      setTimeout(() => this.setState({ showSuccessMsg: false }), 3000);
    }

    if (prevState.status === Status.PENDING && status === Status.REJECTED) {
      this.setState({ showErrorMsg: true, showSuccessMsg: false });
      setTimeout(() => this.setState({ showErrorMsg: false }), 3000);
    }

    if (prevState.responseMsg !== responseMsg) {
      console.log(responseMsg);
    }
  }

  focus = () => {
    if (this.submitButton.current && this.submitButton.current.focus)
      this.submitButton.current.focus();
  };

  subscribe = () => {
    const listId = this.props.listId || this.props.settings.mailchimpListId;

    if (listId) {
      const url =
        listId.replace('/post?', '/post-json?') +
        `&EMAIL=${this.state.emailAddress}`;
      this.setState({ status: Status.PENDING });

      jsonp(
        url,
        {
          param: 'c',
        },
        (err, data) => {
          if (err) {
            this.setState({
              status: Status.REJECTED,
              responseMsg: err,
            });
            this.focus();
          } else if (data.result !== 'success') {
            if (data.msg.includes('subscribed')) {
              this.setState({
                status: Status.FULFILLED,
                responseMsg: null,
              });
              this.focus();

              if (this.props.onSuccess) this.props.onSuccess();
            } else {
              this.setState({
                status: Status.REJECTED,
                responseMsg: data.msg,
              });
              this.focus();
            }
          } else {
            this.setState({
              status: Status.FULFILLED,
              responseMsg: data.msg,
            });
            this.focus();

            if (this.props.onSuccess) this.props.onSuccess();
          }
        }
      );
    }
  };

  getButtonMessage = (): string => {
    const { showErrorMsg, showSuccessMsg } = this.state;

    if (showErrorMsg) {
      return 'Something went wrong, try again';
    } else if (showSuccessMsg) {
      return 'Subscribed!';
    }

    return 'Sign Up';
  };

  getAriaLabel = (): string => {
    const { showErrorMsg, showSuccessMsg } = this.state;

    if (showErrorMsg) {
      return 'Something went wrong, try again';
    } else if (showSuccessMsg) {
      return 'You are subscribed';
    }

    return 'Sign up for newsletter';
  };

  render() {
    const { mode } = this.props;
    const { emailAddress } = this.state;

    return (
      <div
        className={cx(
          'EmailSignup flex flex-wrap items-center col-12 pb_5 relative',
          { 'light-mode': mode === 'light' }
        )}
      >
        <TextField
          name="email"
          placeholder="Your Email"
          variant="no-style"
          ariaLabel="Enter you email to subscribe"
          value={emailAddress}
          onChange={(emailAddress) =>
            this.setState({ emailAddress: emailAddress as string })
          }
          className="EmailSignup__input col-12 pr4 text-small"
        />
        <Button
          className="EmailSignup__button z-1 uppercase viksjoe absolute text-small r0"
          label={this.getButtonMessage()}
          ariaLabel={this.getAriaLabel()}
          variant="no-style"
          onClick={this.subscribe}
          elemRef={this.submitButton}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(EmailSignup);
