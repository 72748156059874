import React from 'react';

interface Video {
  title?: string;
  description?: string;
  videoEmbed: any;
}

export interface IVideos {
  type: 'youtube_videos';
  data: {
    videos: Video[];
  };
}

interface Props {
  slice: IVideos;
}

const Videos: React.FC<Props> = ({ slice }) => {
  return (
    <div className="Videos col-12 flex flex-wrap mb6">
      <div className="Videos__info col-12 md:col-4 container-width mxauto text-center mb1_5 px1_5 md:px2">
        <h2 className="text-large mb1">Videos</h2>
      </div>
      <div className="Videos__videos col-12">
        <div className="flex flex-wrap px0 md:px1_5">
          {slice.data.videos.map(
            (video) =>
              video.videoEmbed && (
                <div
                  key={video.videoEmbed.embed_url}
                  className="col-12 md:col-4 mxauto mb3 relative p2"
                >
                  <div
                    className="Videos__video-embed"
                    dangerouslySetInnerHTML={{
                      __html: video.videoEmbed.html,
                    }}
                  />

                  {video.title && (
                    <div className="text-medium pt1">{video.title}</div>
                  )}

                  {video.description && (
                    <div className="text-small pt_5">{video.description}</div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;
