import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const BagIcon: React.FC<Props> = ({ className = '', fill = '#F2F2E8' }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Bag Icon</desc>
      <g clipPath="url(#clip0)">
        <path
          d="M14.9212 4.59008H12.3093V4.08008C12.3093 1.87008 10.5041 0.0800781 8.27537 0.0800781C6.04663 0.0800781 4.24145 1.87008 4.24145 4.08008V4.59008H1.6295C1.00424 4.59008 0.5 5.09008 0.5 5.71008V11.9201C0.5 14.6801 2.75899 16.9201 5.55248 16.9201H10.9983C13.7917 16.9201 16.0507 14.6801 16.0507 11.9201V5.71008C16.0507 5.09008 15.5465 4.59008 14.9212 4.59008ZM6.25841 4.08008C6.25841 2.97008 7.16604 2.08008 8.27537 2.08008C9.38469 2.08008 10.2923 2.97008 10.2923 4.08008V4.59008H6.25841V4.08008ZM14.0338 11.9201C14.0338 13.5801 12.6723 14.9201 10.9983 14.9201H5.55248C3.8784 14.9201 2.51696 13.5801 2.51696 11.9201V6.59008H14.0338V11.9201Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="15.5507"
            height="16.84"
            fill="white"
            transform="translate(0.5 0.0800781)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BagIcon;
