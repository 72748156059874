import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { StarBurst, Img, EmailSignup } from 'components';
import { GlobalState, GlobalSettings } from 'types';
import { RouteConstants } from 'constants/RouteMap';

interface StateProps {
  settings: GlobalSettings;
}

interface Props extends StateProps {}

const Footer: React.FC<Props> = ({ settings }) => (
  <footer className="Footer overflow-hidden relative col-12">
    <div className="Footer__instagram bg-color-mustard">
      <a
        className="block py1"
        href={settings.instagram}
        aria-label="Pineapple on Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="Footer__instagram__carousel flex width-auto items-center">
          <span className="text-xxl color-cream transition uppercase viksjoe mr2">
            Instagram
          </span>
          <div className="mr2">
            <StarBurst />
          </div>
          <span className="text-xxl color-cream transition uppercase viksjoe mr2">
            Instagram
          </span>
          <div className="mr2">
            <StarBurst />
          </div>
          <span className="text-xxl color-cream transition uppercase viksjoe mr2">
            Instagram
          </span>
          <div className="mr2">
            <StarBurst />
          </div>
          <span className="text-xxl color-cream transition uppercase viksjoe mr2">
            Instagram
          </span>
          <div className="mr2">
            <StarBurst />
          </div>
        </div>
      </a>
    </div>
    <div className="Footer__main bg-color-brown color-white py3">
      <div className="Footer__main__menu container-width mxauto mb2 md:mb4 flex flex-wrap">
        <div className="Footer__main__newsletter col-12 md:col-4 px1_5 md:px0 mb4 md:mb0">
          <h2 className="text-large mb1">See what's cookin'</h2>
          <p className="text-medium mb2">
            Join our mailing list for our latest events, interviews, and hot
            tips and takes.
          </p>
          <EmailSignup />
        </div>
        <div className="Footer__main__nav flex flex-wrap flex-row-reverse md:flex-row col-12 md:col-8 px1_5 md:px0">
          <div className="col-4 flex md:justify-start">
            <div className="inline-block">
              <h2 className="text-medium mb1">Shop</h2>
              <ul className="list-style-none">
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.THE_FINDS.path}
                  >
                    The Finds
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.PRODUCTS.path}
                  >
                    Products
                  </Link>
                </li>

                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.STOCKISTS.path}
                  >
                    Stockists
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.WHOLESALE.path}
                  >
                    Wholesale
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-4 flex md:justify-start">
            <div className="inline-block">
              <h2 className="text-medium mb1">Stories</h2>
              <ul className="list-style-none">
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={`${RouteConstants.READS.path}/the-pantry`}
                  >
                    The Pantry
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={`${RouteConstants.READS.path}/the-guide`}
                  >
                    The Guide
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={`${RouteConstants.READS.path}/articles`}
                  >
                    Articles
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={`${RouteConstants.READS.path}/pineapple-radio`}
                  >
                    Pineapple Radio
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.EVENTS.path}
                  >
                    Events
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-4 flex md:justify-start">
            <div className="inline-block">
              <h2 className="text-medium mb1">About</h2>
              <ul className="list-style-none">
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.ABOUT.path}
                  >
                    Our Story
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.FAQS.path}
                  >
                    FAQs
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.HOW_TO_OPEN.path}
                  >
                    How To Open
                  </Link>
                </li>
                <li className="mb_5">
                  <Link
                    className="white text-small"
                    to={RouteConstants.CONTACT_US.path}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer__legal container-width mxauto flex flex-wrap flex-col-reverse md:flex-row justify-between item-start md:items-end pt4 px1_5 md:px0">
        <div className="Footer__links flex flex-col">
          <div className="Footer__social mb1_5">
            <ul className="wauto list-style-none flex items-center">
              <li className="inline mr1_5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/pineapplecollaborative"
                >
                  <Img
                    className="wauto h100"
                    src="/assets/images/instagram-icon.svg"
                    alt="Pineapple Collaborative on Instagram"
                  />
                </a>
              </li>
              <li className="inline mr1_5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/pineapplecollaborative"
                >
                  <Img
                    className="wauto h100"
                    src="/assets/images/facebook-icon.svg"
                    alt="Pineapple Collaborative on Facebook"
                  />
                </a>
              </li>
              <li className="inline mr1_5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/pineapplecollab"
                >
                  <Img
                    className="wauto h100"
                    src="/assets/images/twitter-icon.svg"
                    alt="Pineapple Collaborative on Twitter"
                  />
                </a>
              </li>
              <li className="inline mr1_5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.pinterest.com/pineapplecollaborative/"
                >
                  <Img
                    className="wauto h100"
                    src="/assets/images/pinterest-icon.svg"
                    alt="Pineapple Collaborative on Pinterest"
                  />
                </a>
              </li>
              <li className="inline mr1_5">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCa-3CN-k7BDYZGmhjInb_Kg"
                >
                  <Img
                    className="wauto h100"
                    src="/assets/images/youtube-icon.svg"
                    alt="Pineapple Collaborative on Youtube"
                  />
                </a>
              </li>
              <li className="inline mr1_5 text-xs">
                <a className="white" href="tel:9293224517">
                  929.322.4517
                </a>
              </li>
              <li className="inline mr1_5 text-xs">
                <a
                  className="white"
                  href="mailto:hello@pineapplecollaborative.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hello@pineapplecollaborative.com
                </a>
              </li>
            </ul>
          </div>
          <div className="Footer__legal__nav text-xs">
            <span className="block md:inline mr2 mb1 md:mb0">
              &copy; {format(new Date(), 'yyyy')} Pineapple Collaborative
            </span>
            <Link className="mr2 white" to={RouteConstants.PRIVACY_POLICY.path}>
              Privacy Policy
            </Link>
            <Link
              className="mr2 white"
              to={RouteConstants.TERMS_OF_SERVICE.path}
            >
              Terms of Service
            </Link>
          </div>
        </div>
        <div className="Footer__legal__logo mb2 md:mb0">
          <Img
            src="/assets/images/pineapple-collaborative-logo-white.svg"
            alt="Pineapple Logo"
          />
        </div>
      </div>
    </div>
  </footer>
);

const mapStateToProps = (state: GlobalState) => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(Footer);
