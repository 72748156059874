import React from 'react';

import { SubscriptionCheckoutSession, Image } from 'types';

import { Img, Button, StarNumber } from 'components';

import { RouteMap } from 'constants/RouteMap';
import Colors from 'constants/Colors';

interface Props {
  checkoutSession: SubscriptionCheckoutSession;
  image: Image | null;
}

const OrderSuccess: React.FC<any> = ({ checkoutSession, image }) => {
  const {
    name,
    email,
    subtotal,
    total,
    address,
    productHandle,
    productName,
    productPrice,
    productQuantity,
  } = checkoutSession;
  const firstName = name.split(' ')[0];

  return (
    <div className="OrderSuccess col-12 container-padding container-width mxauto flex flex-wrap justify-between relative px1_5 md:px2 mb4 md:mb6">
      <div className="OrderSuccess__info relative col-12 md:col-6 mb2 md:mt2 mt1 md:mb0">
        <div className="ginto text-large mb_5">Thank you, {firstName}!</div>
        <div className="text-medium mt1 mb1">
          <div className="bold mb1">
            Your subscription is confirmed, and you’ll receive a confirmation
            email shortly.
          </div>
          <div className="mb1">
            You’re now part of the only product subscription that connects you
            to a community of food lovers through talking, cooking, and of
            course, eating together. We promise it will find a home on your
            kitchen counter and you’ll use it till the very last drop.
          </div>
          {productHandle === 'the-olive-oil-vinegar-set' && (
            <div className="italic">
              In your <span className="bold">Pantry Set</span>, we’ll send you a
              different color of The Olive Oil every quarter.
            </div>
          )}
        </div>
        <div className="ginto text-large mt3 mb_5">Customer Information</div>
        <div className="OrderSuccess__customer-info flex items-start justify-between">
          <div className="OrderSuccess__customer-info--contact mt1 mb1 text-medium">
            <div className="ginto mb_5">Contact Information</div>
            <div>{name}</div>
            <div>{email}</div>
          </div>
          <div className="OrderSuccess__customer-info--shipping mt1 mb1 text-medium">
            <div className="ginto mb_5">Shipping address</div>
            <div>{address.line1}</div>
            <div>{address.line2}</div>
            <div>
              {address.city} {address.state} {address.postal_code}
            </div>
            <div>United States</div>
          </div>
        </div>
        <div className="mt1 mb2 text-medium">
          <div className="ginto mb_5">Need help?</div>
          <div>
            Contact us at{' '}
            <a href="mailto:hello@pineapplecollaborative.com">
              hello@pineapplecollaborative.com
            </a>
          </div>
        </div>
        <Button
          className="secondary letter-spacing-small"
          label="Continue Shopping"
          ariaLabel="Continue Shopping"
          to={RouteMap.HOME.path}
          variant="primary"
        />
      </div>
      <div className="OrderSuccess__summary col-12 md:col-5 mb2 mt2 text-medium">
        <div className="ginto mb1">Order Summary</div>
        <div className="flex items-center justify-between mb1">
          <div className="flex items-center">
            <div className="OrderSuccess__summary--image-container">
              <Img
                className="OrderSuccess__summary--image"
                src={image && image.src}
                alt={(image && image.alt) || ''}
              />
              <StarNumber
                color={Colors.BROWN}
                textColor="white"
                quantity={productQuantity}
              />
            </div>

            <div className="ml_5">
              <div className="bold">{productName}</div>
              <div className="text-small">Subscription</div>
            </div>
          </div>
          <div>${productPrice}</div>
        </div>
        <div className="flex items-center justify-between mb_5">
          <div>Subtotal</div>
          <div>${subtotal}</div>
        </div>
        <div className="flex items-center justify-between mb_5">
          <div>Shipping</div>
          <div>Free</div>
        </div>
        <div className="flex items-center justify-between bold">
          <div>Total</div>
          <div>${total}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
