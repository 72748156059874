import React, { Component } from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';

export interface IQuestionnaire {
  type: 'questionnaire';
  data: {
    title: string;
    questions: {
      question: string;
      answer: any;
    }[];
  };
}

interface Props {
  slice: IQuestionnaire;
}

class Questionnaire extends Component<Props> {
  render() {
    const { slice } = this.props;

    return (
      <div className="Questionnaire col-12 relative mt2 mb4 px1_5">
        <div className="Questionnaire__container text-container-width mxauto bg-color-white pt3 pb1 px1_5">
          <div className="col-12 text-center mb3">
            <span className="text-medium-2">{slice.data.title}</span>
          </div>
          <div className="col-12 md:col-8 mxauto text-center">
            {slice.data.questions.map(
              (question: { question: string; answer: any }) => (
                <div className="mb2" key={question.question}>
                  <span className="block viksjoe uppercase mb_5 color-gold">
                    {question.question}
                  </span>
                  <span className="block bell text-sm">
                    <PrismicRichText render={question.answer} />
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Questionnaire;
