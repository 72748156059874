import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';

export interface ITitleBlock {
  type: 'title_block';
  data: {
    title: string;
    eyebrow: string;
    text: any;
    credit: string;
    creditLink: string;
    highlight: boolean;
  };
}

interface Props {
  slice: ITitleBlock;
}

const TitleBlock: React.FC<Props> = ({ slice }) => (
  <div className="TitleBlock col-12 px1_5 md:px2 mb3">
    <div className="TitleBlock__title text-center mb3">
      <span className="TitleBlock__eyebrow block mb_5 md:mb1 viksjoe text-medium uppercase">
        {slice.data.eyebrow}
      </span>
      <h2 className="TitleBlock__title text-large viksjoe mb2">
        {slice.data.title}
        {slice.data.highlight && (
          <span className="TitleBlock__title__highlight"></span>
        )}
      </h2>
    </div>
    <div className="TitleBlock__text col-12 mxauto mb2 bell">
      <div className="text-container-width prismic-rich-text mxauto">
        <PrismicRichText render={slice.data.text} />
      </div>
    </div>
    <div className="TitleBlock__credit viksjoe text-medium uppercase">
      {slice.data.creditLink ? (
        <a
          href={slice.data.creditLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="text-container-width mxauto color-black">
            <span className="transition">{slice.data.credit}</span>
          </div>
        </a>
      ) : (
        <div className="text-container-width mxauto">{slice.data.credit}</div>
      )}
    </div>
  </div>
);

export default TitleBlock;
