export const fetchProducts = `
  query products {
    products(first: 250) {
      edges {
        node {
          id
          title
          handle
          tags
          variants(first: 100) {
            edges {
              node {
                id
                availableForSale
                price {
                  amount
                  currencyCode
                }
                image {
                  originalSrc
                  altText
                }
                product {
                  id
                  handle
                }
                selectedOptions {
                  name
                  value
                }
                title
              }
            }
          }
          options {
            id
            name
            values
          }
        }
      }
    }
  }
`;
