import { LineItems, LineItem, LineItemToUpdate } from 'types';

export default (
  currentLineItems: LineItems,
  variantId: string
): LineItemToUpdate[] => {
  return currentLineItems.reduce(
    (updatedLineItems: LineItemToUpdate[], lineItem: LineItem) => {
      if (lineItem.variantId === variantId) {
        return updatedLineItems;
      }

      return updatedLineItems.concat([
        {
          variantId: lineItem.variantId,
          quantity: lineItem.quantity
        }
      ]);
    },
    []
  );
};
