import ApiClient from 'lib/ApiClient';

import { fetchProducts } from 'state/graphql/products';
import {
  fetchCheckout,
  createCheckout,
  updateCheckout,
  updateCartNote
} from 'state/graphql/checkout';

import shopifyProducts from 'state/serializers/shopifyProducts';

import { CheckoutReducer } from 'state/reducers/checkout';
import checkout from 'state/serializers/checkout';
import { ShopifyProducts, LineItemToUpdate } from 'types';

interface ShopifyClient {
  fetchProducts(): Promise<ShopifyProducts>;
  fetchCheckout(checkoutId: string): Promise<CheckoutReducer>;
  createCheckout(): Promise<CheckoutReducer>;
  updateCheckout(
    checkoutId: string,
    lineItems: LineItemToUpdate[]
  ): Promise<CheckoutReducer>;
  updateCartNote(checkoutId: string, note: string): Promise<CheckoutReducer>;
}

const ShopifyClient: ShopifyClient = {
  fetchProducts() {
    return ApiClient.query(fetchProducts).then(data => {
      return shopifyProducts(data);
    });
  },
  fetchCheckout(checkoutId) {
    return ApiClient.query(fetchCheckout, {
      id: checkoutId
    }).then(data => checkout(data));
  },
  createCheckout() {
    return ApiClient.mutate(createCheckout, { input: {} }).then(data =>
      checkout(data)
    );
  },
  updateCheckout(checkoutId, lineItems) {
    return ApiClient.mutate(updateCheckout, { checkoutId, lineItems }).then(
      data => checkout(data)
    );
  },
  updateCartNote(checkoutId, note) {
    return ApiClient.mutate(updateCartNote, {
      checkoutId,
      input: { note }
    }).then(data => checkout(data));
  }
};

export default ShopifyClient;
