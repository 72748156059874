import React from 'react';
import { Link } from 'react-router-dom';

import { Img } from 'components';
import { Image } from 'types';

export interface IImpactHeroCollage {
  type: 'hero_collage';
  data: {
    title: string;
    description: string;
    buttonLink: string;
    internalLink: string;
    buttonText: string;
    bottomImage: Image;
    topImage: Image;
  };
}

interface Props {
  slice: IImpactHeroCollage;
}

const ImpactHeroCollage: React.FC<Props> = ({ slice }) => (
  <div className="ImpactHeroCollage flex flex-wrap items-end container-width mxauto px2 mb8">
    <div className="col-12 md:col-5">
      <div className="ImpactHeroCollage__bottom-image col-12">
        <Img
          src={slice.data.bottomImage.src}
          alt={slice.data.bottomImage.caption || ''}
          caption={slice.data.bottomImage.caption || ''}
          credit={slice.data.bottomImage.credit || ''}
        />
      </div>
    </div>
    <div className="col-12 md:col-7 md:pl3 flex flex-wrap">
      <div className="col-12 flex flex-wrap justify-end">
        <div className="ImpactHeroCollage__top-image col-8 mr4">
          <Img
            src={slice.data.topImage.src}
            alt={slice.data.topImage.caption || ''}
            caption={slice.data.topImage.caption || ''}
            credit={slice.data.topImage.credit || ''}
          />
        </div>
      </div>
      <div className="ImpactHeroCollage__info col-12 mt4 pr4">
        <h2 className="text-large mb1">{slice.data.title}</h2>
        <p>{slice.data.description}</p>

        {slice.data.buttonText &&
          (slice.data.internalLink || slice.data.buttonLink) && (
            <div className="mt2 pb_25">
              {slice.data.internalLink ? (
                <Link className="cta" to={slice.data.internalLink}>
                  {slice.data.buttonText}
                </Link>
              ) : (
                <a
                  className="cta"
                  href={slice.data.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {slice.data.buttonText}
                </a>
              )}
            </div>
          )}
      </div>
    </div>
  </div>
);

export default ImpactHeroCollage;
