import products from 'state/reducers/products';
import prices from 'state/reducers/prices';
import subscription from 'state/reducers/subscription';
import events from 'state/reducers/events';
import reads from 'state/reducers/reads';
import settings from 'state/reducers/settings';
import homepage from 'state/reducers/homepage';
import checkout from 'state/reducers/checkout';
import generic from 'state/reducers/generic';
import status from 'state/reducers/status';

export default {
  products,
  prices,
  events,
  reads,
  settings,
  homepage,
  checkout,
  generic,
  status,
  subscription,
};
