import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import get from 'lodash/get';

import { Img, Button } from 'components';
import Cities from 'constants/Cities';
import { RouteConstants } from 'constants/RouteMap';
import { fetchLatestEvents } from 'state/actions/eventsActions';
import { PineappleEvent, GlobalState } from 'types';

export interface IEvents {
  type: 'events';
  data: {
    title: string;
    description: string;
    buttonText: string;
    internalLink: string;
    buttonLink: string;
    city: string;
    events: string[] | null;
    paginate: boolean;
  };
}

interface DispatchProps {
  actions: {
    fetchLatestEvents(page: number, city: string | null): void;
  };
}

interface StateProps {
  latest: PineappleEvent[];
}

interface OwnProps {
  slice: IEvents;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  page: number;
}

class Events extends Component<Props, State> {
  state = {
    page: 1,
  };

  componentDidMount() {
    const { slice, actions } = this.props;
    const city = slice.data.city !== 'No' ? slice.data.city : null;

    actions.fetchLatestEvents(this.state.page, city);
  }

  handleLoadMore = () => {
    const { actions, slice } = this.props;
    const { page } = this.state;

    const city = slice.data.city !== 'No' ? slice.data.city : null;

    this.setState({ page: page + 1 }, () =>
      actions.fetchLatestEvents(this.state.page, city)
    );
  };

  render() {
    const { slice, latest } = this.props;
    const events: PineappleEvent[] = latest;

    return (
      <div className="Events col-12 flex flex-wrap mb6">
        {!!slice.data.title && (
          <div className="Events__info col-12 md:col-4 container-width mxauto text-center mb1_5 px1_5 md:px2">
            <h2 className="text-large mb1">{slice.data.title}</h2>
            {!!slice.data.description && (
              <p className="mb1">{slice.data.description}</p>
            )}
            {slice.data.buttonText &&
              (slice.data.internalLink || slice.data.buttonLink) && (
                <>
                  {slice.data.internalLink ? (
                    <Link className="cta" to={slice.data.internalLink}>
                      {slice.data.buttonText}
                    </Link>
                  ) : (
                    <a
                      className="cta"
                      href={slice.data.buttonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {slice.data.buttonText}
                    </a>
                  )}
                </>
              )}
          </div>
        )}
        <div className="Events__info__filters col-12 mb3">
          <div className="col-12 md:col-8 mxauto text-center px1_5 md:px2">
            <Button
              className="mx_5 mb1"
              variant={
                slice.data.city === 'No' ? 'secondary--inverted' : 'secondary'
              }
              to={RouteConstants.EVENTS.path}
              label="All Events"
              ariaLabel="All Pineapple Events"
            />

            {Object.keys(Cities).map((city: string) => {
              const cityIsSelected: boolean = city === slice.data.city;
              const to = !!get(Cities, city)
                ? `${RouteConstants.EVENTS.path}/${get(Cities, city).slug}`
                : RouteConstants.EVENTS.path;

              const label = get(Cities, `${city}.label`, 'Events');

              return (
                <Button
                  key={label}
                  className="mx_5 mb1"
                  variant={cityIsSelected ? 'secondary--inverted' : 'secondary'}
                  to={to}
                  label={label}
                  ariaLabel={`Events in ${label}`}
                />
              );
            })}
          </div>
        </div>
        <div className="Events__events col-12">
          <div className="flex flex-wrap px0 md:px1_5">
            {events.map((event) => (
              <div
                key={event.url}
                className="col-12 md:col-4 mxauto mb3 relative p2"
              >
                <Link className="no-hover" to={event.url}>
                  <Img
                    className="col-12 mb1_5"
                    src={event.image.src}
                    alt={event.image.caption || ''}
                  />
                </Link>
                {event.stickerImage.src && (
                  <div className="Events__event__sticker absolute t0 r0 w100 flex justify-center">
                    <div className="container-width w100 flex justify-end px1">
                      <div className="Events__event__sticker__container">
                        {event.stickerLink ? (
                          <Link to={event.stickerLink} className="w100">
                            <Img
                              src={event.stickerImage.src}
                              alt={''}
                              className="w100"
                            />
                          </Link>
                        ) : (
                          <Img
                            src={event.stickerImage.src}
                            alt={''}
                            className="w100"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <span className="block text-medium mb_5">{event.title}</span>
                <span className="block text-small mb1">
                  {format(event.start, 'MMMM dd')},{' '}
                  {format(event.start, 'h:mm')} - {format(event.end, 'h:mm a')}
                </span>
                <Link className="cta" to={event.url}>
                  Read More
                </Link>
              </div>
            ))}
          </div>
        </div>
        {slice.data.paginate && (
          <div className="Events__load-more col-12 text-center">
            <Button
              variant="primary--inverted"
              label="Load More"
              ariaLabel="Load More Events"
              onClick={this.handleLoadMore}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  latest: state.events.latest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ fetchLatestEvents }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
