import { createSelector } from 'reselect';
import get from 'lodash/get';

import { SubscriptionPrices } from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): SubscriptionPrices => {
    const prices = get(response, 'data', []).reduce(
      (sanitizedPrices: SubscriptionPrices, price: unknown) => {
        const id = get(price, 'id', '');
        const productId = get(price, 'product', '');
        const currency = get(price, 'currency', '');
        const nickname = get(price, 'nickname', '');
        const amount = Number(get(price, 'unit_amount', '')) / 100;

        if (sanitizedPrices[productId]) {
          sanitizedPrices[productId].push({
            id,
            productId,
            currency,
            amount,
            nickname,
          });
        } else {
          sanitizedPrices[productId] = [
            {
              id,
              productId,
              currency,
              amount,
              nickname,
            },
          ];
        }

        return sanitizedPrices;
      },
      {}
    );

    return prices;
  }
);
