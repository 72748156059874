import { RouterAction } from 'connected-react-router';

import { ReadsAction } from 'state/actions/types/readsActionTypes';
import { Read } from 'types';

export interface ReadsReducer {
  reads: Read[] | null;
  read: Read | null;
  latest: Read[];
}

const initialState: ReadsReducer = {
  reads: null,
  read: null,
  latest: []
};

export default (
  state: ReadsReducer = initialState,
  action: ReadsAction | RouterAction
): ReadsReducer => {
  switch (action.type) {
    case 'FETCH_READS_FULFILLED':
      return {
        ...state,
        reads: action.payload
      };
    case 'FETCH_READ_FULFILLED':
      return {
        ...state,
        read: action.payload
      };
    case 'FETCH_LATEST_READS_FULFILLED':
      return {
        ...state,
        latest: action.payload
          ? state.latest.concat(action.payload)
          : state.latest
      };
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        latest: []
      };
    default:
      return state;
  }
};
