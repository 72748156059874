import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import get from 'lodash/get';

import ContainerBase from 'lib/ContainerBase';
import { fetchRead } from 'state/actions/readsActions';
import ReadView from 'views/ReadView';

import { GlobalState, Read } from 'types';

interface StoreProps {
  read: Read | null;
}

interface DispatchProps {
  actions: {
    fetchRead: (slug: string, previewToken: string) => void;
  };
}

type Props = RouteComponentProps & StoreProps & DispatchProps;

class ReadContainer extends ContainerBase<Props, typeof ReadView> {
  view = import('views/ReadView');

  beforeModel = () => {
    const slug = get(this, 'props.match.params.slug', '');
    const params = qs.parse(this.props.location.search.slice(1));

    return this.props.actions.fetchRead(slug, (params.token as string) || '');
  };
}

const mapStateToProps = (state: GlobalState) => ({
  read: state.reads.read,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      fetchRead,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadContainer);
