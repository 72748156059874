import {
  ImageTextOrientation,
  ImageCaptionOrientation,
  YoutubeVideoOrientation,
} from 'types';

export const imageTextOrientation = (
  raw: string
): ImageTextOrientation | null => {
  if (raw === 'Image centered, text on top') return 'IMAGE_CENTER_TEXT_TOP';
  if (raw === 'Image centered, text on bottom')
    return 'IMAGE_CENTER_TEXT_BOTTOM';
  if (raw === 'Image on left') return 'IMAGE_LEFT';
  if (raw === 'Image on right') return 'IMAGE_RIGHT';
  if (raw === 'Image side by side') return 'IMAGE_SIDE_BY_SIDE';
  if (raw === 'Image stacked') return 'IMAGE_STACKED';

  return null;
};

export const imageCaptionOrientation = (
  raw: string
): ImageCaptionOrientation | null => {
  if (raw === 'Image centered') return 'IMAGE_CENTERED';
  if (raw === 'Image side by side') return 'IMAGE_SIDE_BY_SIDE';
  if (raw === 'Image stacked') return 'IMAGE_STACKED';

  return null;
};

export const youtubeVideoOrientation = (
  raw: string
): YoutubeVideoOrientation | null => {
  if (raw === 'Video centered, text on top') return 'VIDEO_CENTER_TEXT_TOP';
  if (raw === 'Video on left') return 'VIDEO_LEFT';
  if (raw === 'Video on right') return 'VIDEO_RIGHT';

  return null;
};
