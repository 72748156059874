import React from 'react';

import cx from 'classnames';

interface Props {
  id?: string;
  name: string;
  label?: string;
  ariaLabel: string;
  className: string;
  options: string[];
  variant?: 'primary';
  onChange: (value: string | number) => any;
  value?: string | number;
  errorMessage?: string;
}

const defaultProps = {
  className: '',
  onChange: (value: string | number) => {},
};

const Dropdown: React.FC<Props> = (props) => {
  const {
    id,
    name,
    label,
    ariaLabel,
    className,
    options,
    variant,
    errorMessage,
    onChange,
    value,
  } = props;

  const _id = id || name;

  return (
    <div
      className={cx('Dropdown', className, {
        [`Dropdown--style-${variant}`]: !!variant,
      })}
    >
      <label className="flex justify-between" htmlFor={_id}>
        <span>{label}</span>
        {errorMessage && <span className="error">{errorMessage}</span>}
      </label>
      <select
        id={_id}
        aria-label={ariaLabel}
        required
        onChange={(e) => onChange(e.target.value)}
        value={value || ''}
      >
        <option value="" disabled hidden>
          Pick a {name}…
        </option>
        <>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </>
      </select>
    </div>
  );
};

Dropdown.defaultProps = defaultProps;

export default Dropdown;
