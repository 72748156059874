import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  children: React.ReactNode;
  isActive: boolean;
}

const Subnav: React.FC<Props> = ({ className = '', children, isActive }) => (
  <div
    className={cx('Subnav absolute col-12', {
      'Subnav--active': isActive,
    })}
  >
    <div className={cx('Subnav__inner bg-color-cream md:radius-xs', className)}>
      {children}
    </div>
  </div>
);

export default Subnav;
