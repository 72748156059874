import { Component } from 'react';
import { ConnectedComponent } from 'react-redux';
import { RouterState } from 'connected-react-router';

import { ApplicationAction } from 'state/actions/types/appActionTypes';
import { EventsAction } from 'state/actions/types/eventsActionTypes';
import { ProductsAction } from 'state/actions/types/productsActionTypes';
import { ReadsAction } from 'state/actions/types/readsActionTypes';
import { CheckoutAction } from 'state/actions/types/checkoutActionTypes';

import { StatusReducer } from 'state/reducers/status';
import { CheckoutReducer } from 'state/reducers/checkout';
import { ProductsReducer } from 'state/reducers/products';
import { PricesReducer } from 'state/reducers/prices';
import { SubscriptionReducer } from 'state/reducers/subscription';
import { EventsReducer } from 'state/reducers/events';
import { ReadsReducer } from 'state/reducers/reads';
import { SettingsReducer } from 'state/reducers/settings';
import { HomepageReducer } from 'state/reducers/homepage';
import { GenericPageReducer } from 'state/reducers/generic';

import { IImpactHeroCollage } from 'components/slices/ImpactHeroCollage';
import { IProductCollage } from 'components/slices/ProductCollage';
import { IProductGrid } from 'components/slices/ProductGrid';
import { ITestimonials } from 'components/slices/Testimonials';
import { IImageText } from 'components/slices/ImageText';
import { IImageCaption } from 'components/slices/ImageCaption';
import { IPressLogos } from 'components/slices/PressLogos';
import { IFoodMarquee } from 'components/slices/FoodMarquee';
import { IIconGrid } from 'components/slices/IconGrid';
import { IProductRecirculation } from 'components/slices/ProductRecirculation';
import { IImageCollageHero } from 'components/slices/ImageCollageHero';
import { IRichText } from 'components/slices/RichText';
import { IStockist } from 'components/slices/Stockist';
import { ILinkableCarousel } from 'components/slices/LinkableCarousel';
import { IRecirculation } from 'components/slices/Recirculation';
import { IFullWidthImage } from 'components/slices/FullWidthImage';
import { IReads } from 'components/slices/Reads';
import { IEvents } from 'components/slices/Events';
import { ISignUpForm } from 'components/slices/SignUpForm';
import { IWholesaleLogin } from 'components/slices/WholesaleLogin';
import { IContactUsForm } from 'components/slices/ContactUsForm';
import { IAccordions } from 'components/slices/Accordions';
import { ITitleBlock } from 'components/slices/TitleBlock';
import { IFullWidthImageCarousel } from 'components/slices/FullWidthImageCarousel';
import { IShopBlock } from 'components/slices/ShopBlock';
import { IKeepReading } from 'components/slices/KeepReading';
import { IQuestionnaire } from 'components/slices/Questionnaire';
import { IRichTextPicks } from 'components/slices/RichTextPicks';
import { IRichTextProducts } from 'components/slices/RichTextProducts';
import { IRecipe } from 'components/slices/Recipe';
import { IYouTubeVideoEmbed } from 'components/slices/YouTubeVideoEmbed';
import { IVideos } from 'components/slices/Videos';
import { IReadsCollage } from 'components/slices/ReadsCollage';

export interface ViewProps {
  model: any;
}

export class View<Props extends ViewProps, State = {}> extends Component<
  Props,
  State
> {}

export enum Status {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export interface RouteObject {
  path: string;
  exact: boolean;
  component?: ConnectedComponent<any, any>;
  base?: string;
}

export type Action =
  | ApplicationAction
  | ProductsAction
  | EventsAction
  | ReadsAction
  | CheckoutAction;

export interface AlertBanner {
  isActive: string;
  text: string;
  externalLink: string;
  internalLink: string;
  linkText: string;
}

export enum CitySlug {
  'NYC' = 'new-york-city',
  'DC' = 'washington-dc',
  'LA' = 'los-angeles',
  'SF' = 'san-francisco',
}

export enum Color {
  'WHITE' = '#ffffff',
  'BLACK' = '#272f39',
  'CREAM' = '#f2f2e8',
  'BROWN' = '#b78365',
  'BLUE' = '#5387a9',
  'AQUA' = '#9abcbd',
  'YELLOW' = '#dcb359',
  'MUSTARD' = '#ccb16a',
  'TEAL' = '#9eb29c',
  'PINK' = '#e8b18d',
  'GRAY' = '#aeaeae',
  'GRAY_LIGHTER' = '#dedede',
}

export interface GlobalSettings {
  alertBanner: AlertBanner | null;
  instagram: string;
  mailchimpListId: string;
  readsNavItems: { label: string; url: string }[];
  showHolidayShopButton: boolean;
  upsell: Offer | null;
  popUp: PopUp | null;
  giftsWithPurchase: GiftsWithPurchase[];
}

export interface GlobalState {
  products: ProductsReducer;
  prices: PricesReducer;
  subscription: SubscriptionReducer;
  status: StatusReducer;
  events: EventsReducer;
  reads: ReadsReducer;
  settings: SettingsReducer;
  homepage: HomepageReducer;
  checkout: CheckoutReducer;
  generic: GenericPageReducer;
  router: RouterState;
}

export interface GenericPage {
  title: string;
  addHeroPadding: boolean;
  backgroundColor: 'Default' | 'White' | null;
  slices: Slice[] | null;
}

export interface Homepage {
  addHeroPadding: boolean;
  slices: Slice[] | null;
}

export interface Image {
  src: string;
  caption: string | null;
  credit: string | null;
  dimensions?: ImageDimensions;
  captionUrl?: string | null;
  variantTitle?: string | null;
}

export interface ImageDimensions {
  width: number;
  height: number;
}

export type ImageTextOrientation =
  | 'IMAGE_CENTER_TEXT_TOP'
  | 'IMAGE_CENTER_TEXT_BOTTOM'
  | 'IMAGE_LEFT'
  | 'IMAGE_RIGHT'
  | 'IMAGE_SIDE_BY_SIDE'
  | 'IMAGE_STACKED';

export type ImageCaptionOrientation =
  | 'IMAGE_CENTERED'
  | 'IMAGE_SIDE_BY_SIDE'
  | 'IMAGE_STACKED';

export type YoutubeVideoOrientation =
  | 'VIDEO_CENTER_TEXT_TOP'
  | 'VIDEO_LEFT'
  | 'VIDEO_RIGHT';

export interface PineappleEvent {
  title: string;
  slug: string;
  image: Image;
  city: string;
  link: string;
  start: Date;
  end: Date;
  url: string;
  stickerImage: Image;
  stickerLink: string;
  slices: Slice[];
}

export interface PrismicProduct {
  handle: string;
  title: string;
  productImages: Image[];
  gridImage: Image;
  lineItemImage: Image;
  subscriptionSticker: Image;
  savingsSticker: Image;
  savingsPrice: string;
  description: string;
  inspiration: string;
  information: string;
  subscriptionDescription: string;
  heroButtonLabel: string;
  inventoryMessage: string;
  showEmailCapture: boolean;
  emailCaptureMailchimpListId: string;
  makeImageSquare: boolean;
  quickAdd: boolean;
  isFreeGift: boolean;
  variantSpecificMessages: VariantSpecificMessage[];
  excerpt: string;
  slices: Slice[];
}

export interface PrismicProducts {
  [key: string]: PrismicProduct;
}

export interface Product extends ShopifyProduct {
  content: PrismicProduct;
  subscription: SubscriptionProduct | null;
  url: string;
}

export interface Read {
  title: string;
  slug: string;
  image: Image;
  excerpt: string;
  category: ReadsCategory;
  url: string;
  addHeroPadding: boolean;
  slices: Slice[];
}

export type ReadsCategory = 'The Pantry' | 'Recipes';

export interface ShopifyProducts {
  [productHandle: string]: ShopifyProduct;
}

export interface ShopifyProduct {
  id: string;
  title: string;
  handle: string;
  variants: ShopifyProductVariant[];
  options: ShopifyProductOption[];
  tags: string[];
}

export interface SubscriptionPrice {
  id: string;
  productId: string;
  currency: string;
  amount: number;
  nickname: 'YELLOW' | 'WHITE' | 'PINK' | null;
}

export interface SubscriptionPrices {
  [productId: string]: SubscriptionPrice[];
}

export interface SubscriptionProduct {
  id: string;
  shopifyHandle: string;
  name: string;
  description: string;
  images: string[];
  prices?: SubscriptionPrice[];
}

export interface SubscriptionProducts {
  [productName: string]: SubscriptionProduct;
}

export interface SubscriptionCheckoutSession {
  id: string;
  subtotal: string;
  total: string;
  address: {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
  };
  name: string | null;
  email: string | null;
  productHandle: string;
  productName: string;
  productPrice: string;
  productQuantity: string;
}

export interface ShopifyProductVariant {
  id: string;
  availableForSale: boolean;
  price: number;
  title: string;
  variantOptions: string[];
  image: Image;
  product: {
    id: string;
    handle: string;
  };
}

export interface Variant {
  variantId: string;
  availableForSale: boolean;
  price: number;
  product: {
    id: string;
    handle: string;
  };
  variantOptions: string[];
  title: string;
  imageIndex: number;
}

export interface VariantSpecificMessage {
  variant: string;
  buttonText: string;
  inventoryShippingMessage: string;
  quickAdd: boolean;
}

export interface ShopifyProductOption {
  id: string;
  name: string;
  values: string[];
}

export type Slice =
  | IImpactHeroCollage
  | IProductCollage
  | IProductGrid
  | ITestimonials
  | IImageText
  | IImageCaption
  | IPressLogos
  | IFoodMarquee
  | IIconGrid
  | IProductRecirculation
  | IImageCollageHero
  | IRichText
  | IStockist
  | ILinkableCarousel
  | IRecirculation
  | IFullWidthImage
  | IReads
  | IEvents
  | ISignUpForm
  | IWholesaleLogin
  | IContactUsForm
  | IAccordions
  | ITitleBlock
  | IFullWidthImageCarousel
  | IShopBlock
  | IKeepReading
  | IQuestionnaire
  | IRichTextPicks
  | IRichTextProducts
  | IRecipe
  | IYouTubeVideoEmbed
  | IVideos
  | IReadsCollage;

export interface Testimonial {
  quote: string;
  person: string;
}

export interface Checkout {
  completedAt: Date | null;
  currencyCode: string;
  id: string;
  note: string;
  subtotalPrice: number;
  totalPrice: number;
  webUrl: string;
  lineItems: LineItems;
}

export type LineItems = LineItem[];

export interface LineItem {
  variantId: string;
  quantity: number;
  productId: string;
  productHandle: string;
  isFreeGift: boolean;
}

export interface MergedLineItem extends LineItem {
  title: string;
  image: Image;
  url: string;
  price: number;
}

export type LinkMap = LinkSection[];
export interface LinkSection {
  name: string;
  links: RouteObject[];
}

export interface Offer {
  isActive: boolean;
  goal: number;
  title: string;
  message: string;
  buttonText: string;
  desktopImage: Image;
  mobileImage: Image;
  products: {
    product: {
      slug: string;
      uid: string;
    };
    variant: string;
  }[];
}

export interface PopUp {
  isActive: boolean;
  title: string;
  text: string;
  image: Image;
  mailchimpListId: string;
  successMessage: string;
}

export interface GiftsWithPurchase {
  productSlug: string;
  giftSlug: string;
}

export interface UserError {
  field: string;
  message: string;
}

export interface LineItemToUpdate {
  variantId: string;
  quantity: number;
}
