import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Subnav, Img, Button, LeftArrowIcon, RightArrowIcon } from 'components';
import { GlobalState, Read, Color, GlobalSettings } from 'types';
import { RouteConstants } from 'constants/RouteMap';

interface OwnProps {
  isActive: boolean;
}

interface StateProps {
  reads: Read[] | null;
  settings: GlobalSettings;
}

type Props = OwnProps & StateProps;

interface State {
  readIndex: number;
  readIndexChangePending: boolean;
}

class ReadsSubnav extends Component<Props, State> {
  state = {
    readIndex: 0,
    readIndexChangePending: false,
  };

  static INDEX_LIMIT = 2;

  incrementreadIndex = () => {
    const limit = Math.min(
      ReadsSubnav.INDEX_LIMIT,
      this.props.reads ? this.props.reads.length - 1 : 9999
    );
    this.setState({ readIndexChangePending: true });

    setTimeout(() => {
      this.setState({
        readIndex:
          this.state.readIndex === limit ? 0 : this.state.readIndex + 1,
      });
    }, 200);

    setTimeout(() => {
      this.setState({ readIndexChangePending: false });
    }, 400);
  };

  decrementreadIndex = () => {
    const limit = Math.min(
      ReadsSubnav.INDEX_LIMIT,
      this.props.reads ? this.props.reads.length - 1 : 9999
    );
    this.setState({ readIndexChangePending: true });

    setTimeout(() => {
      this.setState({
        readIndex: this.state.readIndex ? this.state.readIndex - 1 : limit,
      });
    }, 200);

    setTimeout(() => {
      this.setState({ readIndexChangePending: false });
    }, 400);
  };

  render() {
    const { isActive, reads, settings } = this.props;
    const { readIndex, readIndexChangePending } = this.state;

    if (!reads) return null;

    const limit = Math.min(ReadsSubnav.INDEX_LIMIT, reads.length - 1);

    return (
      <Subnav isActive={isActive}>
        <div className="ReadsSubnav p1">
          <div className="ReadsSubnav__content mb1 flex flex-wrap">
            <div className="ReadsSubnav__content__links col-5">
              <div className="flex items-center mb2">
                <span className="viksjoe text-small color-brown uppercase">
                  Stories
                </span>
              </div>
              <ul className="list-style-none">
                {settings.readsNavItems.map((item) => (
                  <li className="mb_5" key={item.url}>
                    <Link
                      className="secondary letter-spacing-small"
                      to={item.url}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={cx('ReadsSubnav__content__preview col-7 pl1', {
                'ReadsSubnav__content__preview--pending': readIndexChangePending,
              })}
            >
              {reads && reads.length && reads[limit - 1] && (
                <Link className="secondary" to={reads[readIndex].url}>
                  <div className="mb1">
                    <Img
                      src={reads[readIndex].image.src}
                      alt={reads[readIndex].image.caption || ''}
                    />
                  </div>
                  <div>
                    <span>
                      <span className="text-xs viksjoe uppercase color-brown">
                        {reads[readIndex].category}
                      </span>
                      <br />
                      <span className="ginto text-medium letter-spacing-small capitalize">
                        {reads[readIndex].title}
                      </span>
                    </span>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className="ReadsSubnav__controls pt1_25 pb_25">
            {reads && reads.length && reads[limit - 1] && (
              <div className="flex justify-between">
                <div>
                  <Link className="cta" to={RouteConstants.READS.path}>
                    View All Stories
                  </Link>
                </div>
                <div className="flex items-center">
                  <Button
                    ariaLabel="Previous Event"
                    variant="no-style"
                    onClick={this.decrementreadIndex}
                  >
                    <LeftArrowIcon
                      className="ReadsSubnav__controls__arrow"
                      fill={Color.BLACK}
                    />
                  </Button>
                  <div className="ReadsSubnav__controls__index text-center mx1 viksjoe text-small">
                    {readIndex + 1} / {limit + 1}
                  </div>
                  <Button
                    ariaLabel="Previous Event"
                    variant="no-style"
                    onClick={this.incrementreadIndex}
                  >
                    <RightArrowIcon
                      className="ReadsSubnav__controls__arrow"
                      fill={Color.BLACK}
                    />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Subnav>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  reads: state.reads.reads,
  settings: state.settings,
});

export default connect(mapStateToProps)(ReadsSubnav);
