import { ApplicationAction } from 'state/actions/types/appActionTypes';
import { GlobalSettings } from 'types';

export interface SettingsReducer extends GlobalSettings {}
const initialState: SettingsReducer = {
  alertBanner: null,
  instagram: '',
  mailchimpListId: '',
  readsNavItems: [],
  upsell: null,
  popUp: null,
  giftsWithPurchase: [],
  showHolidayShopButton: false,
};

export default (
  state: SettingsReducer = initialState,
  action: ApplicationAction
): SettingsReducer => {
  switch (action.type) {
    case 'FETCH_GLOBAL_SETTINGS_FULFILLED':
      return {
        ...state,
        ...action.payload,
      } as SettingsReducer;
    default:
      return state;
  }
};
