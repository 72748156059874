import React, { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import cx from 'classnames';

import { Img, Button, LeftArrowIcon, RightArrowIcon } from 'components';
import { Image, Color } from 'types';

interface Props {
  productImages: Image[];
  subscriptionIsSelected: boolean;
  makeImageSquare: boolean;
}

let flickity: any = null;

const ProductImages: React.FC<Props> = ({
  productImages,
  subscriptionIsSelected,
  makeImageSquare,
}) => {
  const [image, setImage] = useState(productImages[0]);

  useEffect(() => {
    setImage(productImages[0]);
    flickity.select(0);
  }, [productImages]);

  const handlePrevious = (): void => {
    const prevImage = productImages[productImages.indexOf(image) - 1];
    if (prevImage) {
      setImage(prevImage);
    } else {
      setImage(productImages[productImages.length - 1]);
    }

    if (flickity) flickity.previous();
  };

  const handleNext = (): void => {
    const nextImage = productImages[productImages.indexOf(image) + 1];
    if (nextImage) {
      setImage(nextImage);
    } else {
      setImage(productImages[0]);
    }

    if (flickity) flickity.next();
  };

  return (
    <div className="ProductImages relative col-12 md:col-6 mb1_5 md:mb0 overflow-hidden">
      <Flickity
        flickityRef={(c) => (flickity = c)}
        options={{
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          adaptiveHeight: false,
        }}
      >
        {productImages.map((flickityImage) => (
          <div
            className="ProductImages__carousel col-12 relative"
            key={flickityImage.src}
          >
            <Img
              className={cx('col-12 h100', { square: makeImageSquare })}
              src={flickityImage.src}
              alt={flickityImage.caption || ''}
            />
            {!subscriptionIsSelected && productImages.length > 1 && (
              <div className="ProductImages__controls">
                <Button
                  className="ProductImages__controls__button ProductImages__controls__previous mx_5"
                  variant="no-style"
                  ariaLabel="Go back to previous image"
                  onClick={handlePrevious}
                >
                  <LeftArrowIcon fill={Color.BLACK} />
                </Button>
                <div className="viksjoe px_5">
                  {productImages.indexOf(image) + 1} / {productImages.length}
                </div>
                <Button
                  className="ProductImages__controls__button ProductImages__controls__next mx_5"
                  variant="no-style"
                  ariaLabel="Advance to next image"
                  onClick={handleNext}
                >
                  <RightArrowIcon fill={Color.BLACK} />
                </Button>
              </div>
            )}
          </div>
        ))}
      </Flickity>
      <div className="ProductImages__caption bell italic right">
        {image.caption && image.captionUrl ? (
          <a href={image.captionUrl} target="_blank" rel="noopener noreferrer">
            {image.caption}
          </a>
        ) : (
          image.caption && <>{image.caption}</>
        )}
      </div>
    </div>
  );
};

export default ProductImages;
