import { LineItem } from 'types';

const getCartContainsWholesale = (lineItems: LineItem[]) => {
  const wholesaleItems = lineItems.filter((item: LineItem) =>
    item.productHandle.includes('case')
  );

  return wholesaleItems.length > 0;
};

export default getCartContainsWholesale;
