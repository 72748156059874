import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import cx from 'classnames';

import { Img } from 'components';
import { fetchRead } from 'state/actions/readsActions';
import latestReadsSansCurrent from 'state/selectors/latestReadsSansCurrent';
import { Read, GlobalState } from 'types';

export interface IKeepReading {
  type: 'keep_reading';
  data: {
    reads: string[] | null;
    useLatest: boolean;
  };
}

interface DispatchProps {
  actions: {
    fetchRead(slug: string): void;
  };
}

interface StateProps {
  latestReads: Read[];
}

interface OwnProps {
  slice: IKeepReading;
}

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  page: number;
  customReads: Read[];
};

class Reads extends Component<Props, State> {
  state = {
    page: 1,
    customReads: [],
  };

  componentDidMount() {
    const { slice, actions } = this.props;

    if (!slice.data.useLatest && !!slice.data.reads) {
      Promise.all(
        slice.data.reads.map((read: string) => {
          return actions.fetchRead(read);
        })
      ).then((response: any) =>
        this.setState({
          customReads: response.map((read: any) => get(read, 'value')),
        })
      );
    }
  }

  render() {
    const { slice, latestReads } = this.props;
    const { customReads } = this.state;

    const reads: Read[] = slice.data.useLatest ? latestReads : customReads;

    return (
      <div className="KeepReading col-12 flex flex-wrap my4">
        <div className="flex w100 justify-center mb2">
          <span className="text-large">Keep Reading</span>
        </div>
        <div className="KeepReading__reads w100 flex items-start flex-wrap px1_5">
          {reads.map((read: Read) => {
            const isPortrait: boolean =
              !!read.image.dimensions &&
              read.image.dimensions.height >= read.image.dimensions.width;

            return (
              <div className="col-12 md:col-4 px1_5 mb4" key={read.url}>
                <Link
                  className="text-decoration-none text-center"
                  to={read.url}
                >
                  <div
                    className={cx('w100', {
                      'aspect-square mb3': !isPortrait,
                      h100: isPortrait,
                    })}
                  >
                    <Img
                      className={cx('w100 mb1', {
                        'h100 object-fit-cover': !isPortrait,
                      })}
                      src={read.image.src}
                      alt={read.title}
                    />
                  </div>
                  <span className="text-sm uppercase viksjoe block mb1 color-black">
                    {read.category}
                  </span>
                  <span className="text-medium-2 color-black block">
                    {read.title}
                  </span>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  latestReads: latestReadsSansCurrent(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ fetchRead }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reads);
