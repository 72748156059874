import { LineItemToUpdate, GlobalState } from 'types';

export default (
  state: GlobalState,
  lineItems: LineItemToUpdate[]
): LineItemToUpdate[] => {
  let updatedLineItems: LineItemToUpdate[] = lineItems;

  state.settings.giftsWithPurchase.forEach((item) => {
    if (!state.products.shopifyProducts) return;

    const productVariantId =
      state.products.shopifyProducts[item.productSlug] &&
      state.products.shopifyProducts[item.productSlug].variants[0].id;
    const giftVariantId =
      state.products.shopifyProducts[item.giftSlug] &&
      state.products.shopifyProducts[item.giftSlug].variants[0].id;

    if (!productVariantId || !giftVariantId) return;

    const productInCart: boolean = !!lineItems.find((item) => {
      return item.variantId === productVariantId;
    });

    const giftInCart: boolean = !!lineItems.find(
      (item) => item.variantId === giftVariantId
    );

    if (productInCart && !giftInCart) {
      updatedLineItems.push({ variantId: giftVariantId, quantity: 1 });
    }

    if (!productInCart) {
      updatedLineItems = updatedLineItems.filter(
        (item) => item.variantId !== giftVariantId
      );
    }
  });

  return updatedLineItems;
};
