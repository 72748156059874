import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import marked from 'marked';

import { Img } from 'components';
import { Image } from 'types';
import prismicImageUtil from 'utils/prismicImageUtil';

export interface IFullWidthImage {
  type: 'full-width_image';
  data: {
    buttonText: string;
    internalLink: string;
    buttonLink: string;
    desktopImage: Image;
    mobileImage: Image;
    stickerImage: Image;
    stickerLink: string;
    stickerAlignment: 'Left' | 'Right';
  };
}

interface Props {
  slice: IFullWidthImage;
}

const FullWidthImage: React.FC<Props> = ({ slice }) => (
  <div
    className={cx('FullWidthImage col-12 relative', {
      'mb2 md:mb4': !slice.data.stickerImage.src,
      mb6: !!slice.data.stickerImage.src,
    })}
  >
    <div className="FullWidthImage__container col-12 relative">
      {slice.data.internalLink || slice.data.buttonLink ? (
        <>
          {slice.data.internalLink ? (
            <Link className="no-style" to={slice.data.internalLink}>
              <Img
                isBg={true}
                key={slice.data.desktopImage.src}
                className="FullWidthImage__desktop col-12 h100 none md:block"
                src={prismicImageUtil(
                  slice.data.desktopImage.src,
                  '1940',
                  'pjpg'
                )}
                alt={slice.data.desktopImage.caption || ''}
              />
              <Img
                isBg={true}
                key={slice.data.mobileImage.src}
                className="FullWidthImage__mobile col-12 h100 md:none lg:none xl:none"
                src={prismicImageUtil(slice.data.mobileImage.src, '550', 'gif')}
                alt={slice.data.mobileImage.caption || ''}
              />
              <div className="col-12 py_5 items-start justify-between flex-wrap px1_5 none md:flex">
                <div
                  className="col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.desktopImage.caption || ''),
                  }}
                />
                <div
                  className="pl_5 md:pl1 text-right col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.desktopImage.credit || ''),
                  }}
                />
              </div>
              <div className="col-12 py_5 items-start justify-between flex-wrap px1_5 md:none">
                <div
                  className="col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.mobileImage.caption || ''),
                  }}
                />
                <div
                  className="pl_5 md:pl1 text-right col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.mobileImage.credit || ''),
                  }}
                />
              </div>
            </Link>
          ) : (
            <a
              className="no-style"
              href={slice.data.buttonLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img
                isBg={true}
                key={slice.data.desktopImage.src}
                className="FullWidthImage__desktop col-12 h100 none md:block"
                src={slice.data.desktopImage.src}
                alt={slice.data.desktopImage.caption || ''}
              />
              <Img
                isBg={true}
                key={slice.data.mobileImage.src}
                className="FullWidthImage__mobile col-12 h100 md:none lg:none xl:none"
                src={slice.data.mobileImage.src}
                alt={slice.data.mobileImage.caption || ''}
              />
              <div className="col-12 py_5 items-start justify-between flex-wrap px1_5 none md:flex">
                <div
                  className="col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.desktopImage.caption || ''),
                  }}
                />
                <div
                  className="pl_5 md:pl1 text-right col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.desktopImage.credit || ''),
                  }}
                />
              </div>
              <div className="col-12 py_5 items-start justify-between flex-wrap px1_5 md:none">
                <div
                  className="col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.mobileImage.caption || ''),
                  }}
                />
                <div
                  className="pl_5 md:pl1 text-right col-6 color-gray text-xs bell"
                  dangerouslySetInnerHTML={{
                    __html: marked(slice.data.mobileImage.credit || ''),
                  }}
                />
              </div>
            </a>
          )}
        </>
      ) : (
        <>
          <Img
            isBg={true}
            key={slice.data.desktopImage.src}
            className="FullWidthImage__desktop col-12 h100 none md:block"
            src={slice.data.desktopImage.src}
            alt={slice.data.desktopImage.caption || ''}
          />
          <Img
            isBg={true}
            key={slice.data.mobileImage.src}
            className="FullWidthImage__mobile col-12 h100 md:none lg:none xl:none"
            src={slice.data.mobileImage.src}
            alt={slice.data.mobileImage.caption || ''}
          />
          <div className="col-12 py_5 items-start justify-between flex-wrap px1_5 none md:flex">
            <div
              className="col-6 color-gray text-xs bell"
              dangerouslySetInnerHTML={{
                __html: marked(slice.data.desktopImage.caption || ''),
              }}
            />
            <div
              className="pl_5 md:pl1 text-right col-6 color-gray text-xs bell"
              dangerouslySetInnerHTML={{
                __html: marked(slice.data.desktopImage.credit || ''),
              }}
            />
          </div>
          <div className="col-12 py_5 items-start justify-between flex-wrap px1_5 md:none">
            <div
              className="col-6 color-gray text-xs bell"
              dangerouslySetInnerHTML={{
                __html: marked(slice.data.mobileImage.caption || ''),
              }}
            />
            <div
              className="pl_5 md:pl1 text-right col-6 color-gray text-xs bell"
              dangerouslySetInnerHTML={{
                __html: marked(slice.data.mobileImage.credit || ''),
              }}
            />
          </div>
        </>
      )}
    </div>
    {slice.data.stickerImage.src && (
      <div className="FullWidthImage__sticker absolute b0 r0 w100 flex justify-center">
        <div
          className={cx('container-width w100 flex px1', {
            'justify-end': slice.data.stickerAlignment === 'Right',
            'justify-start': slice.data.stickerAlignment === 'Left',
          })}
        >
          <div className="FullWidthImage__sticker__container">
            {slice.data.stickerLink ? (
              <Link to={slice.data.stickerLink} className="w100">
                <Img
                  src={slice.data.stickerImage.src}
                  alt={''}
                  className="w100"
                />
              </Link>
            ) : slice.data.buttonLink ? (
              <a
                className="no-style w100"
                href={slice.data.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  src={slice.data.stickerImage.src}
                  alt={''}
                  className="w100"
                />
              </a>
            ) : (
              <Img
                src={slice.data.stickerImage.src}
                alt={''}
                className="w100"
              />
            )}
          </div>
        </div>
      </div>
    )}
  </div>
);

export default FullWidthImage;
