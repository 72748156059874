import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import { updateLineItemsWithFreeGifts } from 'state/actions/checkoutActions';
import getUpdatedLineItems from 'utils/getUpdatedLineItems';
import getRemovedLineItems from 'utils/getRemovedLineItems';
import { CheckoutReducer } from 'state/reducers/checkout';
import { Quantity, Button, Img } from 'components';
import { MergedLineItem, LineItemToUpdate, GlobalState } from 'types';

interface StateProps {
  checkout: CheckoutReducer;
  actions: {
    updateLineItemsWithFreeGifts(
      checkoutId: string,
      lineItems: LineItemToUpdate[]
    ): void;
  };
}

interface Props extends StateProps {
  lineItem: MergedLineItem;
  showQuantity: boolean | null;
  isFreeGift: boolean | null;
}

class ProductLineItem extends Component<Props> {
  handleLineItemChange = (lineItem: MergedLineItem, quantity: number): void => {
    const { checkout, actions } = this.props;

    const activeVariant = lineItem.variantId;

    if (activeVariant) {
      if (quantity) {
        const updatedLineItems = getUpdatedLineItems(
          checkout.checkout.lineItems,
          {
            variantId: activeVariant,
            quantity: quantity,
          },
          true
        );

        actions.updateLineItemsWithFreeGifts(
          checkout.checkout.id,
          updatedLineItems
        );
      } else {
        const updatedLineItems = getRemovedLineItems(
          checkout.checkout.lineItems,
          activeVariant
        );

        actions.updateLineItemsWithFreeGifts(
          checkout.checkout.id,
          updatedLineItems
        );
      }
    }
  };

  render() {
    const { lineItem, showQuantity, isFreeGift } = this.props;

    return (
      <div className="ProductLineItem flex py1">
        <div className="ProductLineItem__image-wrapper">
          <div
            className="ProductLineItem__image mr1"
            style={{
              background: `url(${lineItem.image.src}) no-repeat center`,
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className="ProductLineItem__info flex flex-col col-12 justify-between pl1 md:py_5">
          <div className="flex flex-wrap justify-between items-start mb1">
            <span className="col-9 none md:block ginto text-medium letter-spacing-small mb_5">
              {lineItem.title} &mdash; ${lineItem.price}
            </span>
            <span className="ProductLineItem__mobile-price md:none ginto text-medium letter-spacing-small mb_5">
              {lineItem.title}
            </span>
            {!isFreeGift && (
              <Button
                variant="no-style"
                onClick={() => this.handleLineItemChange(lineItem, 0)}
                ariaLabel="Remove item"
              >
                <Img
                  className="ProductLineItem__close-icon"
                  src="/assets/images/close-icon.svg"
                  alt="Close icon"
                />
              </Button>
            )}
          </div>
          <div className="col-12 flex flex-wrap justify-between items-end">
            <Link
              to={lineItem.url}
              className="inline-block viksjoe cta letter-spacing-small none md:block"
            >
              Learn More
            </Link>
            <span className="md:none">${lineItem.price}</span>
            {showQuantity && (
              <Quantity
                onChange={(value: number) =>
                  this.handleLineItemChange(lineItem, value)
                }
                allowZero={true}
                showLabel={false}
                quantity={lineItem.quantity}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      updateLineItemsWithFreeGifts,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductLineItem);
