import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import cx from 'classnames';

// @ts-ignore
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import getUpdatedLineItems from 'utils/getUpdatedLineItems';
import isSubscriptionVariant from 'utils/isSubscriptionVariant';
import getSubscriptionQuarterColor from 'utils/getSubscriptionQuarterColor';
import getCartContainsWholesale from 'utils/getCartContainsWholesale';
import getNewItemIsWholesale from 'utils/getNewItemIsWholesale';

import { CheckoutReducer } from 'state/reducers/checkout';
import { StripeCheckoutData } from 'lib/StripeClient';

import Colors from 'constants/Colors';
import { RouteConstants } from 'constants/RouteMap';

import {
  Img,
  Quantity,
  Button,
  PriceOptions,
  ProductImages,
  EmailSignup,
} from 'components';
import {
  Product,
  LineItemToUpdate,
  ShopifyProductVariant,
  Image,
  VariantSpecificMessage,
} from 'types';

interface Props {
  product: Product;
  checkout: CheckoutReducer;
  actions: {
    updateLineItems(checkoutId: string, lineItems: LineItemToUpdate[]): void;
    updateLineItemsWithFreeGifts(
      checkoutId: string,
      lineItems: LineItemToUpdate[]
    ): void;
    createSubscriptionCheckout(data: StripeCheckoutData): void;
  };
}

const ProductHero: React.FC<Props> = ({ product, checkout, actions }) => {
  const [activeVariant, setActiveVariant] = useState<string>(
    product.variants[0].id
  );
  const [quantity, setQuantity] = useState<number>(1);
  const [informationIsActive, setInformationIsActive] = useState<boolean>(
    false
  );
  const [inspirationIsActive, setInspirationIsActive] = useState<boolean>(
    false
  );
  const [subscriptionIsSelected, setSubscriptionIsSelected] = useState<boolean>(
    false
  );
  const [productImages, setProductImages] = useState<Image[]>(
    [product.variants[0].image].concat(product.content.productImages)
  );
  const [showWholesaleMessage, setShowWholesaleMessage] = useState<boolean>(
    false
  );

  useEffect(() => {
    const variant = product.variants.find(
      (variant: ShopifyProductVariant) => variant.id === activeVariant
    );

    if (!variant) return;
    if (variant.title === 'Default Title') return;

    const variantImages: Image[] =
      product.content.productImages.filter((productImage) => {
        return productImage.variantTitle === variant.title;
      }) || [];

    setProductImages([variant.image].concat(variantImages));
  }, [activeVariant, product]);

  const isWholesale = window.location.href.includes('master-case');

  const { user } = useIdentityContext();
  if (isWholesale && !user) {
    return <Redirect to={RouteConstants.WHOLESALE.path} />;
  }

  const handleQuantityChange = (newQuantity: number) => {
    if (isWholesale) {
      if (newQuantity >= 9) return;
      setShowWholesaleMessage(newQuantity === 8);
      setQuantity(newQuantity);
    } else {
      setQuantity(newQuantity);
    }
  };

  const toggleInformation = () => {
    setInformationIsActive(!informationIsActive);
  };

  const toggleInspiration = () => {
    setInspirationIsActive(!inspirationIsActive);
  };

  const handlePriceOptionChange = (option: string) => {
    setSubscriptionIsSelected(option === 'subscription');
  };

  const handleAdd = () => {
    const variant = product.variants.find(
      (variant: ShopifyProductVariant) => variant.id === activeVariant
    );
    if (!variant) return;

    const newItemIsWholesale = getNewItemIsWholesale(variant);
    const cartContainsWholesale = getCartContainsWholesale(
      checkout.checkout.lineItems
    );

    if (
      (!newItemIsWholesale && cartContainsWholesale) ||
      (newItemIsWholesale && !cartContainsWholesale)
    ) {
      actions.updateLineItems(checkout.checkout.id, [
        {
          variantId: activeVariant,
          quantity: 1,
        },
      ]);
    } else {
      const updatedLineItems = getUpdatedLineItems(
        checkout.checkout.lineItems,
        {
          variantId: activeVariant,
          quantity: 1,
        }
      );

      actions.updateLineItemsWithFreeGifts(
        checkout.checkout.id,
        updatedLineItems
      );
    }
  };

  const handleSubscribe = () => {
    if (!product.subscription) return;

    let price;
    if (product.subscription.shopifyHandle === 'the-olive-oil-vinegar-set') {
      price = product.subscription.prices
        ? product.subscription.prices.find(
            (price) => price.nickname === getSubscriptionQuarterColor()
          )
        : null;
    } else {
      price = product.subscription.prices
        ? product.subscription.prices[0]
        : null;
    }

    actions.createSubscriptionCheckout({
      price: price ? price.id : '',
      quantity,
      currentUrl: window.location.href,
      successUrl: `${window.location.origin}/order/success`,
    });
  };

  const variant = product.variants.find(
    (variant: ShopifyProductVariant) => variant.id === activeVariant
  );

  const subscriptionVariant = product.variants.find(
    (variant: ShopifyProductVariant) =>
      variant.title.toLowerCase().includes('subscription')
  );

  if (!variant) return null;

  const variantSpecificMessage = product.content.variantSpecificMessages.find(
    (message: VariantSpecificMessage) => message.variant === variant.title
  );

  const showQuantity = (product: Product) => {
    return !product.tags.includes('The Finds');
  };

  return (
    <div className="ProductHero col-12 container-padding container-width mxauto flex flex-wrap justify-between relative px1_5 md:px2 mb2 md:mb4">
      {subscriptionIsSelected && subscriptionVariant ? (
        <div className="ProductHero__image relative col-12 md:col-6 mb1_5 md:mb0">
          <Img
            className="col-12 h100"
            src={subscriptionVariant.image.src}
            alt={subscriptionVariant.image.caption || ''}
          />
        </div>
      ) : (
        <ProductImages
          productImages={productImages}
          subscriptionIsSelected={subscriptionIsSelected}
          makeImageSquare={product.content.makeImageSquare}
        />
      )}

      <div className="ProductHero__info col-12 md:col-5">
        <div className="ProductHero__description">
          <h1 className="ProductHero__title mb_5">{product.title}</h1>
          <div className="mt1_5 p-with-margin bell text-medium">
            <PrismicRichText render={product.content.description} />
          </div>
          {product.subscription && product.subscription.prices ? (
            <PriceOptions
              subscriptionPrice={product.subscription.prices[0].amount.toFixed(
                2
              )}
              oneTimePrice={variant.price.toFixed(2)}
              subscriptionDescription={product.content.subscriptionDescription}
              onChange={(option) => handlePriceOptionChange(option)}
              subscriptionIsSelected={subscriptionIsSelected}
              productHandle={product.subscription.shopifyHandle}
              subscriptionSticker={product.content.subscriptionSticker}
            />
          ) : product.content.savingsPrice ? (
            <div className="ProductHero__prices flex">
              <p className="ProductHero__prices__savings-price my1_5 md:my2 mr1 text-medium ginto bold">
                ${product.content.savingsPrice}
              </p>
              <p className="my1_5 md:my2 text-medium ginto bold">
                ${variant.price.toFixed(2)}
              </p>
              {product.content.savingsSticker && (
                <Img
                  className="ProductHero__prices__savings-sticker"
                  src={product.content.savingsSticker.src}
                  alt={product.content.savingsSticker.caption || ''}
                />
              )}
            </div>
          ) : (
            <p className="my1_5 md:my2 text-medium ginto bold">
              ${variant.price.toFixed(2)}
            </p>
          )}
        </div>
        {!subscriptionIsSelected && product.variants.length > 1 && (
          <div className="ProductHero__variants py1 flex justify-between items-center">
            <div>
              <span className="block text-label">Pick a Tin Color</span>
            </div>
            <div>
              {product.variants.map((variant: ShopifyProductVariant) => {
                if (isSubscriptionVariant(variant)) {
                  return null;
                }

                console.log(
                  'BEEP',
                  variant.title.toUpperCase(),
                  Colors[variant.title.toUpperCase()],
                  Colors
                );

                return (
                  <Button
                    key={variant.id}
                    onClick={() => setActiveVariant(variant.id)}
                    className="ProductHero__variant ml1"
                    ariaLabel={`Select ${variant.title} variant`}
                    variant="no-style"
                    style={{
                      background:
                        activeVariant === variant.id
                          ? Colors[variant.title.toUpperCase()]
                          : 'transparent',
                    }}
                  >
                    <div
                      className="ProductHero__variant__inner"
                      style={{
                        background: Colors[variant.title.toUpperCase()],
                      }}
                    />
                  </Button>
                );
              })}
            </div>
          </div>
        )}

        {!product.content.isFreeGift && (
          <>
            <div className="ProductHero__add py1">
              {showQuantity(product) && (
                <Quantity
                  allowZero={false}
                  quantity={quantity}
                  onChange={handleQuantityChange}
                  showLabel={true}
                />
              )}
              <div className="ProductHero__buttons">
                {subscriptionIsSelected ? (
                  <Button
                    className="col-12 mt1_5 mb_5 blue"
                    label="Subscribe"
                    ariaLabel="Subscribe"
                    onClick={handleSubscribe}
                  />
                ) : (
                  <>
                    {variantSpecificMessage &&
                    variantSpecificMessage.buttonText ? (
                      <Button
                        className={cx('col-12 mt_5 mb_5', {
                          mt1_5: showQuantity(product),
                        })}
                        label={variantSpecificMessage.buttonText}
                        variant="primary"
                        ariaLabel={variantSpecificMessage.buttonText}
                        onClick={handleAdd}
                      />
                    ) : (
                      <Button
                        className={cx('col-12 mt_5 mb_5', {
                          mt1_5: showQuantity(product),
                        })}
                        label={product.content.heroButtonLabel || 'Add to Bag'}
                        variant="primary"
                        ariaLabel={
                          product.content.heroButtonLabel || 'Add to Bag'
                        }
                        onClick={handleAdd}
                      />
                    )}
                  </>
                )}
              </div>
              {!!product.content.inventoryMessage && (
                <div className="col-12 text-center">
                  <div className="text-xs">
                    <PrismicRichText
                      render={product.content.inventoryMessage}
                    />
                  </div>
                </div>
              )}
              {variantSpecificMessage &&
                variantSpecificMessage.inventoryShippingMessage && (
                  <div className="col-12 text-center">
                    <p className="text-xs">
                      {variantSpecificMessage.inventoryShippingMessage}
                    </p>
                  </div>
                )}
              {isWholesale && showWholesaleMessage && (
                <div className="col-12 text-center text-xs">
                  <p>
                    To order more than 8 cases, contact us at{' '}
                    <a href="mailto:hello@pineapplecollaborative.com">
                      hello@pineapplecollaborative.com
                    </a>
                  </p>
                </div>
              )}
              {!!product.content.showEmailCapture && (
                <div className="mt1">
                  <EmailSignup
                    mode="light"
                    listId={product.content.emailCaptureMailchimpListId}
                  />
                </div>
              )}
            </div>
          </>
        )}

        {product.content.information && (
          <div className="ProductHero__information-dropdown py1 pr_5">
            <div className="flex flex-wrap justify-between">
              <span className="text-label">Information</span>
              <Button
                onClick={toggleInformation}
                variant="no-style"
                className="text-label"
                label={informationIsActive ? '–' : '+'}
                ariaLabel="toggle information visibility"
              />
            </div>
            {informationIsActive && (
              <div className="mt1_5 bell text-medium">
                <PrismicRichText render={product.content.information} />
              </div>
            )}
          </div>
        )}
        {product.content.inspiration && (
          <div className="ProductHero__inspiration-dropdown py1 pr_5">
            <div className="flex flex-wrap justify-between">
              <span className="text-label">Inspiration</span>
              <Button
                onClick={toggleInspiration}
                variant="no-style"
                className="text-label"
                label={inspirationIsActive ? '–' : '+'}
                ariaLabel="toggle inspiration visibility"
              />
            </div>
            {inspirationIsActive && (
              <div className="mt1_5 bell text-medium">
                <PrismicRichText render={product.content.inspiration} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductHero;
