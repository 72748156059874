import React from 'react';
import { StarBurst } from './icons';

interface Props {
  quantity: number;
  color: string;
  textColor: string;
}

const StarNumber: React.FC<Props> = ({ quantity, color, textColor }) => (
  <div className="StarNumber relative text-center">
    <div className="StarNumber__star-burst absolute t0 l0 col-12">
      <StarBurst fill={color} />
    </div>
    <div
      className={`StarNumber__quantity relative color-${textColor} text-label z-1 pt1 md:pt0`}
    >
      {quantity}
    </div>
  </div>
);

export default StarNumber;
