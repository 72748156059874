import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const LeftArrowIcon: React.FC<Props> = ({
  className = '',
  fill = '#F2F2E8'
}) => {
  return (
    <svg
      className={className}
      width="31"
      height="14"
      viewBox="0 0 31 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.937694 9.29415L0.937694 4.66415L16.1077 0.464152L16.1077 3.92415L10.5977 5.33415L30.6377 5.33415L30.6377 8.38415L9.85769 8.38415L16.1077 9.89415L16.1077 13.2542L0.937694 9.29415Z"
        fill={fill}
      />
    </svg>
  );
};

export default LeftArrowIcon;
