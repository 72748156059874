import React from 'react';

import { Button } from 'components';

const Error404: React.FC = () => (
  <div className="Error404 vh100 flex flex-col items-center justify-center text-center px1">
    <span className="text-label color-brown mb1">404</span>
    <h2 className="col-12 md:col-5 mxauto mb1 text-large">
      You've lost your way
    </h2>
    <p className="col-12 md:col-4 mxauto mb3">
      This page has either moved or it doesn't exist.
    </p>
    <Button
      to="/"
      ariaLabel="Go home"
      variant="primary"
      label="Head back home"
    />
  </div>
);

export default Error404;
