import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

import ContainerBase from 'lib/ContainerBase';
import { fetchEvent } from 'state/actions/eventsActions';
import EventView from 'views/EventView';

import { GlobalState, PineappleEvent } from 'types';

interface StoreProps {
  event: PineappleEvent | null;
}

interface DispatchProps {
  actions: {
    fetchEvent: (slug: string) => void;
  };
}

type Props = RouteComponentProps & StoreProps & DispatchProps;

class EventContainer extends ContainerBase<Props, typeof EventView> {
  view = import('views/EventView');

  beforeModel = () => {
    const slug = get(this, 'props.match.params.slug', '');

    return this.props.actions.fetchEvent(slug);
  };
}

const mapStateToProps = (state: GlobalState) => ({
  event: state.events.event
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      fetchEvent
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventContainer);
