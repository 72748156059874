import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';

import Colors from 'constants/Colors';
import slugify from 'utils/slugify';
import isSubscriptionVariant from 'utils/isSubscriptionVariant';

import { Button } from 'components';
import { Product, ShopifyProductVariant } from 'types';

interface Props {
  product: Product;
  variantId?: string;
  addProduct: (product: Product, variantId?: string) => void;
  trackClick?: () => void;
}

const ProductListItem: React.FC<Props> = ({
  product,
  variantId,
  addProduct,
  trackClick = noop,
}) => {
  const [activeVariantId, setActiveVariantId] = useState(
    variantId || product.variants[0].id
  );

  const activeVariant = product.variants.find(
    (variant) => variant.id === activeVariantId
  );

  if (!product || !activeVariant) return null;

  const imageSrc = product.content.lineItemImage.src || activeVariant.image.src;

  return (
    <div
      className={cx(
        'ProductListItem flex flex-wrap py1 md:py1_5',
        slugify(product.title)
      )}
    >
      <div className="ProductListItem__image-wrapper overflow-hidden pr1">
        <div
          className="ProductListItem__image"
          style={{
            backgroundImage: `url(${imageSrc})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      </div>
      <div className="ProductListItem__info flex flex-col justify-between pl1 py_25 md:py_5 word-wrap">
        <div>
          <span className="none md:inline ginto text-medium letter-spacing-small mb_5">
            {product.title}
            <span className="text-small"> &mdash; ${activeVariant.price}</span>
          </span>
          <span className="block md:none text-small ginto letter-spacing-small mb_25">
            {product.title}
          </span>
        </div>
        <div className="text-right mt_5 md:mt0 md:mb_5">
          {!variantId &&
            product.variants.length > 1 &&
            product.variants.map((variant: ShopifyProductVariant) => {
              if (isSubscriptionVariant(variant)) {
                return null;
              }

              return (
                <Button
                  key={variant.id}
                  onClick={() => setActiveVariantId(variant.id)}
                  className="ProductListItem__variant"
                  ariaLabel={`Select ${variant.title} variant`}
                  variant="no-style"
                  style={{
                    background:
                      activeVariant.id === variant.id
                        ? Colors[variant.title.toUpperCase()]
                        : 'transparent',
                  }}
                >
                  <div
                    className="ProductListItem__variant__inner"
                    style={{
                      background: Colors[variant.title.toUpperCase()],
                    }}
                  />
                </Button>
              );
            })}
        </div>
        <div className="flex flex-wrap justify-between items-center">
          <div className="mt_5 md:mt0">
            <Link
              to={product.url}
              className="ProductListItem__info-link inline-block viksjoe cta letter-spacing-small"
            >
              Learn More
            </Link>
          </div>
          <div className="text-right mt_5 md:mt0">
            <Button
              className="wauto md:none"
              variant="primary--small"
              onClick={() => {
                trackClick();
                addProduct(product, activeVariantId);
              }}
              ariaLabel={`Add ${product.title}`}
              label="Add"
            />

            <Button
              className="wauto none md:block"
              variant="primary--small"
              onClick={() => {
                trackClick();
                addProduct(product, activeVariantId);
              }}
              ariaLabel={`Add ${product.title}`}
              label="Quick Add"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListItem;
