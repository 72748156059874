import ShopifyClient from 'lib/ShopifyClient';
import ContentClient from 'lib/ContentClient';

export const fetchShopifyProducts = () => ({
  type: 'FETCH_SHOPIFY_PRODUCTS',
  payload: ShopifyClient.fetchProducts(),
});

export const fetchPrismicProducts = () => ({
  type: 'FETCH_PRISMIC_PRODUCTS',
  payload: ContentClient.fetchProducts(),
});
