import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Flickity from 'react-flickity-component';

import slugify from 'utils/slugify';

import Colors from 'constants/Colors';
import {
  Button,
  Img,
  LeftArrowIcon,
  RightArrowIcon,
  StarBurst,
} from 'components';
import { Image, Color } from 'types';

interface LinkableCarouselSlide {
  title: string;
  eyebrow: string;
  description: string;
  buttonLink: string;
  internalLink: string;
  buttonText: string;
  marqueeText: string;
  image: Image;
}

export interface ILinkableCarousel {
  type: 'linkable_carousel';
  data: {
    title: string;
    description: string;
    color: Color;
    buttonLink: string;
    internalLink: string;
    buttonText: string;
    marqueeText: string;
    autoplay: boolean;
    slides: LinkableCarouselSlide[];
  };
}

interface Props {
  slice: ILinkableCarousel;
}

class LinkableCarousel extends Component<Props> {
  private flickity: any = null;

  handleNext = (): void => {
    if (this.flickity && this.flickity.next) this.flickity.next();
  };

  handlePrevious = (): void => {
    if (this.flickity && this.flickity.previous) this.flickity.previous();
  };

  render() {
    const { slice } = this.props;

    return (
      <div
        id={slugify(slice.data.marqueeText)}
        className="LinkableCarousel col-12 mb3 md:mb8"
      >
        {slice.data.title && (
          <div className="LinkableCarousel__info col-12 md:col-6 mxauto text-center mb2 md:mb3">
            <h2 className="text-large mb_5">{slice.data.title}</h2>
            {slice.data.description && <p>{slice.data.description}</p>}
            {slice.data.buttonText &&
              (slice.data.internalLink || slice.data.buttonLink) && (
                <div className="mt2 pb_25">
                  {slice.data.internalLink ? (
                    <Link className="cta" to={slice.data.internalLink}>
                      {slice.data.buttonText}
                    </Link>
                  ) : (
                    <a
                      className="cta"
                      href={slice.data.buttonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {slice.data.buttonText}
                    </a>
                  )}
                </div>
              )}
          </div>
        )}
        {slice.data.marqueeText && (
          <div className="LinkableCarousel__marquee">
            <div className="block py1">
              <div
                className="LinkableCarousel__marquee__overlay"
                style={{ background: Colors[slice.data.color] }}
              ></div>
              <div className="LinkableCarousel__marquee__carousel flex width-auto items-center">
                <div className="mr2">
                  <StarBurst className="LinkableCarousel__marquee__star" />
                </div>
                <span className="text-xxl color-cream transition uppercase viksjoe mr2">
                  {slice.data.marqueeText}
                </span>
                <div className="mr2">
                  <StarBurst className="LinkableCarousel__marquee__star" />
                </div>
                <span className="text-xxl color-cream transition uppercase viksjoe mr2">
                  {slice.data.marqueeText}
                </span>
                <div className="mr2">
                  <StarBurst className="LinkableCarousel__marquee__star" />
                </div>
                <span className="text-xxl color-cream transition uppercase viksjoe mr2">
                  {slice.data.marqueeText}
                </span>
                <div className="mr2">
                  <StarBurst className="LinkableCarousel__marquee__star" />
                </div>
                <span className="text-xxl color-cream transition uppercase viksjoe mr2">
                  {slice.data.marqueeText}
                </span>
                <div className="mr2">
                  <StarBurst className="LinkableCarousel__marquee__star" />
                </div>
                <span className="text-xxl color-cream transition uppercase viksjoe mr2">
                  {slice.data.marqueeText}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="LinkableCarousel__carousel col-12 overflow-hidden">
          <span
            className="LinkableCarousel__carousel__overlay"
            style={{ background: Colors[slice.data.color] }}
          ></span>
          <Flickity
            flickityRef={(c) => (this.flickity = c)}
            options={{
              prevNextButtons: false,
              pageDots: false,
              wrapAround: true,
            }}
          >
            {slice.data.slides.map(
              (slide: LinkableCarouselSlide, index: number) => (
                <div
                  className="LinkableCarousel__slide col-12 md:col-8 h100"
                  key={index}
                >
                  <div className="flex h100">
                    <div className="LinkableCarousel__slide__image h100">
                      <Img
                        className="h100 wauto"
                        src={slide.image.src}
                        alt={slide.image.caption || ''}
                      />
                    </div>
                    <div className="LinkableCarousel__slide__info h100 flex flex-col p1 md:p2 color-white relative">
                      <span className="LinkableCarousel__slide__eyebrow viksjoe text-small uppercase block mb1_5 md:mb1_5">
                        {slide.eyebrow}
                      </span>
                      <span className="LinkableCarousel__slide__title block mb1">
                        {slide.title}
                      </span>
                      <span className="LinkableCarousel__slide__copy block mb1">
                        <p>{slide.description}</p>
                      </span>
                      {slide.buttonText &&
                        (slide.internalLink || slide.buttonLink) && (
                          <div className="">
                            {slide.internalLink ? (
                              <Link
                                className="cta white"
                                to={slide.internalLink}
                              >
                                {slide.buttonText}
                              </Link>
                            ) : (
                              <a
                                className="cta white"
                                href={slide.buttonLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {slide.buttonText}
                              </a>
                            )}
                          </div>
                        )}
                      <div className="LinkableCarousel__slide__controls absolute col-12 b0 l0 flex justify-between items-center p1 md:p2">
                        <Button
                          ariaLabel="go to previous slide"
                          onClick={this.handlePrevious}
                          variant="no-style"
                        >
                          <LeftArrowIcon fill="#fff" />
                        </Button>
                        <span className="color-white viksjoe text-small">
                          {index + 1} / {slice.data.slides.length + 1}
                        </span>
                        <Button
                          ariaLabel="go to next slide"
                          onClick={this.handleNext}
                          variant="no-style"
                        >
                          <RightArrowIcon fill="#fff" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </Flickity>
        </div>
      </div>
    );
  }
}

export default LinkableCarousel;
