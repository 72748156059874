import React from 'react';
import cx from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import { YoutubeVideoOrientation } from 'types';

export interface IYouTubeVideoEmbed {
  type: 'youtube_video';
  data: {
    title: string;
    description: string;
    orientation: YoutubeVideoOrientation;
    videoEmbed?: any;
  };
}

interface Props {
  slice: IYouTubeVideoEmbed;
}

const YouTubeVideoEmbed: React.FC<Props> = ({ slice }) => {
  const videoLeft = slice.data.orientation === 'VIDEO_LEFT';
  const videoRight = slice.data.orientation === 'VIDEO_RIGHT';
  const videoCentered = slice.data.orientation === 'VIDEO_CENTER_TEXT_TOP';

  return (
    <div
      className={cx(
        'YouTubeVideoEmbed container-width mxauto col-12 px2 py1 md:py0 mb4 md:mb8',
        {
          'video-left': videoLeft,
          'video-right': videoRight,
          'video-centered': videoCentered,
        }
      )}
    >
      <div className="YouTubeVideoEmbed__text mb2">
        {slice.data.title && (
          <h2 className="YouTubeVideoEmbed__title text-large text-center mb1">
            {slice.data.title}
          </h2>
        )}

        {slice.data.description && (
          <div className="YouTubeVideoEmbed__description md:col-6 text-center bell">
            <PrismicRichText render={slice.data.description} />
          </div>
        )}
      </div>

      {slice.data.videoEmbed && (
        <div
          className="YouTubeVideoEmbed__video flex justify-center"
          dangerouslySetInnerHTML={{ __html: slice.data.videoEmbed.html }}
        />
      )}
    </div>
  );
};

export default YouTubeVideoEmbed;
