import { createSelector } from 'reselect';
import { parseISO } from 'date-fns';
import get from 'lodash/get';

import { RouteConstants } from 'constants/RouteMap';
import slices from 'state/serializers/slices';
import { PineappleEvent, Image, CitySlug, Slice } from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): PineappleEvent[] => {
    const results = get(response, 'results', []);

    return results.map((result: unknown) => {
      const title: string = get(result, 'data.title[0].text', '');
      const slug: string = get(result, 'uid', '');
      const image: Image = {
        src: get(result, 'data.image.url', ''),
        caption: get(result, 'data.image.alt', ''),
        credit: get(result, 'data.image.copyright', '')
      };
      const link: string = get(result, 'data.link.url', '');
      const start: Date = parseISO(get(result, 'data.start_time', ''));
      const end: Date = parseISO(get(result, 'data.end_time', ''));
      const city: string = get(result, 'data.city', '');

      const stickerImage: Image = {
        src: get(result, 'data.sticker_image.url', ''),
        caption: get(result, 'data.sticker_image.alt', ''),
        credit: get(result, 'data.sticker_image.copyright', '')
      };

      const stickerLink: string = get(result, 'data.sticker_link.url', '');

      let citySlug;
      switch (city) {
        case 'NYC': {
          citySlug = CitySlug.NYC;
          break;
        }
        case 'DC': {
          citySlug = CitySlug.DC;
          break;
        }
        case 'LA': {
          citySlug = CitySlug.LA;
          break;
        }
        case 'SF': {
          citySlug = CitySlug.SF;
          break;
        }
        default: {
          citySlug = CitySlug.NYC;
          break;
        }
      }

      const url: string = `${RouteConstants.EVENTS.path}/${citySlug}/${slug}`;
      const body: Slice[] = slices(get(result, 'data.body', []));

      return {
        title,
        slug,
        image,
        city,
        link,
        start,
        end,
        url,
        stickerImage,
        stickerLink,
        slices: body
      } as PineappleEvent;
    });
  }
);
