import { CheckoutAction } from 'state/actions/types/checkoutActionTypes';
import { UserError, Checkout } from 'types';

export interface CheckoutReducer {
  userErrors: UserError[];
  checkout: Checkout;
}

const initialState: CheckoutReducer = {
  userErrors: [],
  checkout: {
    completedAt: null,
    currencyCode: '',
    id: '',
    note: '',
    subtotalPrice: 0,
    totalPrice: 0,
    webUrl: '',
    lineItems: [],
  },
};

export default (
  state: CheckoutReducer = initialState,
  action: CheckoutAction
): CheckoutReducer => {
  switch (action.type) {
    case 'FETCH_CHECKOUT_FULFILLED':
    case 'CREATE_CHECKOUT_FULFILLED':
    case 'UPDATE_LINE_ITEMS_FULFILLED':
    case 'UPDATE_CART_NOTE_FULFILLED':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
