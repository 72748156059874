const defaults = {
  width: '1024',
  format: 'pjpg',
  quality: '70'
};

export default (
  url: string,
  width: string = defaults.width,
  format?: string,
  removeAutoCompression?: boolean
): string => {
  if (!url) return '';

  if (!format && url) {
    const imageFormat = url.split('.').pop();

    if (imageFormat === 'gif') {
      return `${url}&w=${width}`;
    }

    const getFormat = imageFormat === 'png' ? 'png' : defaults.format;

    if (url.includes('?')) {
      return `${url}&w=${width}&fm=${getFormat}&q=${defaults.quality}`;
    } else {
      return `${url}?w=${width}&fm=${getFormat}&q=${defaults.quality}`;
    }
  }

  if (url.includes('?')) {
    if (removeAutoCompression) {
      return `${url.replace(
        '?auto=compress,format',
        ''
      )}?w=${width}&fm=${format || defaults.format}&q=${defaults.quality}`;
    } else {
      return `${url}&w=${width}&fm=${format || defaults.format}&q=${
        defaults.quality
      }`;
    }
  } else {
    return `${url}?w=${width}&fm=${format || defaults.format}&q=${
      defaults.quality
    }`;
  }
};
