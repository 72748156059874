import { Action, Status } from 'types';

export interface StatusReducer {
  initializeApp: Status;
}

const initialState: StatusReducer = {
  initializeApp: Status.IDLE
};

export default (
  state: StatusReducer = initialState,
  action: Action
): StatusReducer => {
  switch (action.type) {
    case 'INITIALIZE_APP_PENDING':
      return { ...state, initializeApp: Status.PENDING };
    case 'INITIALIZE_APP_FULFILLED':
      return { ...state, initializeApp: Status.FULFILLED };
    case 'INITIALIZE_APP_REJECTED':
      return { ...state, initializeApp: Status.REJECTED };

    default:
      return state;
  }
};
