import { ProductsAction } from 'state/actions/types/productsActionTypes';
import { ShopifyProducts, PrismicProducts, SubscriptionProducts } from 'types';

export interface ProductsReducer {
  shopifyProducts: ShopifyProducts | null;
  prismicProducts: PrismicProducts | null;
  subscriptionProducts: SubscriptionProducts | null;
}

const initialState: ProductsReducer = {
  shopifyProducts: null,
  prismicProducts: null,
  subscriptionProducts: null,
};

export default (
  state: ProductsReducer = initialState,
  action: ProductsAction
): ProductsReducer => {
  switch (action.type) {
    case 'FETCH_SHOPIFY_PRODUCTS_FULFILLED':
      return {
        ...state,
        shopifyProducts: action.payload,
      };
    case 'FETCH_PRISMIC_PRODUCTS_FULFILLED':
      return {
        ...state,
        prismicProducts: action.payload,
      };
    case 'FETCH_SUBSCRIPTION_PRODUCTS_FULFILLED':
      return {
        ...state,
        subscriptionProducts: action.payload,
      };
    default:
      return state;
  }
};
