import React from 'react';
import cx from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import { Img } from 'components';
import { Image, ImageCaptionOrientation } from 'types';

export interface IImageCaption {
  type: 'image_caption';
  data: {
    orientation: ImageCaptionOrientation;
    text: any;
    image: Image;
    imageSecondary: Image;
  };
}

interface Props {
  slice: IImageCaption;
}

const ImageCaption: React.FC<Props> = ({ slice }) => {
  const imageCentered = slice.data.orientation === 'IMAGE_CENTERED';
  const imageSideBySide = slice.data.orientation === 'IMAGE_SIDE_BY_SIDE';
  const imageStacked = slice.data.orientation === 'IMAGE_STACKED';

  return (
    <div className="ImageCaption col-12 px2 container-width mxauto flex flex-wrap justify-between mb2 md:mb8">
      <div
        className={cx('ImageCaption__image', {
          'col-12': !imageSideBySide,
          'md:mt3': imageCentered,
          'col-6 pr_5 md:pr1': imageSideBySide,
          'pb_5 md:pb1': imageStacked,
        })}
      >
        <Img
          src={slice.data.image.src}
          alt={slice.data.image.caption || ''}
          caption={slice.data.image.caption || ''}
          credit={slice.data.image.credit || ''}
        />
      </div>

      {(imageSideBySide || imageStacked) && (
        <div
          className={cx('ImageCaption__image-secondary', {
            'col-6 pl_5 md:pl1': !imageStacked,
            'col-12 pt_5 md:pt1': imageStacked,
          })}
        >
          <Img
            src={slice.data.imageSecondary.src}
            alt={slice.data.imageSecondary.caption || ''}
            caption={slice.data.imageSecondary.caption || ''}
            credit={slice.data.imageSecondary.credit || ''}
          />
        </div>
      )}

      <div className="ImageCaption__text col-12 mb2 md:mb0 mxauto text-center md:col-7">
        <span className="bell">
          <PrismicRichText render={slice.data.text} />
        </span>
      </div>
    </div>
  );
};

export default ImageCaption;
