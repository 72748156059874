import React, { useEffect } from 'react';

export interface IStockist {
  type: 'code'; // TODO: why is this 'code' and not 'stockist_map' from Prismic?
  data: {
    widgetId: string;
  };
}

interface Props {
  slice: IStockist;
}

const Stockist: React.FC<Props> = ({ slice }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//stockist.co/embed/v1/widget.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.location.reload();
    };
  }, []);

  return (
    <div className="Stockist mxauto col-12 lg:col-8 px1_5 py1 md:py0 md:px2 mb4 md:mb8">
      <div data-stockist-widget-tag={slice.data.widgetId}></div>
    </div>
  );
};

export default Stockist;
