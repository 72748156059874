import { Dispatch } from 'redux';

import ApiClient, { ClientId } from 'lib/ApiClient';
import { client as Apollo } from 'lib/ApolloClient';
import { client as Prismic } from 'lib/PrismicClient';

import {
  fetchShopifyProducts,
  fetchPrismicProducts,
} from 'state/actions/productsActions';
// import {
//   fetchSubscriptionProducts,
//   fetchSubscriptionPrices,
// } from 'state/actions/subscriptionActions';
import { fetchEvents } from 'state/actions/eventsActions';
import { fetchReads } from 'state/actions/readsActions';
import { fetchOrCreateCheckout } from 'state/actions/checkoutActions';

import ContentClient from 'lib/ContentClient';

export const initializeApp = (checkoutId: string) => (
  dispatch: Dispatch<any>
) =>
  dispatch({
    type: 'INITIALIZE_APP',
    payload: new Promise((resolve, reject) => {
      ApiClient.setRef(ClientId.APOLLO, Apollo);
      ApiClient.setRef(ClientId.PRISMIC, Prismic);

      const initApp = Promise.all([
        dispatch(fetchGlobalSettings()),
        dispatch(fetchHomepage()),
        dispatch(fetchShopifyProducts()),
        dispatch(fetchPrismicProducts()),
        // ~~ NOTE: COMMENTING OUT THE LINES BELOW REMOVES THE SUBSCRIPTION OPTIONS
        // ~~ FOR The Olive Oil, The Olive Oil and Vinegar Set:
        // dispatch(fetchSubscriptionProducts()),
        // dispatch(fetchSubscriptionPrices()),
        dispatch(fetchEvents()),
        dispatch(fetchReads()),
        dispatch(fetchOrCreateCheckout(checkoutId)),
      ]);

      const timeout = new Promise((resolve, reject) => {
        setTimeout(() => reject('Timeout'), 10000);
      });

      const checkTimeout = Promise.race([initApp, timeout]);

      return checkTimeout.then(resolve).catch(reject);
    }),
  });

export const fetchGlobalSettings = () => ({
  type: 'FETCH_GLOBAL_SETTINGS',
  payload: ContentClient.fetchGlobalSettings(),
});

export const fetchHomepage = () => ({
  type: 'FETCH_HOMEPAGE',
  payload: ContentClient.fetchHomepage(),
});

export const fetchGenericPage = (slug: string) => ({
  type: 'FETCH_GENERIC_PAGE',
  payload: ContentClient.fetchGenericPage(slug),
});
