import React, { SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import { RichText as PrismicRichText } from 'prismic-reactjs';

// @ts-ignore
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import get from 'lodash/get';
import cx from 'classnames';

import { RouteConstants } from 'constants/RouteMap';
import Colors from 'constants/Colors';
import { Img, StarSticker, Button, StarBurst } from 'components';
import {
  Product,
  GlobalState,
  LineItemToUpdate,
  MergedLineItem,
  Image,
} from 'types';

import products from 'state/selectors/products';
import lineItems from 'state/selectors/lineItems';
import {
  updateLineItems,
  updateLineItemsWithFreeGifts,
} from 'state/actions/checkoutActions';
import { CheckoutReducer } from 'state/reducers/checkout';
import prismicImageUtil from 'utils/prismicImageUtil';
import getUpdatedLineItems from 'utils/getUpdatedLineItems';
import getCartContainsWholesale from 'utils/getCartContainsWholesale';
import getNewItemIsWholesale from 'utils/getNewItemIsWholesale';

interface ProductCollageItem {
  product: string;
  buttonText: string;
  hoverButtonText: string;
  stickerText: string;
}

export interface IProductCollage {
  type: 'product_collage';
  data: {
    title: string;
    description: string;
    showShopAllButton: boolean;
    showDecorativeStars: boolean;
    textAlignment: 'Left' | 'Center';
    linkableStickerImage: Image;
    linkableStickerPath: string;
    products: ProductCollageItem[];
  };
}

interface OwnProps {
  slice: IProductCollage;
}

interface DispatchProps {
  actions: {
    updateLineItems: (
      checkoutId: string,
      lineItems: LineItemToUpdate[]
    ) => void;
    updateLineItemsWithFreeGifts: (
      checkoutId: string,
      lineItems: LineItemToUpdate[]
    ) => void;
  };
}

interface StateProps {
  products: { [key: string]: Product } | null;
  lineItems: MergedLineItem[];
  checkout: CheckoutReducer;
}

type Props = OwnProps & StateProps & DispatchProps;

const ProductCollage: React.FC<Props> = ({
  slice,
  products,
  checkout,
  actions,
}) => {
  const isWholesale = slice.data.title.toLowerCase().includes('wholesale');
  const { user } = useIdentityContext();
  const [itemsAdded, setItemsAdded] = useState<any>({});

  if (isWholesale && !user) {
    return <Redirect to={RouteConstants.WHOLESALE.path} />;
  }

  const handleQuickAdd = (
    event: SyntheticEvent<HTMLElement>,
    product: Product
  ) => {
    const firstVariant = product.variants[0];
    if (!firstVariant.availableForSale) return;

    event.preventDefault();

    const newItemIsWholesale = getNewItemIsWholesale(firstVariant);
    const cartContainsWholesale = getCartContainsWholesale(
      checkout.checkout.lineItems
    );

    if (
      (!newItemIsWholesale && cartContainsWholesale) ||
      (newItemIsWholesale && !cartContainsWholesale)
    ) {
      actions.updateLineItems(checkout.checkout.id, [
        {
          variantId: firstVariant.id,
          quantity: 1,
        },
      ]);
    } else {
      const updatedLineItems = getUpdatedLineItems(
        checkout.checkout.lineItems,
        {
          variantId: firstVariant.id,
          quantity: 1,
        }
      );

      actions.updateLineItemsWithFreeGifts(
        checkout.checkout.id,
        updatedLineItems
      );
    }

    setItemsAdded({ [product.id]: true });
    setTimeout(() => {
      setItemsAdded({ [product.id]: false });
    }, 3000);
  };

  const getButtonLabel = (product: Product) => {
    if (itemsAdded[product.id]) return 'Added!';

    if (!product.variants[0].availableForSale) {
      return 'Sold Out';
    }

    if (product.variants.length === 1) {
      return 'Add to Cart';
    } else {
      return `Add to Cart (${product.variants[0].title})`;
    }
  };

  return (
    <div className="ProductCollage col-12 container-width mxauto px2 mb2 md:mb8 flex flex-col relative">
      {slice.data.showDecorativeStars && (
        <>
          <StarBurst
            className="ProductCollage__starburst__left"
            fill="#ccb16a"
          />
          <StarBurst
            className="ProductCollage__starburst__right"
            fill="#ccb16a"
          />
        </>
      )}

      <div className="ProductCollage__info text-center md:mb6 mb3">
        <h2 className="text-large mb_5">{slice.data.title}</h2>
        {!!slice.data.description && (
          <div className="mxauto md:col-6 col-12 bell text-medium">
            <PrismicRichText render={slice.data.description} />
          </div>
        )}
      </div>

      <div className="ProductCollage__linkable-sticker">
        {!!slice.data.linkableStickerImage.src &&
        slice.data.linkableStickerPath ? (
          <Link to={slice.data.linkableStickerPath}>
            <Img
              className="ProductCollage__linkable-sticker__image"
              src={prismicImageUtil(slice.data.linkableStickerImage.src)}
              alt={slice.data.linkableStickerImage.caption || ''}
            />
          </Link>
        ) : (
          <Img
            className="ProductCollage__linkable-sticker__image"
            src={prismicImageUtil(slice.data.linkableStickerImage.src)}
            alt={slice.data.linkableStickerImage.caption || ''}
          />
        )}
      </div>

      <div
        className={cx('ProductCollage__products transition', {
          'opacity-0': !products,
        })}
      >
        {slice.data.products.map((item: ProductCollageItem, i: number) => {
          const product: Product | null = get(products, item.product, null);

          if (!product) return null;

          return (
            <div
              key={item.product}
              className="ProductCollage__products__product col-12 relative"
            >
              <div
                className={cx(
                  'ProductCollage__products__product__sticker absolute t0',
                  { r0: i % 2, l0: !(i % 2) }
                )}
              >
                {!!item.stickerText && (
                  <StarSticker fill={Colors.YELLOW} text={item.stickerText} />
                )}
              </div>
              <Link
                className="ProductCollage__products__product__link relative no-style no-hover block"
                to={product.url}
              >
                <Img
                  className="ProductCollage__products__product__image block mb1 col-12"
                  src={prismicImageUtil(
                    product.content.gridImage.src,
                    '960',
                    product.content.gridImage.src.includes('.gif')
                      ? 'gif'
                      : 'pjpg'
                  )}
                  alt={product.content.gridImage.caption || ''}
                />
                <div className="ProductCollage__products__product__overlay">
                  {!!product.content.excerpt && (
                    <div className="ProductCollage__products__product__excerpt bell">
                      {product.content.excerpt}
                    </div>
                  )}
                  <Button
                    className="ProductCollage__products__product__quick-add-button"
                    variant="square--brown"
                    ariaLabel="Quick Add"
                    label={item.hoverButtonText || getButtonLabel(product)}
                    onClick={(event) => handleQuickAdd(event, product)}
                  />
                </div>
              </Link>
              <div
                className={cx({
                  'text-center': slice.data.textAlignment === 'Center',
                })}
              >
                <h3 className="ProductCollage__products__product__title my_5">
                  {product.title}
                </h3>
                {item.buttonText && (
                  <Link className="inline-block cta mt_5" to={product.url}>
                    {item.buttonText}
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {slice.data.showShopAllButton && (
        <Button
          className="ProductCollage__products__product__shop-all-button"
          variant="secondary"
          ariaLabel="Shop All"
          label="Shop All"
          to={RouteConstants.PRODUCTS.path}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  products: products(state),
  lineItems: lineItems(state),
  checkout: state.checkout,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { updateLineItems, updateLineItemsWithFreeGifts },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCollage);
