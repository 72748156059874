import React, { SyntheticEvent, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import useLocalStorage from 'utils/hooks/useLocalStorage';
import { Button, Img, EmailSignup } from 'components';
import { GlobalState, PopUp as IPopUp } from 'types';

interface StateProps {
  popUp: IPopUp | null;
}

interface Props extends StateProps {}

const PopUp: React.FC<Props> = ({ popUp }) => {
  const [isActive, setIsActive] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [isDismissedForever, setIsDismissedForever] = useLocalStorage(
    'popup:dismissed',
    false
  );
  const popUpBackground = useRef(null);

  useEffect(() => {
    if (popUp && popUp.isActive) {
      setTimeout(() => setIsActive(true), 6000);
    }
  }, [popUp, setIsActive]);

  if (!popUp || !popUp.isActive) return null;

  const handleClose = (event: SyntheticEvent<HTMLElement>) => {
    if (event.target === popUpBackground.current) {
      setIsActive(false);
    }
  };

  return (
    <div
      className={cx('PopUp col-12 flex items-center', {
        'is-dismissed': isDismissedForever,
        'is-active': isActive,
      })}
      onClick={(event) => handleClose(event)}
      ref={popUpBackground}
    >
      <div className={cx('PopUp__inner', { 'has-image': popUp.image.src })}>
        {popUp.image.src && (
          <Img
            className="PopUp__image"
            src={popUp.image.src}
            alt={popUp.image.caption || ''}
          />
        )}

        <div className="PopUp__wrapper px2 py3 w100">
          <div>
            <h2 className="mb1">{popUp.title}</h2>
            <div className="mb2 bell text-medium">
              <PrismicRichText render={popUp.text} />
            </div>
          </div>
          <EmailSignup
            mode="light"
            listId={popUp.mailchimpListId}
            onSuccess={() => {
              setShowSuccessMsg(true);
            }}
          />
          {showSuccessMsg && (
            <div className="PopUp__success-message bell text-medium">
              {popUp.successMessage}
            </div>
          )}
        </div>

        <Button
          variant="no-style"
          className="PopUp__close"
          onClick={() => {
            setIsActive(false);
            setIsDismissedForever(true);
          }}
          ariaLabel="Close this alert"
        >
          <Img src="/assets/images/close-icon.svg" alt="close icon" />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  popUp: state.settings.popUp,
});

export default connect(mapStateToProps)(PopUp);
