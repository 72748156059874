import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { Img, Button } from 'components';
import { PineappleEvent } from 'types';

interface Props {
  event: PineappleEvent;
}

const EventHero: React.FC<Props> = ({ event }) => (
  <div className="EventHero md:mt4 container-width mxauto px2 flex col-12 flex-wrap items-center mb3 md:mb8">
    <div className="EventHero__image col-12 md:col-6">
      <Img
        className="col-12"
        src={event.image.src}
        alt={event.image.caption || ''}
      />
    </div>
    <div className="EventHero__info col-12 md:col-6 p1_5 md:p3 text-center flex flex-wrap justify-center relative">
      {event.stickerImage.src && (
        <div className="EventHero__sticker absolute t0 r0 w100 flex justify-center">
          <div className="container-width w100 flex justify-end px1">
            <div className="EventHero__sticker__container">
              {event.stickerLink ? (
                <Link to={event.stickerLink} className="w100">
                  <Img src={event.stickerImage.src} alt={''} className="w100" />
                </Link>
              ) : (
                <Img src={event.stickerImage.src} alt={''} className="w100" />
              )}
            </div>
          </div>
        </div>
      )}
      <span className="EventHero__city block mb1 viksjoe uppercase text-medium">
        Pineapple {event.city}
      </span>
      <h1 className="EventHero__title mb1 capitalize mb1">{event.title}</h1>
      <div className="flex flex-wrap items-center justify-center">
        <span className="mb2 md:mb4">
          {format(event.start, 'MMMM dd')}, {format(event.start, 'h:mm')} -{' '}
          {format(event.end, 'h:mm a')}
        </span>
        <Button
          containerClassName="EventHero__button col-8"
          className="flex flex-wrap items-center justify-center"
          variant="primary"
          to={event.link}
          label="More Info"
          ariaLabel="More Info"
        />
      </div>
    </div>
  </div>
);

export default EventHero;
