import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';

export interface IRecipe {
  type: 'recipe';
  data: {
    ingredients: any;
    instructions: any;
  };
}

interface Props {
  slice: IRecipe;
}

const Recipe: React.FC<Props> = ({ slice }) => {
  return (
    <div className="Recipe prismic-rich-text col-12 px2 md:px2">
      <div className="Recipe__ingredients col-12 md:col-9 mxauto mb4">
        <h2>Ingredients</h2>
        <PrismicRichText render={slice.data.ingredients} />
      </div>
      <div className="Recipe__instructions col-12 md:col-9 mxauto md:mb4 mb2 md:py3">
        <h2>Instructions</h2>
        <PrismicRichText render={slice.data.instructions} />
      </div>
    </div>
  );
};

export default Recipe;
