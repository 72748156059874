import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const RightArrowIcon: React.FC<Props> = ({
  className = '',
  fill = '#F2F2E8'
}) => {
  return (
    <svg
      className={className}
      width="31"
      height="14"
      viewBox="0 0 31 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3377 4.21415V8.84415L15.1677 13.0442V9.58415L20.6777 8.17415H0.637695V5.12415H21.4177L15.1677 3.61415V0.25415L30.3377 4.21415Z"
        fill={fill}
      />
    </svg>
  );
};

export default RightArrowIcon;
