import { LineItems, LineItem, LineItemToUpdate } from 'types';

export default (
  currentLineItems: LineItems,
  lineItemToUpdate: LineItemToUpdate,
  replaceQuantity: boolean = false
): LineItemToUpdate[] => {
  let updated = false;

  const lineItemsToUpdate: LineItemToUpdate[] = currentLineItems.map(
    (lineItem: LineItem) => {
      if (lineItem.variantId === lineItemToUpdate.variantId) {
        updated = true;

        return {
          variantId: lineItem.variantId,
          quantity: replaceQuantity
            ? lineItemToUpdate.quantity
            : lineItemToUpdate.quantity + lineItem.quantity
        };
      }

      return {
        variantId: lineItem.variantId,
        quantity: lineItem.quantity
      };
    }
  );

  if (!updated) {
    lineItemsToUpdate.push(lineItemToUpdate);
  }

  return lineItemsToUpdate;
};
