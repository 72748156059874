import { PricesAction } from 'state/actions/types/pricesActionTypes';
import { SubscriptionPrices } from 'types';

export interface PricesReducer {
  subscriptionPrices: SubscriptionPrices | null;
}

const initialState: PricesReducer = {
  subscriptionPrices: null,
};

export default (
  state: PricesReducer = initialState,
  action: PricesAction
): PricesReducer => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTION_PRICES_FULFILLED':
      return {
        ...state,
        subscriptionPrices: action.payload,
      };
    default:
      return state;
  }
};
