import { createSelector } from 'reselect';
import get from 'lodash/get';

import { CheckoutReducer } from 'state/reducers/checkout';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): CheckoutReducer => {
    const name = Object.keys(get(response, 'data', {}))[0];
    const namedCheckout = get(response, `data.${name}.checkout`);
    const unnamedCheckout = get(response, 'data.node');
    const checkout = namedCheckout || unnamedCheckout || {};
    const lineItems = get(checkout, 'lineItems.edges', []).map(
      (lineItem: unknown) => {
        const node = get(lineItem, 'node', {});

        return {
          variantId: get(node, 'variant.id', ''),
          quantity: get(node, 'quantity', 0),
          productId: get(node, 'variant.product.id', ''),
          productHandle: get(node, 'variant.product.handle', ''),
        };
      }
    );

    return {
      userErrors: get(response, 'data.checkoutCreate.userErrors', []),
      checkout: {
        completedAt: get(checkout, 'completedAt', null),
        currencyCode: get(checkout, 'currencyCode', ''),
        id: get(checkout, 'id', ''),
        note: get(checkout, 'note', ''),
        subtotalPrice: parseFloat(
          get(checkout, 'subtotalPrice.amount', '0.00')
        ),
        totalPrice: parseFloat(get(checkout, 'totalPrice.amount', '0.00')),
        webUrl: get(checkout, 'webUrl', ''),
        lineItems,
      },
    };
  }
);
