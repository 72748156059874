import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import { Img } from 'components';
import { Image, ImageTextOrientation } from 'types';

export interface IImageText {
  type: 'image_text';
  data: {
    orientation: ImageTextOrientation;
    descriptionSize: 'Small' | 'Medium' | 'Large' | null;
    title: string;
    description: any;
    buttonText: string;
    internalLink: string;
    buttonLink: string;
    image: Image;
    imageSecondary: Image;
  };
}

interface Props {
  slice: IImageText;
}

const ImageText: React.FC<Props> = ({ slice }) => {
  const imageLeft = slice.data.orientation === 'IMAGE_LEFT';
  const imageRight = slice.data.orientation === 'IMAGE_RIGHT';
  const imageCenteredTextTop =
    slice.data.orientation === 'IMAGE_CENTER_TEXT_TOP';
  const imageCenteredTextBottom =
    slice.data.orientation === 'IMAGE_CENTER_TEXT_BOTTOM';
  const imageSideBySide = slice.data.orientation === 'IMAGE_SIDE_BY_SIDE';
  const imageStacked = slice.data.orientation === 'IMAGE_STACKED';

  return (
    <div
      className={cx(
        'ImageText col-12 px2 container-width mxauto flex flex-wrap justify-between mb2 md:mb8',
        {
          'flex-row-reverse': imageLeft,
          'items-center': imageLeft || imageRight,
        }
      )}
    >
      {(imageLeft || imageRight || imageCenteredTextTop) && (
        <div
          className={cx('ImageText__info col-12 mb2 md:mb0', {
            'md:col-5': !imageCenteredTextTop,
            'mxauto text-center md:col-7': imageCenteredTextTop,
          })}
        >
          <h2 className="text-large mb1">{slice.data.title}</h2>
          <span
            className={cx('bell', {
              'text-medium': slice.data.descriptionSize === 'Medium',
              'text-large': slice.data.descriptionSize === 'Large',
            })}
          >
            <PrismicRichText render={slice.data.description} />
          </span>

          {slice.data.buttonText &&
            (slice.data.internalLink || slice.data.buttonLink) && (
              <div className="mt2 pb_25">
                {slice.data.internalLink ? (
                  <Link className="cta" to={slice.data.internalLink}>
                    {slice.data.buttonText}
                  </Link>
                ) : (
                  <a
                    className="cta"
                    href={slice.data.buttonLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {slice.data.buttonText}
                  </a>
                )}
              </div>
            )}
        </div>
      )}

      <div
        className={cx('ImageText__image', {
          'col-12': !imageSideBySide,
          'md:col-6': imageLeft || imageRight,
          'md:mt3': imageCenteredTextTop || imageCenteredTextBottom,
          'col-6 pr_5 md:pr1': imageSideBySide,
          'pb_5 md:pb1': imageStacked,
        })}
      >
        <Img
          src={slice.data.image.src}
          alt={slice.data.image.caption || ''}
          caption={slice.data.image.caption || ''}
          credit={slice.data.image.credit || ''}
        />
      </div>

      {(imageSideBySide || imageStacked) && (
        <div
          className={cx('ImageText__image-secondary', {
            'col-6 pl_5 md:pl1': !imageStacked,
            'col-12 pt_5 md:pt1': imageStacked,
          })}
        >
          <Img
            src={slice.data.imageSecondary.src}
            alt={slice.data.imageSecondary.caption || ''}
            caption={slice.data.imageSecondary.caption || ''}
            credit={slice.data.imageSecondary.credit || ''}
          />
        </div>
      )}

      {(imageCenteredTextBottom || imageSideBySide || imageStacked) && (
        <div className="ImageText__info--bottom col-12 mb2 md:mb0 mxauto text-center md:col-7">
          <h2 className="text-large mb1">{slice.data.title}</h2>
          <span
            className={cx('bell', {
              'text-medium': slice.data.descriptionSize === 'Medium',
              'text-large': slice.data.descriptionSize === 'Large',
            })}
          >
            <PrismicRichText render={slice.data.description} />
          </span>

          {slice.data.buttonText &&
            (slice.data.internalLink || slice.data.buttonLink) && (
              <div className="mt2 pb_25">
                {slice.data.internalLink ? (
                  <Link className="cta" to={slice.data.internalLink}>
                    {slice.data.buttonText}
                  </Link>
                ) : (
                  <a
                    className="cta"
                    href={slice.data.buttonLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {slice.data.buttonText}
                  </a>
                )}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default ImageText;
