const getQuarter = (): string => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  return quarter.toString();
};

type QuarterColors = {
  [key: string]: string;
};

const quarterColors: QuarterColors = {
  '1': 'PINK',
  '2': 'YELLOW',
  '3': 'WHITE',
  '4': 'PINK',
};

const getSubscriptionQuarterColor = () => {
  return quarterColors[getQuarter()];
};

export default getSubscriptionQuarterColor;
