import { createSelector } from 'reselect';

import { GlobalState, Read } from 'types';

export default createSelector(
  (state: GlobalState): Read[] | null => {
    return state.reads.reads;
  },
  (reads: Read[] | null): { [key: string]: Read } => {
    if (!reads) return {};

    return reads.reduce((reads: { [key: string]: Read }, read: Read | null) => {
      if (!read) return reads;

      reads[read.slug] = read;

      return reads;
    }, {});
  }
);
