import * as Prismic from 'prismic-javascript';
import get from 'lodash/get';

import ApiClient from 'lib/ApiClient';
import events from 'state/serializers/events';
import reads from 'state/serializers/reads';
import globalSettings from 'state/serializers/globalSettings';
import homepage from 'state/serializers/homepage';
import genericPage from 'state/serializers/genericPage';
import prismicProducts from 'state/serializers/prismicProducts';

import {
  PineappleEvent,
  Read,
  GlobalSettings,
  Homepage,
  PrismicProducts,
  GenericPage,
} from 'types';

interface IContentClient {
  fetchEvents(): Promise<PineappleEvent[]>;
  fetchEvent(slug: string): Promise<PineappleEvent>;
  fetchLatestEvents(
    page: number,
    city: string | null
  ): Promise<PineappleEvent[] | null>;
  fetchReads(): Promise<Read[]>;
  fetchRead(slug: string): Promise<Read | null>;
  fetchLatestReads(
    pageSize: number,
    page: number,
    category?: string
  ): Promise<Read[] | null>;
  fetchGlobalSettings(): Promise<GlobalSettings>;
  fetchHomepage(): Promise<Homepage>;
  fetchProducts(): Promise<PrismicProducts>;
  fetchGenericPage(slug: string): Promise<GenericPage>;
}

const ContentClient: IContentClient = {
  fetchEvents(): Promise<PineappleEvent[]> {
    return ApiClient.content(Prismic.Predicates.at('document.type', 'event'), {
      orderings: '[my.event.start_time desc]',
    }).then((data) => {
      return events(data);
    });
  },
  fetchEvent(slug: string): Promise<PineappleEvent> {
    return ApiClient.content(
      Prismic.Predicates.at('my.event.uid', slug),
      {}
    ).then((data) => {
      return get(events(data), '[0]', null);
    });
  },
  fetchLatestEvents(
    page: number,
    city: string | null
  ): Promise<PineappleEvent[] | null> {
    return city
      ? ApiClient.content(
          [
            Prismic.Predicates.at('document.type', 'event'),
            Prismic.Predicates.at('my.event.city', city),
          ],
          {
            pageSize: 6,
            page,
            orderings: '[my.event.start_time desc]',
          }
        ).then((data) => {
          return events(data);
        })
      : ApiClient.content(Prismic.Predicates.at('document.type', 'event'), {
          pageSize: 6,
          page,
          orderings: '[my.event.start_time desc]',
        }).then((data) => {
          return events(data);
        });
  },
  fetchReads(): Promise<Read[]> {
    return ApiClient.content(Prismic.Predicates.at('document.type', 'read'), {
      orderings: '[document.first_publication_date desc]',
    }).then((data) => {
      return reads(data);
    });
  },
  fetchRead(slug: string, previewToken: string = ''): Promise<Read | null> {
    return ApiClient.content(
      Prismic.Predicates.at('my.read.uid', slug),
      previewToken ? { ref: previewToken } : {}
    ).then((data) => {
      return get(reads(data), '[0]', null);
    });
  },
  fetchLatestReads(
    pageSize: number,
    page: number,
    category: string = ''
  ): Promise<Read[] | null> {
    const predicates =
      category && category !== 'All'
        ? [
            Prismic.Predicates.at('document.type', 'read'),
            Prismic.Predicates.at('my.read.category', category),
          ]
        : Prismic.Predicates.at('document.type', 'read');

    return ApiClient.content(predicates, {
      pageSize,
      page,
      orderings: '[document.first_publication_date desc]',
    }).then((data) => {
      return reads(data);
    });
  },
  fetchGlobalSettings(): Promise<GlobalSettings> {
    return ApiClient.content(
      Prismic.Predicates.at('document.type', 'global_settings'),
      {}
    ).then((data) => {
      return globalSettings(data);
    });
  },
  fetchHomepage(): Promise<Homepage> {
    return ApiClient.content(
      Prismic.Predicates.at('document.type', 'homepage'),
      {}
    ).then((data) => {
      return homepage(data);
    });
  },
  fetchProducts(): Promise<PrismicProducts> {
    return ApiClient.content(
      Prismic.Predicates.at('document.type', 'product'),
      { pageSize: 50 }
    ).then((data) => {
      return prismicProducts(data);
    });
  },
  fetchGenericPage(slug: string): Promise<GenericPage> {
    return ApiClient.content(
      Prismic.Predicates.at('my.generic.uid', slug),
      {}
    ).then((data) => {
      return genericPage(data);
    });
  },
};

export default ContentClient;
