import { createSelector } from 'reselect';
import get from 'lodash/get';

import { SubscriptionProducts } from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): SubscriptionProducts => {
    const products = get(response, 'data', []).reduce(
      (sanitizedProducts: SubscriptionProducts, product: unknown) => {
        const id = get(product, 'id', '');
        const name = get(product, 'name', '');
        const description = get(product, 'description', '');
        const shopifyHandle = get(product, 'metadata.shopify_handle', '');
        const images = get(product, 'images', []).reduce(
          (sanitizedImages: string[], image: unknown) => {
            const src = get(image, '', '');
            return sanitizedImages.concat([src]);
          },
          []
        );

        sanitizedProducts[shopifyHandle] = {
          id,
          shopifyHandle,
          name,
          description,
          images,
        };

        return sanitizedProducts;
      },
      {}
    );

    return products;
  }
);
