import { loadStripe } from '@stripe/stripe-js';

import subscriptionProducts from 'state/serializers/subscriptionProducts';
import subscriptionPrices from 'state/serializers/subscriptionPrices';
import subscriptionCheckoutSession from 'state/serializers/subscriptionCheckoutSession';

export interface StripeCheckoutData {
  price: string;
  quantity: number;
  currentUrl: string;
  successUrl: string;
}

const StripeClient = {
  // Fetch subscription products (all products in Stripe should be subscription products)
  async fetchProducts() {
    const response = await fetch('/.netlify/functions/get-products')
      .then((res) => res.json())
      .catch((err) => console.error(err));

    return subscriptionProducts(response);
  },
  // Fetch subscription prices
  async fetchPrices() {
    const response = await fetch('/.netlify/functions/get-prices')
      .then((res) => res.json())
      .catch((err) => console.error(err));

    return subscriptionPrices(response);
  },
  // Fetch subscription checkout
  async createCheckout(data: StripeCheckoutData) {
    const response = await fetch('/.netlify/functions/create-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((err) => console.error(err));

    const stripe = await loadStripe(response.publishableKey);
    if (stripe) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.sessionId,
      });

      if (error) {
        console.error(error);
      }
    }
  },
  // Fetch subscription checkout session
  async fetchCheckoutSession(id: string) {
    const response = await fetch(
      '/.netlify/functions/create-checkout-session',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      }
    )
      .then((res) => res.json())
      .catch((err) => console.error(err));

    if (!response.error) {
      return subscriptionCheckoutSession(response);
    }
  },
};

export default StripeClient;
