import { createSelector } from 'reselect';
import get from 'lodash/get';

import {
  GlobalSettings,
  AlertBanner,
  Offer,
  PopUp,
  GiftsWithPurchase,
} from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): GlobalSettings => {
    const result = get(response, 'results[0]', {});
    const instagram = get(result, 'data.instagram_link.url', '');
    const mailchimpListId = get(result, 'data.mailchimp_list_id[0].text', '');
    const readsNavItems = get(result, 'data.reads_navigation', []).map(
      (item: unknown) => ({
        label: get(item, 'link_label', ''),
        url: get(item, 'link_url', ''),
      })
    );
    const showHolidayShopButton = get(
      result,
      'data.show_holiday_shop_button',
      false
    );

    const alertBanner: AlertBanner = {
      isActive: get(result, 'data.active', false),
      text: get(result, 'data.banner_text[0].text', ''),
      externalLink: get(result, 'data.external_link.url', ''),
      internalLink: get(result, 'data.internal_link[0].text', ''),
      linkText: get(result, 'data.link_text[0].text', ''),
    };

    const upsell: Offer = {
      isActive: get(result, 'data.upsell_is_active', false),
      title: get(result, 'data.upsell_title', ''),
      message: get(result, 'data.upsell_message', ''),
      buttonText: get(result, 'data.upsell_button_text', ''),
      goal: parseInt(get(result, 'data.goal', 0)) || 0,
      desktopImage: {
        src: get(result, 'data.upsell_desktop_image.url', ''),
        credit: get(result, 'data.upsell_desktop_image.copyright', ''),
        caption: get(result, 'data.upsell_desktop_image.alt', ''),
      },
      mobileImage: {
        src: get(result, 'data.upsell_mobile_image.url', ''),
        credit: get(result, 'data.upsell_mobile_image.copyright', ''),
        caption: get(result, 'data.upsell_mobile_image.alt', ''),
      },
      products: get(result, 'data.upsell_products', []).map(
        (product: unknown) => ({
          product: get(product, 'product'),
          variant: get(product, 'variant'),
        })
      ),
    };

    const giftsWithPurchase: GiftsWithPurchase[] = get(
      result,
      'data.gift_association',
      []
    ).map((item: unknown) => {
      return {
        productSlug: get(item, 'product.uid'),
        giftSlug: get(item, 'free_gift.uid'),
      };
    });

    const popUp: PopUp = {
      isActive: get(result, 'data.pop-up_is_active', false),
      title: get(result, 'data.pop-up_title[0].text', ''),
      text: get(result, 'data.pop-up_text', ''),
      image: {
        src: get(result, 'data.pop-up_image.url', ''),
        credit: get(result, 'data.pop-up_image.copyright', ''),
        caption: get(result, 'data.pop-up_image.alt', ''),
      },
      mailchimpListId: get(result, 'data.pop-up_mailchimp_list_id[0].text', ''),
      successMessage: get(result, 'data.pop-up_success_message[0].text', ''),
    };

    return {
      instagram,
      alertBanner,
      mailchimpListId,
      readsNavItems,
      showHolidayShopButton,
      upsell,
      popUp,
      giftsWithPurchase,
    } as GlobalSettings;
  }
);
