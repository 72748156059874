import { createSelector } from 'reselect';
import get from 'lodash/get';

import {
  imageTextOrientation,
  imageCaptionOrientation,
  youtubeVideoOrientation,
} from 'utils/imageOrientation';
import { Color, Slice } from 'types';

import { IImpactHeroCollage } from 'components/slices/ImpactHeroCollage';
import { IProductCollage } from 'components/slices/ProductCollage';
import { IProductGrid } from 'components/slices/ProductGrid';
import { ITestimonials } from 'components/slices/Testimonials';
import { IImageText } from 'components/slices/ImageText';
import { IImageCaption } from 'components/slices/ImageCaption';
import { IPressLogos } from 'components/slices/PressLogos';
import { IFoodMarquee } from 'components/slices/FoodMarquee';
import { IIconGrid } from 'components/slices/IconGrid';
import { IImageCollageHero } from 'components/slices/ImageCollageHero';
import { IProductRecirculation } from 'components/slices/ProductRecirculation';
import { IRichText } from 'components/slices/RichText';
import { IStockist } from 'components/slices/Stockist';
import { ILinkableCarousel } from 'components/slices/LinkableCarousel';
import { IRecirculation } from 'components/slices/Recirculation';
import { IFullWidthImage } from 'components/slices/FullWidthImage';
import { IReads } from 'components/slices/Reads';
import { IEvents } from 'components/slices/Events';
import { ISignUpForm } from 'components/slices/SignUpForm';
import { IWholesaleLogin } from 'components/slices/WholesaleLogin';
import { IContactUsForm } from 'components/slices/ContactUsForm';
import { IAccordions } from 'components/slices/Accordions';
import { ITitleBlock } from 'components/slices/TitleBlock';
import { IKeepReading } from 'components/slices/KeepReading';
import { IFullWidthImageCarousel } from 'components/slices/FullWidthImageCarousel';
import { IShopBlock } from 'components/slices/ShopBlock';
import { IQuestionnaire } from 'components/slices/Questionnaire';
import { IRichTextPicks } from 'components/slices/RichTextPicks';
import { IRichTextProducts } from 'components/slices/RichTextProducts';
import { IRecipe } from 'components/slices/Recipe';
import { IYouTubeVideoEmbed } from 'components/slices/YouTubeVideoEmbed';
import { IVideos } from 'components/slices/Videos';
import { IReadsCollage } from 'components/slices/ReadsCollage';

export default createSelector(
  (slices: unknown[]) => slices,
  (slices: unknown[]): Slice[] =>
    slices
      .map((slice: unknown): Slice | null => {
        const type = get(slice, 'slice_type', '');

        if (!type) return null;

        switch (type) {
          case 'hero_collage': {
            return {
              type,
              data: {
                title: get(slice, 'primary.hero_title[0].text', ''),
                description: get(slice, 'primary.description[0].text', ''),
                buttonLink: get(slice, 'primary.button_link.url', ''),
                internalLink: get(slice, 'primary.internal_link[0].text', ''),
                buttonText: get(slice, 'primary.button_text[0].text', ''),
                bottomImage: {
                  src: get(slice, 'primary.bottom_image.url', ''),
                  caption: get(slice, 'primary.bottom_image.alt', ''),
                  credit: get(slice, 'primary.bottom_image.copyright', ''),
                },
                topImage: {
                  src: get(slice, 'primary.top_image.url', ''),
                  caption: get(slice, 'primary.top_image.alt', ''),
                  credit: get(slice, 'primary.top_image.copyright', ''),
                },
              },
            } as IImpactHeroCollage;
          }
          case 'product_collage': {
            return {
              type,
              data: {
                title: get(slice, 'primary.title[0].text', ''),
                description: get(slice, 'primary.description', ''),
                showShopAllButton: get(slice, 'primary.shop_all_button', false),
                showDecorativeStars: get(
                  slice,
                  'primary.decorative_stars',
                  false
                ),
                textAlignment: get(slice, 'primary.text_alignment', 'Left'),
                products: get(slice, 'items', []).map((product: unknown) => ({
                  product: get(product, 'product.uid', ''),
                  buttonText: get(product, 'button_text[0].text', ''),
                  hoverButtonText: get(
                    product,
                    'hover_button_text[0].text',
                    ''
                  ),
                  stickerText: get(product, 'sticker_text[0].text', ''),
                })),
                linkableStickerImage: {
                  src: get(slice, 'primary.linkable_sticker_image.url', ''),
                  caption: get(slice, 'primary.linkable_sticker_image.alt', ''),
                  credit: get(
                    slice,
                    'primary.linkable_sticker_image.copyright',
                    ''
                  ),
                },
                linkableStickerPath: get(
                  slice,
                  'primary.linkable_sticker_path[0].text',
                  ''
                ),
              },
            } as IProductCollage;
          }
          case 'product_grid': {
            return {
              type,
              data: {
                title: get(slice, 'primary.grid_title[0].text', ''),
                logo: {
                  src: get(slice, 'primary.grid_logo.url', ''),
                  caption: get(slice, 'primary.grid_logo.alt', ''),
                  credit: get(slice, 'primary.grid_logo.copyright', ''),
                },
                linkableStickerImage: {
                  src: get(slice, 'primary.linkable_sticker_image.url', ''),
                  caption: get(slice, 'primary.linkable_sticker_image.alt', ''),
                  credit: get(
                    slice,
                    'primary.linkable_sticker_image.copyright',
                    ''
                  ),
                },
                linkableStickerPath: get(
                  slice,
                  'primary.linkable_sticker_path[0].text',
                  ''
                ),
                description: get(slice, 'primary.grid_description', ''),
                products: get(slice, 'items', []).map((product: unknown) => ({
                  product: get(product, 'product.uid', ''),
                })),
              },
            } as IProductGrid;
          }
          case 'testimonials': {
            return {
              type,
              data: {
                color: Object.keys(Color).find(
                  (color) =>
                    color === get(slice, 'primary.color', 'Teal').toUpperCase()
                ),
                testimonials: get(slice, 'items', []).map((item: unknown) => ({
                  quote: get(item, 'quote[0].text', ''),
                  person: get(item, 'name[0].text', ''),
                })),
              },
            } as ITestimonials;
          }
          case 'image_text': {
            return {
              type,
              data: {
                orientation: imageTextOrientation(
                  get(slice, 'primary.orientation', 'Image on left')
                ),
                descriptionSize: get(
                  slice,
                  'primary.description_text_size',
                  'Small'
                ),
                title: get(slice, 'primary.image_text_title[0].text', ''),
                description: get(slice, 'primary.image_text_description', ''),
                buttonText: get(
                  slice,
                  'primary.image_text_button_text[0].text',
                  ''
                ),
                internalLink: get(
                  slice,
                  'primary.image_text_internal_link[0].text',
                  ''
                ),
                buttonLink: get(
                  slice,
                  'primary.image_text_button_link.url',
                  ''
                ),
                image: {
                  src: get(slice, 'primary.image_text_image.url', ''),
                  caption: get(slice, 'primary.image_text_image.alt', ''),
                  credit: get(slice, 'primary.image_text_image.copyright', ''),
                },
                imageSecondary: {
                  src: get(slice, 'primary.image_text_image_secondary.url', ''),
                  caption: get(
                    slice,
                    'primary.image_text_image_secondary.alt',
                    ''
                  ),
                  credit: get(
                    slice,
                    'primary.image_text_image_secondary.copyright',
                    ''
                  ),
                },
              },
            } as IImageText;
          }
          case 'image_caption': {
            return {
              type,
              data: {
                orientation: imageCaptionOrientation(
                  get(slice, 'primary.orientation', 'Image centered')
                ),
                text: get(slice, 'primary.image_caption_text', ''),
                image: {
                  src: get(slice, 'primary.image_caption_image.url', ''),
                  caption: get(slice, 'primary.image_caption_image.alt', ''),
                  credit: get(
                    slice,
                    'primary.image_caption_image.copyright',
                    ''
                  ),
                },
                imageSecondary: {
                  src: get(
                    slice,
                    'primary.image_caption_image_secondary.url',
                    ''
                  ),
                  caption: get(
                    slice,
                    'primary.image_caption_image_secondary.alt',
                    ''
                  ),
                  credit: get(
                    slice,
                    'primary.image_caption_image_secondary.copyright',
                    ''
                  ),
                },
              },
            } as IImageCaption;
          }
          case 'press_logos': {
            return {
              type,
              data: {
                links: get(slice, 'items', []).map((item: unknown) => ({
                  title: get(item, 'link_title', ''),
                  publication: get(item, 'publication[0].text', ''),
                  url: get(item, 'url.url', '') || get(item, 'link.url', ''),
                  logo: {
                    src: get(item, 'logo.url', ''),
                    caption: get(item, 'logo.alt', ''),
                    credit: get(item, 'logo.copyright', ''),
                  },
                  linkText: get(item, 'link_text', ''),
                  internalLink: get(item, 'internal_link', ''),
                  text: get(item, 'text[0].text', ''),
                })),
              },
            } as IPressLogos;
          }
          case 'food_marquee': {
            return {
              type,
            } as IFoodMarquee;
          }
          case 'icon_grid': {
            return {
              type,
              data: {
                title: get(slice, 'primary.icon_grid_title[0].text', ''),
                description: get(
                  slice,
                  'primary.icon_grid_description[0].text',
                  ''
                ),
                items: get(slice, 'items', []).map((item: unknown) => ({
                  image: {
                    src: get(item, 'image.url', ''),
                    caption: get(item, 'image.alt', ''),
                    credit: get(item, 'image.copyright', ''),
                  },
                  text: get(item, 'text[0].text', ''),
                })),
              },
            } as IIconGrid;
          }
          case 'product_recirculation': {
            return {
              type,
              data: {
                title: get(
                  slice,
                  'primary.product_recirculation_title[0].text'
                ),
                description: get(
                  slice,
                  'primary.product_recirculation_description[0].text'
                ),
                product: get(
                  slice,
                  'primary.product_recirculation_product.uid',
                  ''
                ),
                buttonText: get(
                  slice,
                  'primary.product_recirculation_button_text[0].text'
                ),
              },
            } as IProductRecirculation;
          }
          case 'image_collage_hero': {
            return {
              type,
              data: {
                title: get(slice, 'primary.image_collage_hero_title[0].text'),
                description: get(
                  slice,
                  'primary.image_collage_hero_description[0].text'
                ),
                eyebrow: get(
                  slice,
                  'primary.image_collage_hero_eyebrow[0].text',
                  ''
                ),
                image1: {
                  src: get(slice, 'primary.image_collage_hero_image_1.url', ''),
                  caption: get(
                    slice,
                    'primary.image_collage_hero_image_1.alt',
                    ''
                  ),
                  credit: get(
                    slice,
                    'primary.image_collage_hero_image_1.copyright',
                    ''
                  ),
                },
                image2: {
                  src: get(slice, 'primary.image_collage_hero_image_2.url', ''),
                  caption: get(
                    slice,
                    'primary.image_collage_hero_image_2.alt',
                    ''
                  ),
                  credit: get(
                    slice,
                    'primary.image_collage_hero_image_2.copyright',
                    ''
                  ),
                },
                image3: {
                  src: get(slice, 'primary.image_collage_hero_image_3.url', ''),
                  caption: get(
                    slice,
                    'primary.image_collage_hero_image_3.alt',
                    ''
                  ),
                  credit: get(
                    slice,
                    'primary.image_collage_hero_image_3.copyright',
                    ''
                  ),
                },
              },
            } as IImageCollageHero;
          }
          case 'rich_text': {
            return {
              type,
              data: slice,
            } as IRichText;
          }
          case 'code': {
            return {
              type,
              data: {
                widgetId: get(slice, 'primary.stockist_widget_tag[0].text', ''),
              },
            } as IStockist;
          }
          case 'linkable_carousel': {
            return {
              type,
              data: {
                title: get(slice, 'primary.carousel_title[0].text', ''),
                description: get(
                  slice,
                  'primary.carousel_description[0].text',
                  ''
                ),
                color: Object.keys(Color).find(
                  (color) =>
                    color ===
                    get(slice, 'primary.carousel_color', 'Blue').toUpperCase()
                ),
                buttonLink: get(slice, 'primary.carousel_button_link.url', ''),
                internalLink: get(
                  slice,
                  'primary.carousel_internal_link[0].text',
                  ''
                ),
                buttonText: get(
                  slice,
                  'primary.carousel_button_text[0].text',
                  ''
                ),
                marqueeText: get(slice, 'primary.marquee_text[0].text', ''),
                autoplay:
                  get(slice, 'primary.carousel_autoplay', 'Disabled') ===
                  'Enabled',
                slides: get(slice, 'items', []).map((item: any) => ({
                  title: get(item, 'slide_title[0].text', ''),
                  eyebrow: get(item, 'slide_eyebrow[0].text', ''),
                  description: get(item, 'slide_description[0].text', ''),
                  buttonLink: get(item, 'slide_button_link.url', ''),
                  internalLink: get(item, 'slide_internal_link[0].text', ''),
                  buttonText: get(item, 'slide_button_text[0].text', ''),
                  image: {
                    src: get(item, 'slide_image.url', ''),
                    caption: get(item, 'slide_image.alt', ''),
                    credit: get(item, 'slide_image.copyright', ''),
                  },
                })),
              },
            } as ILinkableCarousel;
          }
          case '2-up_recirculation': {
            return {
              type,
              data: {
                title: get(slice, 'primary.recirculation_title[0].text', ''),
                description: get(
                  slice,
                  'primary.recirculation_description[0].text',
                  ''
                ),
                slides: get(slice, 'items', []).map((item: any) => ({
                  image: {
                    src: get(item, 'image.url', ''),
                    caption: get(item, 'image.alt', ''),
                    credit: get(item, 'image.copyright', ''),
                  },
                  eyebrow: get(item, 'slide_eyebrow[0].text', ''),
                  title: get(item, 'slide_title[0].text', ''),
                  buttonLink: get(item, 'button_link.url', ''),
                  internalLink: get(item, 'internal_link[0].text', ''),
                  buttonText: get(item, 'button_text[0].text', ''),
                })),
              },
            } as IRecirculation;
          }
          case 'full-width_image': {
            return {
              type,
              data: {
                mobileImage: {
                  src: get(
                    slice,
                    'primary.full_width_image_mobile_image.url',
                    ''
                  ),
                  caption: get(
                    slice,
                    'primary.full_width_image_mobile_image.alt',
                    ''
                  ),
                  credit: get(
                    slice,
                    'primary.full_width_image_mobile_image.copyright',
                    ''
                  ),
                },
                desktopImage: {
                  src: get(
                    slice,
                    'primary.full_width_image_desktop_image.url',
                    ''
                  ),
                  caption: get(
                    slice,
                    'primary.full_width_image_desktop_image.alt',
                    ''
                  ),
                  credit: get(
                    slice,
                    'primary.full_width_image_desktop_image.copyright',
                    ''
                  ),
                },
                buttonLink: get(
                  slice,
                  'primary.full_width_image_external_link.url',
                  ''
                ),
                internalLink: get(
                  slice,
                  'primary.full_width_image_internal_link[0].text',
                  ''
                ),
                buttonText: get(slice, 'primary.link_label[0].text', ''),
                stickerImage: {
                  src: get(slice, 'primary.sticker_image.url', ''),
                  caption: get(slice, 'primary.sticker_image.alt', ''),
                },
                stickerLink: get(slice, 'primary.sticker_link[0].text', ''),
                stickerAlignment: get(
                  slice,
                  'primary.full-width_image_sticker_alignment',
                  'Left'
                ),
              },
            } as IFullWidthImage;
          }
          case 'reads': {
            return {
              type,
              data: {
                title: get(slice, 'primary.reads_title[0].text', ''),
                description: get(slice, 'primary.reads_description', ''),
                buttonLink: get(slice, 'primary.external_link.url', ''),
                internalLink: get(
                  slice,
                  'primary.reads_internal_link[0].text',
                  ''
                ),
                buttonText: get(slice, 'primary.reads_button_text[0].text', ''),
                category: get(slice, 'primary.category', ''),
                reads:
                  get(
                    slice,
                    'primary.automatic',
                    'Automatically pull latest'
                  ) === 'Automatically pull latest'
                    ? null
                    : get(slice, 'items', []).map((item: any) => item.read.uid),
                useLatest:
                  get(
                    slice,
                    'primary.automatic',
                    'Automatically pull latest'
                  ) === 'Automatically pull latest',
                paginate: get(slice, 'primary.load_more', 'No') !== 'No',
              },
            } as IReads;
          }
          case 'events': {
            return {
              type,
              data: {
                title: get(slice, 'primary.events_title[0].text', ''),
                description: get(
                  slice,
                  'primary.events_description[0].text',
                  ''
                ),
                buttonLink: get(slice, 'primary.events_external_link.url', ''),
                internalLink: get(
                  slice,
                  'primary.events_internal_link[0].text',
                  ''
                ),
                buttonText: get(
                  slice,
                  'primary.events_button_text[0].text',
                  ''
                ),
                city: get(slice, 'primary.filter_by_city', 'No'),
                paginate: get(slice, 'primary.load_more', 'No') !== 'No',
              },
            } as IEvents;
          }
          case 'sign-up_form': {
            return {
              type,
              data: {
                title: get(slice, 'primary.signup_title[0].text', ''),
                description: get(
                  slice,
                  'primary.signup_description[0].text',
                  ''
                ),
                listId: get(slice, 'primary.signup_list_id[0].text', ''),
              },
            } as ISignUpForm;
          }
          case 'wholesale_login': {
            return {
              type,
              data: {
                title: get(slice, 'primary.wholesale_login_title[0].text', ''),
                description: get(
                  slice,
                  'primary.wholesale_login_description',
                  ''
                ),
              },
            } as IWholesaleLogin;
          }
          case 'contact-us_form': {
            return {
              type,
              data: {
                title: get(slice, 'primary.contact-us_title[0].text', ''),
                description: get(slice, 'primary.contact-us_description', ''),
              },
            } as IContactUsForm;
          }
          case 'accordions': {
            return {
              type,
              data: {
                title: get(slice, 'primary.accordions_title[0].text', ''),
                description: get(
                  slice,
                  'primary.accordions_description[0].text',
                  ''
                ),
                items: get(slice, 'items', []).map((item: any) => ({
                  question: get(item, 'question[0].text'),
                  answer: get(item, 'answer'),
                })),
              },
            } as IAccordions;
          }
          case 'title_block': {
            return {
              type,
              data: {
                title: get(slice, 'primary.title_block_title[0].text', ''),
                eyebrow: get(slice, 'primary.title_block_eyebrow[0].text', ''),
                text: get(slice, 'primary.title_block_text'),
                credit: get(slice, 'primary.title_block_credit[0].text', ''),
                creditLink: get(slice, 'primary.credit_link.url', ''),
                highlight: get(slice, 'primary.has_text_highlight', 'No'),
              },
            } as ITitleBlock;
          }
          case 'full-width_image_carousel': {
            return {
              type,
              data: {
                images: get(slice, 'items', []).map((item: any) => ({
                  src: get(item, 'image.url', ''),
                  caption: get(item, 'image.alt', ''),
                  credit: get(item, 'image.copyright', ''),
                })),
                autoplay:
                  get(slice, 'primary.carousel_autoplay', 'Disabled') ===
                  'Enabled',
              },
            } as IFullWidthImageCarousel;
          }
          case 'shop_block': {
            return {
              type,
              data: {
                products: get(slice, 'items', []).map((product: unknown) =>
                  get(product, 'product.uid', '')
                ),
              },
            } as IShopBlock;
          }
          case 'keep_reading': {
            return {
              type,
              data: {
                reads:
                  get(
                    slice,
                    'primary.automatic',
                    'Automatically pull latest'
                  ) === 'Automatically pull latest'
                    ? null
                    : get(slice, 'items', []).map((item: any) => item.read.uid),
                useLatest:
                  get(
                    slice,
                    'primary.automatic',
                    'Automatically pull latest'
                  ) === 'Automatically pull latest',
              },
            } as IKeepReading;
          }
          case 'questionnaire': {
            return {
              type,
              data: {
                title: get(slice, 'primary.questionnaire_title[0].text'),
                questions: get(slice, 'items', []).map((item: any) => ({
                  question: get(item, 'question[0].text', ''),
                  answer: get(item, 'answer'),
                })),
              },
            } as IQuestionnaire;
          }
          case 'rich_text_and_picks': {
            return {
              type,
              data: {
                title: get(slice, 'primary.rich_text_and_picks_title[0].text'),
                richText: get(slice, 'primary.rich_text'),
                picks: get(slice, 'items', []).map((item: any) => ({
                  image: {
                    src: get(item, 'image.url', ''),
                    caption: get(item, 'image.alt', ''),
                    credit: get(item, 'image.copyright', ''),
                  },
                  title: get(item, 'pick_title[0].text', ''),
                  link: get(item, 'link.url', ''),
                })),
              },
            } as IRichTextPicks;
          }
          case 'rich_text___products': {
            return {
              type,
              data: {
                title: get(slice, 'primary.rich_text_products_title[0].text'),
                richText: get(slice, 'primary.rich_text_products_text'),
                products: get(slice, 'items', []).map((product: unknown) =>
                  get(product, 'product.uid', '')
                ),
              },
            } as IRichTextProducts;
          }
          case 'recipe': {
            return {
              type,
              data: {
                ingredients: get(slice, 'primary.ingredients'),
                instructions: get(slice, 'primary.instructions'),
              },
            } as IRecipe;
          }
          case 'youtube_video': {
            return {
              type,
              data: {
                title: get(slice, 'primary.youtube_video_title[0].text'),
                description: get(
                  slice,
                  'primary.youtube_video_description',
                  ''
                ),
                orientation: youtubeVideoOrientation(
                  get(
                    slice,
                    'primary.youtube_video_orientation',
                    'Video on left'
                  )
                ),
                videoEmbed: get(slice, 'primary.youtube_video_embed'),
              },
            } as IYouTubeVideoEmbed;
          }
          case 'youtube_videos': {
            return {
              type,
              data: {
                videos: get(slice, 'items', []).map((item: unknown) => ({
                  title: get(item, 'youtube_video_title[0].text', ''),
                  description: get(
                    item,
                    'youtube_video_description[0].text',
                    ''
                  ),
                  videoEmbed: get(item, 'youtube_video_embed'),
                })),
              },
            } as IVideos;
          }
          case 'reads_collage': {
            return {
              type,
              data: {
                title: get(slice, 'primary.reads_collage_title[0].text', ''),
                description: get(
                  slice,
                  'primary.reads_collage_description',
                  ''
                ),
                showDecorativeStars: get(
                  slice,
                  'primary.decorative_stars',
                  false
                ),
                textAlignment: get(slice, 'primary.text_alignment', 'Left'),
                reads: get(slice, 'items', []).map((read: unknown) => ({
                  read: get(read, 'read.uid', ''),
                  buttonText: get(read, 'button_text[0].text', ''),
                })),
              },
            } as IReadsCollage;
          }
          default: {
            console.warn('No Slice Match: ', type);
            return null;
          }
        }
      })
      .reduce((serializedSlices: Slice[], slice: Slice | null): Slice[] => {
        if (!!slice) return serializedSlices.concat([slice]);

        return serializedSlices;
      }, [])
);
