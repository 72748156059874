import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';
import cx from 'classnames';

import Routes from 'routes';
import { GlobalState, Status, Product, MergedLineItem, Offer } from 'types';

// @ts-ignore
import NetlifyIdentityContext from 'react-netlify-identity-gotrue';

import { initializeApp } from 'state/actions/appActions';
import { CheckoutReducer } from 'state/reducers/checkout';
import products from 'state/selectors/products';
import * as gtag from 'lib/gtag';

import { Nav, Footer, ErrorPage, PopUp } from 'components';

import 'what-input';
import 'styles/App.scss';

interface OwnProps {
  initializeAppStatus: Status;
  actions: {
    initializeApp(checkoutId: string): void;
  };
  checkout: CheckoutReducer;
  products: { [key: string]: Product } | null;
  lineItems: MergedLineItem[];
  upsell: Offer | null;
}

type Props = OwnProps & RouteComponentProps;

class App extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const { initializeAppStatus, actions, checkout } = props;
    const checkoutId = get(checkout, 'checkout.id', '');

    if (initializeAppStatus === Status.IDLE) {
      actions.initializeApp(checkoutId);
    }
  }

  componentDidMount() {
    const { products, checkout, upsell } = this.props;

    if (
      upsell &&
      products &&
      checkout &&
      (upsell.goal === 0 || upsell.goal > checkout.checkout.totalPrice)
    ) {
      gtag.event({
        action: 'load',
        category: 'upsell',
        label: 'will_show',
        value: '',
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { location } = this.props;

    if (this.props.initializeAppStatus === Status.REJECTED) {
      return (
        <div className="App bg-color-cream vp-min-height transition">
          <ErrorPage />
        </div>
      );
    }

    const url = process.env.REACT_APP_NETLIFY_URL;

    return (
      <NetlifyIdentityContext url={url}>
        <main
          className={cx(
            'App relative bg-color-cream color-black vp-min-height transition',
            { 'opacity-0': this.props.initializeAppStatus !== Status.FULFILLED }
          )}
        >
          <PopUp />
          <Nav />
          <a className="App__skip-to-content-link" href="#main" tabIndex={1}>
            Skip to content
          </a>
          <div className="content vp-min-height relative" id="main">
            <div className="relative z-1">
              <Routes location={location} />
            </div>
            <div className="App__grain absolute h100 col-12 t0 l0 r0 b0" />
          </div>
          <Footer />
        </main>
      </NetlifyIdentityContext>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  initializeAppStatus: state.status.initializeApp,
  checkout: state.checkout,
  upsell: state.settings.upsell,
  products: products(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      initializeApp,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
