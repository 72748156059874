import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';

export interface IRichText {
  type: 'rich_text';
  data: {
    primary: any;
  };
}

interface Props {
  slice: IRichText;
}

/* @ts-ignore */
const RichText: React.FC<Props> = ({ slice }) => (
  <div className="RichText prismic-rich-text col-12 px1_5 md:px2">
    <div className="col-12 mxauto mb4">
      <PrismicRichText render={slice.data.primary.rich_text} />
    </div>
  </div>
);

export default RichText;
