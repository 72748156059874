import { createSelector } from 'reselect';

import { RouteConstants } from 'constants/RouteMap';
import {
  GlobalState,
  Product,
  ShopifyProduct,
  ShopifyProducts,
  PrismicProduct,
  PrismicProducts,
  SubscriptionProducts,
  SubscriptionProduct,
  SubscriptionPrices,
} from 'types';

export default createSelector(
  (state: GlobalState): ShopifyProducts | null => {
    return state.products.shopifyProducts;
  },
  (state: GlobalState): PrismicProducts | null => {
    return state.products.prismicProducts;
  },
  (state: GlobalState): SubscriptionProducts | null => {
    return state.products.subscriptionProducts;
  },
  (state: GlobalState): SubscriptionPrices | null => {
    return state.prices.subscriptionPrices;
  },
  (
    shopifyProducts: ShopifyProducts | null,
    prismicProducts: PrismicProducts | null,
    subscriptionProducts: SubscriptionProducts | null,
    subscriptionPrices: SubscriptionPrices | null
  ): { [key: string]: Product } | null => {
    if (!shopifyProducts || !prismicProducts) return null;

    return Object.values(prismicProducts)
      .map((product: PrismicProduct): Product | null => {
        const shopifyProduct: ShopifyProduct = shopifyProducts[product.handle];
        const subscriptionProduct: SubscriptionProduct | null = subscriptionProducts
          ? subscriptionProducts[product.handle]
          : null;

        if (!shopifyProduct) return null;

        if (subscriptionProduct) {
          if (subscriptionPrices) {
            subscriptionProduct['prices'] =
              subscriptionPrices[subscriptionProduct.id];
          }
        }

        return {
          ...shopifyProduct,
          content: product,
          subscription: subscriptionProduct,
          url: `${RouteConstants.PRODUCTS.path}/${product.handle}`,
        };
      })
      .reduce(
        (products: { [key: string]: Product }, product: Product | null) => {
          if (!product) return products;

          products[product.handle] = product;

          return products;
        },
        {}
      );
  }
);
