import { createSelector } from 'reselect';

import lineItems from 'state/selectors/lineItems';
import { GlobalState, LineItem, MergedLineItem } from 'types';

export default createSelector(
  (state: GlobalState): MergedLineItem[] => lineItems(state),
  (lineItems: MergedLineItem[]): number => {
    return lineItems.reduce((totalItemCount: number, lineItem: LineItem) => {
      return totalItemCount + lineItem.quantity;
    }, 0);
  }
);
