const ColorMap: { [key: string]: string } = {
  WHITE: '#ffffff',
  BLACK: '#272f39',
  CREAM: '#f2f2e8',
  BROWN: '#b78365',
  BLUE: '#5387a9',
  MUSTARD: '#ccb16a',
  YELLOW: '#d0b15e',
  TEAL: '#9eb29c',
  PINK: '#e1b89c',
  AQUA: '#9abcbd',
  GREEN: '#9eb29c',
  GRAY: '#aeaeae',
  GRAY_LIGHTER: '#dedede',

  'TUNISIAN JASMINE': '#fafafa',
  MARIGOLD: '#f0a000',
  ARTICHOKE: '#b4c68e',
};

export default ColorMap;
