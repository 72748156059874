import {
  PrismicProduct,
  VariantSpecificMessage,
  ShopifyProduct,
  ShopifyProductVariant,
} from 'types';

export default (
  prismicProduct: PrismicProduct,
  shopifyProduct: ShopifyProduct
): ShopifyProductVariant[] => {
  let variants: ShopifyProductVariant[] = [];

  const prismicVariants: VariantSpecificMessage[] = prismicProduct.variantSpecificMessages.filter(
    (variant) => variant.quickAdd
  );

  if (prismicVariants.length > 0) {
    prismicVariants.forEach((variantSpecificMessage) => {
      const matchingVariant = shopifyProduct.variants.find((variant) => {
        return variant.title === variantSpecificMessage.variant;
      });

      if (matchingVariant) {
        variants.push(matchingVariant);
      } else {
        variants.push(shopifyProduct.variants[0]);
      }
    });
  } else {
    variants.push(shopifyProduct.variants[0]);
  }

  return variants;
};
