import React, { Component } from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import cx from 'classnames';

import { Img, Button } from 'components';
import { fetchLatestReads, fetchRead } from 'state/actions/readsActions';
import PageSize from 'constants/PageSize';
import { Read, GlobalState } from 'types';

export interface IReads {
  type: 'reads';
  data: {
    title: string;
    description: any;
    buttonText: string;
    internalLink: string;
    buttonLink: string;
    reads: string[] | null;
    category: string;
    useLatest: boolean;
    paginate: boolean;
  };
}

interface DispatchProps {
  actions: {
    fetchLatestReads(pageSize: number, page: number, category: string): void;
    fetchRead(slug: string): void;
  };
}

interface StateProps {
  latest: Read[];
}

interface OwnProps {
  slice: IReads;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  page: number;
  reads: Read[];
}

class Reads extends Component<Props, State> {
  state = {
    page: 1,
    reads: [],
  };

  componentDidMount() {
    const { slice, actions } = this.props;

    if (slice.data.useLatest)
      actions.fetchLatestReads(
        PageSize.CONTENT,
        this.state.page,
        slice.data.category
      );

    if (!slice.data.useLatest && !!slice.data.reads) {
      Promise.all(
        slice.data.reads.map((read: string) => {
          return actions.fetchRead(read);
        })
      ).then((response: any) =>
        this.setState({
          reads: response.map((read: any) => get(read, 'value')),
        })
      );
    }
  }

  handleLoadMore = () => {
    const { actions, slice } = this.props;
    const { page } = this.state;

    this.setState({ page: page + 1 }, () =>
      actions.fetchLatestReads(
        PageSize.CONTENT,
        this.state.page,
        slice.data.category
      )
    );
  };

  render() {
    const { slice, latest } = this.props;
    const { reads } = this.state;

    const listedReads: Read[] = slice.data.useLatest ? latest : reads;

    return (
      <div className="Reads col-12 flex flex-wrap mb3 md:mb6">
        {!!slice.data.title && (
          <div className="Reads__info col-12 md:col-4 container-width mxauto text-center mb3 px2">
            <h2 className="text-large mb1">{slice.data.title}</h2>
            {!!slice.data.description && (
              <div className="mb1">
                <PrismicRichText render={slice.data.description} />
              </div>
            )}
            {slice.data.buttonText &&
              (slice.data.internalLink || slice.data.buttonLink) && (
                <>
                  {slice.data.internalLink ? (
                    <Link className="cta" to={slice.data.internalLink}>
                      {slice.data.buttonText}
                    </Link>
                  ) : (
                    <a
                      className="cta"
                      href={slice.data.buttonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {slice.data.buttonText}
                    </a>
                  )}
                </>
              )}
          </div>
        )}
        <div className="Reads__reads col-12">
          <div className="flex flex-wrap px0 md:px1_5">
            {listedReads.map((read: Read) => {
              const isPortrait: boolean =
                !!read.image.dimensions &&
                read.image.dimensions.height >= read.image.dimensions.width;

              return (
                <div key={read.url} className="col-12 md:col-4 mxauto mb3 px2">
                  <div className={cx({ 'aspect-square mb3': !isPortrait })}>
                    <Link className="no-hover" to={read.url}>
                      <Img
                        className={cx('col-12 mb1_5', {
                          'h100 object-fit-cover': !isPortrait,
                        })}
                        src={read.image.src}
                        alt={read.image.caption || ''}
                      />
                    </Link>
                  </div>
                  <span className="block text-medium mb_5">{read.title}</span>
                  <Link className="cta" to={read.url}>
                    Read More
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        {slice.data.paginate && (
          <div className="Reads__load-more col-12 mt3 text-center">
            <Button
              variant="primary--inverted"
              label="Load More"
              ariaLabel="Load More Reads"
              onClick={this.handleLoadMore}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  latest: state.reads.latest,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({ fetchLatestReads, fetchRead }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reads);
