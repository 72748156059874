import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import { Img } from 'components';
import products from 'state/selectors/products';
import { Product, GlobalState } from 'types';

export interface IRichTextProducts {
  type: 'rich_text___products';
  data: {
    title: string;
    richText: any;
    products: string[];
  };
}

interface StateProps {
  products: { [key: string]: Product } | null;
}

interface Props extends StateProps {
  slice: IRichTextProducts;
}

/* @ts-ignore */
class RichTextProducts extends Component<Props> {
  render() {
    const { slice } = this.props;

    return (
      <div className="RichTextProducts col-12 px1_5 md:px2 px1_5">
        <div className="RichTextProducts__container container-width mxauto flex flex-wrap">
          <div className="col-12 md:col-6 prismic-rich-text mb3">
            <PrismicRichText render={slice.data.richText} />
          </div>
          <div className="col-12 md:col-6 mb3">
            <div className="col-12 text-center mb2">
              <h2 className="text-medium-2">{slice.data.title}</h2>
            </div>
            <div className="col-12 flex flex-wrap">
              {slice.data.products.map((slug: string) => {
                const product: Product | null = get(
                  this.props.products,
                  slug,
                  null
                );

                if (!product) return null;

                return (
                  <div
                    className="RichTextProducts__product col-6 px1 text-center"
                    key={product.id}
                  >
                    <Link
                      className="text-decoration-none no-hover"
                      to={product.url}
                    >
                      <div className="RichTextProducts__product__img col-12 mb2">
                        <Img
                          className="block w100"
                          src={product.variants[0].image.src}
                          alt={product.title}
                        />
                      </div>
                      <span className="viksjoe uppercase color-black underline">
                        {product.title}
                      </span>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  products: products(state)
});

export default connect(mapStateToProps)(RichTextProducts);
