import ContentClient from 'lib/ContentClient';

export const fetchEvents = () => ({
  type: 'FETCH_EVENTS',
  payload: ContentClient.fetchEvents()
});

export const fetchEvent = (slug: string) => ({
  type: 'FETCH_EVENT',
  payload: ContentClient.fetchEvent(slug)
});

export const fetchLatestEvents = (page: number, city: string | null) => ({
  type: 'FETCH_LATEST_EVENTS',
  payload: ContentClient.fetchLatestEvents(page, city)
});
