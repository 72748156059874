import React, { Component } from 'react';

import { Button, TextField } from 'components';

interface Props {
  allowZero: boolean;
  showLabel: boolean;
  quantity: number;
  onChange: (value: number) => void;
}

class Quantity extends Component<Props> {
  handleIncrement = () => this.props.onChange(this.props.quantity + 1);
  handleDecrement = () => {
    if (!this.props.allowZero && !(this.props.quantity - 1)) return;

    this.props.onChange(this.props.quantity - 1);
  };

  handleOnChange = (value: number) => {
    if (value <= 0 && !this.props.allowZero) return;

    this.props.onChange(value);
  };

  render() {
    const { showLabel, quantity } = this.props;

    return (
      <div className="Quantity flex flex-wrap justify-between items-center">
        {showLabel && (
          <div className="Quantity__label">
            <span className="text-label">Quantity</span>
          </div>
        )}
        <div className="Quantity__interface flex flex-wrap">
          <Button
            variant="round"
            ariaLabel="decrement quantity"
            label="–"
            onClick={this.handleDecrement}
          />
          <TextField
            type="number"
            className="mx1"
            name="quantity"
            ariaLabel={`the current quantity is ${quantity}`}
            variant="round"
            value={quantity}
            onChange={value => this.handleOnChange(value as number)}
          />
          <Button
            variant="round"
            ariaLabel="increment quantity"
            label="+"
            onClick={this.handleIncrement}
          />
        </div>
      </div>
    );
  }
}

export default Quantity;
