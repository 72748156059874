import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from 'react-apollo';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import packageJson from './../package.json';
import { client } from 'lib/ApolloClient';
import { history, store, persistor } from 'store';

import App from './App';

Sentry.init({
  dsn:
    'https://4624dcd5ac92419b95c408fc2e688a4d@o474277.ingest.sentry.io/5510371',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

console.log('APP VER: ', packageJson.version);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </PersistGate>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);
