import React from 'react';
import { Link } from 'react-router-dom';

import { Image } from 'types';

interface RecirculationItem {
  eyebrow: string;
  title: string;
  buttonText: string;
  internalLink: string;
  buttonLink: string;
  image: Image;
}

export interface IRecirculation {
  type: '2-up_recirculation';
  data: {
    title: string;
    description: string;
    slides: RecirculationItem[];
  };
}

interface Props {
  slice: IRecirculation;
}

const Recirculation: React.FC<Props> = ({ slice }) => (
  <div className="Recirculation col-12 container-width mxauto mb3 md:mb8 px1">
    <h2 className="text-large text-center mb2">{slice.data.title}</h2>
    <div className="flex flex-wrap md:flex-nowrap justify-center col-12">
      {slice.data.slides.map((slide: RecirculationItem) => (
        <div className="col-12 md:col-6 mb2 md:mb0 px1">
          <div className="col-12 bg-color-brown color-white flex flex-wrap radius-xs overflow-hidden">
            <div
              className="Recirculation__image col-5"
              style={{
                background: `url(${slide.image.src}) no-repeat center`,
                backgroundSize: 'cover'
              }}
            />
            <div className="col-7 flex flex-col p1 md:p1_5">
              <span className="Recirculation__eyebrow mb2 md:mb4 viksjoe uppercase text-small">
                {slide.eyebrow}
              </span>
              <span className="Recirculation__title text-medium-2 mb_5 md:mb1_5">
                {slide.title}
              </span>
              {slide.buttonText && (slide.internalLink || slide.buttonLink) && (
                <div>
                  {slide.internalLink ? (
                    <Link
                      className="Recirculation__link cta white"
                      to={slide.internalLink}
                    >
                      {slide.buttonText}
                    </Link>
                  ) : (
                    <a
                      className="cta white"
                      href={slide.buttonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {slide.buttonText}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Recirculation;
