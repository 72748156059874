import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const LeftArrowIcon: React.FC<Props> = ({
  className = '',
  fill = '#F2F2E8'
}) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0566 2.57622H14.3096V1.30488C14.3096 0.865854 13.9529 0.5 13.5048 0.5C13.0657 0.5 12.6999 0.856707 12.6999 1.30488V2.57622H6.43464V1.30488C6.43464 0.865854 6.07793 0.5 5.62976 0.5C5.19074 0.5 4.82489 0.856707 4.82489 1.30488V2.57622H3.07793C1.66025 2.57622 0.507812 3.72866 0.507812 5.14634V15.939C0.507812 17.3567 1.66025 18.5 3.07793 18.5H16.0383C17.456 18.5 18.6084 17.3567 18.6084 15.939V5.14634C18.6267 3.72866 17.4743 2.57622 16.0566 2.57622ZM2.35537 5.14634C2.35537 4.73476 2.69379 4.40549 3.09623 4.40549H4.84318V4.65244C4.33098 4.92683 3.98342 5.46646 3.98342 6.09756C3.98342 7.01219 4.72428 7.7439 5.63891 7.7439C6.55354 7.7439 7.2944 7.00305 7.2944 6.09756C7.2944 5.46646 6.94684 4.91768 6.43464 4.65244V4.39634H12.6999V4.64329C12.1968 4.91768 11.8493 5.45732 11.8493 6.08841C11.8493 7.00305 12.5901 7.73476 13.4956 7.73476C14.4103 7.73476 15.1511 6.9939 15.1511 6.08841C15.1511 5.45732 14.8035 4.90854 14.2913 4.64329V4.39634H16.0383C16.459 4.39634 16.7883 4.73476 16.7883 5.1372V8.48476H2.35537V5.14634ZM16.8157 15.939C16.8157 16.3506 16.4773 16.6799 16.0657 16.6799H3.09623C2.68464 16.6799 2.35537 16.3415 2.35537 15.939V10.0945H16.8157V15.939Z"
        fill={fill}
      />
    </svg>
  );
};

export default LeftArrowIcon;
