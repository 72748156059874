import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import cx from 'classnames';

import { StarBurst, Img } from 'components';
import { RouteConstants } from 'constants/RouteMap';

import products from 'state/selectors/products';
import { GlobalState, Product } from 'types';

export interface IShopBlock {
  type: 'shop_block';
  data: {
    products: string[];
  };
}

interface StateProps {
  products: { [key: string]: Product } | null;
}

interface Props extends StateProps {
  slice: IShopBlock;
}

class ShopBlock extends Component<Props> {
  private flickity: any = null;

  handleNext = (): void => {
    if (this.flickity && this.flickity.next) this.flickity.next();
  };

  handlePrevious = (): void => {
    if (this.flickity && this.flickity.previous) this.flickity.previous();
  };

  render() {
    const { slice } = this.props;

    return (
      <div className="ShopBlock col-12 relative mb2 md:mb4 px1_5">
        <div className="ShopBlock__container mxauto text-container-width overflow-hidden">
          <div className="ShopBlock__marquee bg-color-gold">
            <Link className="block py1" to={RouteConstants.PRODUCTS.path}>
              <div className="ShopBlock__marquee__carousel flex width-auto items-center">
                <div className="mr2">
                  <StarBurst />
                </div>
                <span className="text-xl color-cream transition uppercase viksjoe mr2">
                  Shop
                </span>
                <div className="mr2">
                  <StarBurst />
                </div>
                <span className="text-xl color-cream transition uppercase viksjoe mr2">
                  Shop
                </span>
                <div className="mr2">
                  <StarBurst />
                </div>
                <span className="text-xl color-cream transition uppercase viksjoe mr2">
                  Shop
                </span>
                <div className="mr2">
                  <StarBurst />
                </div>
                <span className="text-xl color-cream transition uppercase viksjoe mr2">
                  Shop
                </span>
                <div className="mr2">
                  <StarBurst />
                </div>
                <span className="text-xl color-cream transition uppercase viksjoe mr2">
                  Shop
                </span>
              </div>
            </Link>
          </div>
          <div className="ShopBlock__products flex flex-wrap">
            {slice.data.products.map((slug: string, i: number) => {
              const product: Product | null = get(
                this.props.products,
                slug,
                null
              );

              if (!product) return null;

              return (
                <div
                  className={cx(
                    'ShopBlocks__products__product col-12 md:col-6 flex',
                    { 'flex-row-reverse md:flex-row': i % 2 }
                  )}
                  key={slug}
                >
                  <div className="col-6">
                    <Img
                      className="block w100"
                      src={product.variants[0].image.src}
                      alt={product.title}
                    />
                  </div>
                  <div className="col-6 flex flex-col justify-end items-start p1_5">
                    <span className="text-medium mb2">{product.title}</span>
                    <Link className="cta" to={product.url}>
                      Shop Now
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  products: products(state)
});

export default connect(mapStateToProps)(ShopBlock);
