import React from 'react';
import { Link } from 'react-router-dom';

import { Img } from 'components';
import { Image } from 'types';

interface PressLink {
  title: string;
  publication: string;
  url: string;
  logo: Image;
  text: string;
  internalLink: string;
  linkText: string;
}

export interface IPressLogos {
  type: 'press_logos';
  data: {
    links: PressLink[];
  };
}

interface Props {
  slice: IPressLogos;
}

const PressLogos: React.FC<Props> = ({ slice }) => (
  <div className="PressLogos flex flex-wrap items-start container-width mxauto px1_5 md:px0 justify-between mb3 md:mb8">
    {slice.data.links.map((link: PressLink) => (
      <div
        key={`${link.url}${link.linkText}`}
        className="px1 md:px0 mb1 md:mb0 col-6 md:col-2 text-center"
      >
        <a
          className="no-style"
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="PressLogos__image flex flex-wrap items-end mb1">
            <Img
              className="col-12"
              src={link.logo.src}
              alt={link.logo.caption || ''}
            />
          </div>
          <p className="text-label mb1">{link.title}</p>
          <p className="color-black text-small">{link.text}</p>
          {link.linkText && (link.internalLink || link.url) && (
            <div className="mt1 pb_25">
              {link.internalLink ? (
                <Link className="cta" to={link.internalLink}>
                  {link.linkText}
                </Link>
              ) : (
                <a
                  className="cta"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.linkText}
                </a>
              )}
            </div>
          )}
        </a>
      </div>
    ))}
  </div>
);

export default PressLogos;
