import React, { Component, ReactText } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import cx from 'classnames';

import { RouteConstants } from 'constants/RouteMap';
import {
  Subnav,
  TextField,
  ProductListItem,
  ProductLineItem,
  Button,
  Upsell,
} from 'components';
import {
  Product,
  GlobalState,
  MergedLineItem,
  LineItemToUpdate,
  Offer,
} from 'types';

import {
  updateCartNote,
  updateLineItemsWithFreeGifts,
} from 'state/actions/checkoutActions';
import quickAddProducts from 'state/selectors/quickAddProducts';
import products from 'state/selectors/products';
import lineItems from 'state/selectors/lineItems';
import { CheckoutReducer } from 'state/reducers/checkout';
import getUpdatedLineItems from 'utils/getUpdatedLineItems';

interface OwnProps {
  isActive: boolean;
}

interface DispatchProps {
  actions: {
    updateCartNote: (checkoutId: string, note: string) => void;
    updateLineItemsWithFreeGifts: (
      checkoutId: string,
      lineItems: LineItemToUpdate[]
    ) => void;
  };
}

interface StateProps {
  quickAddProducts: { [key: string]: Product } | null;
  products: { [key: string]: Product } | null;
  lineItems: MergedLineItem[];
  checkout: CheckoutReducer;
  upsell: Offer | null;
}

type State = {
  giftMessageIsOpen: boolean;
  note: string;
};

type Props = OwnProps & StateProps & DispatchProps;

class CartSubnav extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      giftMessageIsOpen: false,
      note: props.checkout.checkout.note,
    };
  }

  addProduct = (product: Product, variantId: string | null = null) => {
    const { checkout, actions } = this.props;
    const activeVariant = variantId
      ? product.variants.find((variant) => variant.id === variantId)?.id
      : product.variants[0].id;

    if (activeVariant) {
      const updatedLineItems = getUpdatedLineItems(
        checkout.checkout.lineItems,
        {
          variantId: activeVariant,
          quantity: 1,
        }
      );

      actions.updateLineItemsWithFreeGifts(
        checkout.checkout.id,
        updatedLineItems
      );
    }
  };

  render() {
    const {
      isActive,
      quickAddProducts,
      products,
      lineItems,
      checkout,
      upsell,
      actions,
    } = this.props;
    const { note, giftMessageIsOpen } = this.state;

    const onChange = (value: ReactText) => {
      this.setState({ note: value as string });
      actions.updateCartNote(checkout.checkout.id, value as string);
    };

    return (
      <Subnav isActive={isActive}>
        {!!lineItems.length ? (
          <div className="CartSubnav CartSubnav--cart pb1 relative">
            {!!upsell && upsell.isActive && !!quickAddProducts && (
              <div className="CartSubnav__upsell">
                <Upsell
                  offer={upsell}
                  checkout={checkout.checkout}
                  products={quickAddProducts}
                  onClick={this.addProduct}
                />
              </div>
            )}
            <div className="CartSubnav__line-items px1">
              {lineItems.map((lineItem: MergedLineItem) => {
                const isTheFinds =
                  products &&
                  products[lineItem.productHandle].tags.includes('The Finds');
                const showQuantity = !isTheFinds && !lineItem.isFreeGift;

                return (
                  <ProductLineItem
                    key={lineItem.variantId}
                    lineItem={lineItem}
                    showQuantity={showQuantity}
                    isFreeGift={lineItem.isFreeGift}
                  />
                );
              })}
            </div>
            <div className="px1">
              <div
                className={cx('CartSubnav__gift-message pt1 flex flex-wrap', {
                  'CartSubnav__gift-message--active': giftMessageIsOpen,
                })}
              >
                <Button
                  variant="no-style"
                  className="pointer text-decoration-none no-hover"
                  ariaLabel="Toggle Gift Message Input"
                  onClick={() =>
                    this.setState({ giftMessageIsOpen: !giftMessageIsOpen })
                  }
                >
                  <span className="col-12 block text-label mb1">
                    Gift Message {giftMessageIsOpen ? '–' : '+'}
                  </span>
                </Button>
                <TextField
                  className="CartSubnav__gift-message__input col-12 flex items-start"
                  ariaLabel="Gift Note"
                  name="note"
                  type="textarea"
                  value={note || ''}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="px1">
              <div className="CartSubnav__totals py1 flex flex-wrap justify-between">
                <span className="text-label">Subtotal</span>
                <span className="text-label">
                  ${checkout.checkout.subtotalPrice.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="CartSubnav__controls col-12 flex flex-wrap pt1 px1">
              <div className="none md:block col-6 pr2">
                <Button
                  variant="primary--inverted"
                  containerClassName="col-12"
                  className="col-12"
                  to={RouteConstants.PRODUCTS.path}
                  label="View Products"
                  ariaLabel="View Products"
                />
              </div>
              <div className="col-12 md:col-6">
                <Button
                  containerClassName="CartSubnav__checkout flex flex-wrap items-center col-12"
                  className="col-12"
                  to={checkout.checkout.webUrl}
                  label="Checkout Now"
                  ariaLabel="Checkout"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="CartSubnav CartSubnav--products px1 pb1">
            <div className="CartSubnav__items">
              {quickAddProducts &&
                Object.values(quickAddProducts).map((product: Product) => (
                  <ProductListItem
                    key={product.id}
                    product={product}
                    addProduct={this.addProduct}
                  />
                ))}
            </div>
            <div className="CartSubnav__controls pt1">
              <Button
                containerClassName="col-12"
                className="col-12"
                ariaLabel="View All Products"
                variant="primary"
                label="View All Products"
                to={RouteConstants.PRODUCTS.path}
              />
            </div>
          </div>
        )}
      </Subnav>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  quickAddProducts: quickAddProducts(state),
  products: products(state),
  lineItems: lineItems(state),
  checkout: state.checkout,
  upsell: state.settings.upsell,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    { updateCartNote, updateLineItemsWithFreeGifts },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartSubnav);
