import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import Colors from 'constants/Colors';

import { Button, Img } from 'components';
import { Image } from 'types';

interface Props {
  productHandle: string;
  oneTimePrice: string;
  subscriptionPrice: string;
  subscriptionDescription: any;
  onChange: (option: string) => void;
  subscriptionIsSelected: boolean;
  subscriptionSticker: Image;
}

const PriceOptions: React.FC<Props> = (props) => {
  const {
    productHandle,
    oneTimePrice,
    subscriptionPrice,
    onChange,
    subscriptionIsSelected,
    subscriptionDescription,
    subscriptionSticker,
  } = props;

  return (
    <div className="PriceOptions col-12 mb1_5">
      <div className="PriceOptions__option--container flex items-center">
        <Button
          onClick={() => onChange('one-time')}
          className="PriceOptions__option"
          ariaLabel="Select one time price"
          variant="no-style"
        >
          <div
            className="PriceOptions__option__inner"
            style={{
              background: !subscriptionIsSelected
                ? Colors['BLACK']
                : Colors['CREAM'],
            }}
          />
        </Button>
        <span className="text-medium ginto">
          <span role="button" aria-label="One time purchase">
            One Time Purchase
          </span>
        </span>
      </div>
      <div className="PriceOptions__option--container flex items-center">
        <Button
          onClick={() => onChange('subscription')}
          className="PriceOptions__option"
          ariaLabel="Select subscription price"
          variant="no-style"
        >
          <div
            className="PriceOptions__option__inner"
            style={{
              background: subscriptionIsSelected
                ? Colors['BLACK']
                : Colors['CREAM'],
            }}
          />
        </Button>
        <span className="text-medium ginto">
          <span role="button" aria-label="Subscribe and save">
            Subscribe & Save
          </span>
        </span>
      </div>
      <div className="text-medium ginto bold mt1">
        {subscriptionIsSelected ? (
          <>
            <span className="PriceOptions__option--strikethrough-price pr_5">
              {productHandle === 'the-olive-oil' ? `$112.00` : `$65`}
            </span>
            ${subscriptionPrice}, every 3 months
          </>
        ) : (
          `$${oneTimePrice}`
        )}
      </div>
      {subscriptionIsSelected && (
        <Img
          className="PriceOptions__subscription-image"
          src={subscriptionSticker.src}
          alt={subscriptionSticker.caption || ''}
        />
      )}
      {subscriptionIsSelected && (
        <div className="PriceOptions__subscription-description bell text-medium mt1_5 mb2">
          <PrismicRichText render={subscriptionDescription} />
        </div>
      )}
    </div>
  );
};

export default PriceOptions;
