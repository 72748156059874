import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

import ContainerBase from 'lib/ContainerBase';

import { fetchSubscriptionCheckoutSession } from 'state/actions/subscriptionActions';

import OrderSuccessView from 'views/OrderSuccessView';

import { GlobalState, SubscriptionCheckoutSession } from 'types';
import getSubscriptionProductImages from 'state/selectors/getSubscriptionProductImages';

interface StoreProps {
  subscriptionCheckoutSession: SubscriptionCheckoutSession | null;
}

interface DispatchProps {
  actions: {
    fetchSubscriptionCheckoutSession: (id: string) => void;
  };
}

type Props = RouteComponentProps & StoreProps & DispatchProps;

class OrderSuccessContainer extends ContainerBase<
  Props,
  typeof OrderSuccessView
> {
  view = import('views/OrderSuccessView');

  beforeModel = () => {
    const id = get(this, 'props.match.params.id', '');

    return this.props.actions.fetchSubscriptionCheckoutSession(id);
  };
}

const mapStateToProps = (state: GlobalState) => {
  return {
    subscriptionProductImages: getSubscriptionProductImages(state),
    subscriptionCheckoutSession: state.subscription
      ? state.subscription.checkoutSession
      : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      fetchSubscriptionCheckoutSession,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSuccessContainer);
