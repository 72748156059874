import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Button, Img } from 'components';
import { AlertBanner as IAlertBanner } from 'types';

interface Props {
  alertBanner: IAlertBanner | null;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}

const AlertBanner: React.FC<Props> = ({
  alertBanner,
  isActive,
  setIsActive,
}) => {
  useEffect(() => {
    if (alertBanner && alertBanner.isActive !== 'No') {
      setTimeout(() => setIsActive(true), 3000);
    }
  }, [alertBanner, setIsActive]);

  return (
    <div
      className={cx('AlertBanner col-12 bg-color-yellow flex items-center', {
        'AlertBanner--active':
          isActive && !!alertBanner && alertBanner.isActive,
      })}
    >
      {alertBanner && alertBanner.isActive && (
        <div className="AlertBanner__inner col-12 container-width mxauto px1_5 md:px2 flex flex-wrap items-center justify-center relative">
          {(alertBanner.internalLink || alertBanner.externalLink) && (
            <>
              {alertBanner.internalLink ? (
                <Link className="no-style" to={alertBanner.internalLink}>
                  <span className="AlertBanner__text color-white">
                    {alertBanner.text}
                  </span>
                </Link>
              ) : (
                <a
                  className="no-style"
                  href={alertBanner.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="AlertBanner__text color-white">
                    {alertBanner.text}
                  </span>
                </a>
              )}
            </>
          )}

          <Button
            variant="no-style"
            className="AlertBanner__close p0 absolute r0"
            onClick={() => setIsActive(false)}
            ariaLabel="Close this alert"
          >
            <Img src="/assets/images/close-icon-white.svg" alt="close icon" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default AlertBanner;
