import React, { Component, MouseEvent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { RouterState } from 'connected-react-router';

import Colors from 'constants/Colors';
import { RouteConstants } from 'constants/RouteMap';
import { Breakpoints } from 'lib/withBreakpoints';
import totalLineItemsCount from 'state/selectors/totalLineItemsCount';

import {
  Img,
  Button,
  BagIcon,
  MenuIcon,
  CartSubnav,
  EventsSubnav,
  ReadsSubnav,
  StarNumber,
  BagNumber,
  AlertBanner,
} from 'components';
import { Color, GlobalState } from 'types';
import { SettingsReducer } from 'state/reducers/settings';

interface StateProps {
  settings: SettingsReducer;
  router: RouterState;
  totalLineItemsCount: number;
}
interface Props extends StateProps {}

type ActiveSubnav = 'shop' | 'events' | 'reads' | 'cart' | null;
interface State {
  activeSubnav: ActiveSubnav;
  lineItemCountIsChanging: boolean;
  menuIsOpen: boolean;
  cartIsOpen: boolean;
  scrolled: boolean;
  alertBannerIsActive: boolean;
}

class Nav extends Component<Props, State> {
  state = {
    activeSubnav: null,
    lineItemCountIsChanging: false,
    menuIsOpen: false,
    cartIsOpen: false,
    scrolled: false,
    alertBannerIsActive: false,
  };

  handleScroll = (event: Event): void => {
    if (window.scrollY > 80) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  scrollListenerOptions = {
    capture: false,
    passive: true,
  };

  componentDidMount() {
    window.addEventListener(
      'scroll',
      this.handleScroll,
      this.scrollListenerOptions
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      'scroll',
      this.handleScroll,
      this.scrollListenerOptions
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.totalLineItemsCount !== this.props.totalLineItemsCount) {
      this.setState({ lineItemCountIsChanging: true });
      setTimeout(() => this.setState({ lineItemCountIsChanging: false }), 250);

      if (this.props.totalLineItemsCount < 1) {
        this.closeCart();
      }
    }

    if (
      prevProps.router.location.pathname !== this.props.router.location.pathname
    ) {
      this.setState({ menuIsOpen: false, cartIsOpen: false });
    }
  }

  handleHover = (subnav: ActiveSubnav): void => {
    this.setState({ activeSubnav: subnav });
  };

  handleClick = (
    event: MouseEvent<HTMLElement>,
    subnav: ActiveSubnav
  ): void => {
    if (window.innerWidth >= Breakpoints.MEDIUM.lowerBound) return;
    event.preventDefault();

    this.setState({ activeSubnav: subnav });
  };

  openMenu = () => this.setState({ menuIsOpen: true, cartIsOpen: false });
  closeMenu = () => this.setState({ menuIsOpen: false });

  openCart = () => this.setState({ cartIsOpen: true, menuIsOpen: false });
  closeCart = () => this.setState({ cartIsOpen: false });

  shrinkDesktopNav = () => {
    const { scrolled, alertBannerIsActive } = this.state;

    if (scrolled) return true;
    if (alertBannerIsActive) return true;

    return false;
  };

  setAlertBannerIsActive = (isActive: boolean) => {
    this.setState({ alertBannerIsActive: isActive });
  };

  handleCartOpen = () => {
    if (this.props.totalLineItemsCount > 0) {
      if (this.state.cartIsOpen) {
        this.closeCart();
      } else {
        this.openCart();
      }
    }
  };

  render() {
    const { settings, totalLineItemsCount } = this.props;
    const {
      activeSubnav,
      lineItemCountIsChanging,
      menuIsOpen,
      cartIsOpen,
      scrolled,
      alertBannerIsActive,
    } = this.state;

    return (
      <nav
        className={cx('Nav fixed t0 l0 col-12 z-nav', {
          'Nav--scrolled': scrolled,
        })}
      >
        <AlertBanner
          alertBanner={settings.alertBanner}
          setIsActive={this.setAlertBannerIsActive}
          isActive={alertBannerIsActive}
        />
        <div
          className={cx(
            'Nav container-width flex flex-wrap md:justify-between justify-center items-center mxauto pt1_5 px1_5 pb1 md:px2',
            {
              'md:pb3': !scrolled,
              'md:pt1_5 md:pb1_5': scrolled,
              'md:pt3': !this.shrinkDesktopNav(),
            }
          )}
        >
          <div className="Nav__mobile-cart md:none absolute l0 ml1_5">
            <div className="Nav__mobile-mini-cart col-12">
              <CartSubnav isActive={cartIsOpen} />
            </div>
            <Button
              className={cx('Nav__cart-button flex', {
                'Nav__cart-button--changing': lineItemCountIsChanging,
              })}
              variant="no-style"
              ariaLabel="open mini cart"
              onClick={cartIsOpen ? this.closeCart : this.openCart}
            >
              <BagNumber
                color={Colors.MUSTARD}
                textColor="white"
                quantity={totalLineItemsCount}
              />
            </Button>
          </div>
          <div className="Nav__logo">
            <Link className="no-hover" to="/">
              <Img
                alt="Pineapple Collaborative Logo"
                src="/assets/images/pineapple-collaborative-logo-black.svg"
              />
            </Link>
          </div>
          <div className="Nav__mobile-menu-opener md:none absolute r0 mr1_5">
            <Button
              variant="no-style"
              onClick={menuIsOpen ? this.closeMenu : this.openMenu}
              ariaLabel="Toggle menu"
            >
              <MenuIcon fill={Colors.MUSTARD} />
            </Button>
          </div>
          <div
            className={cx(
              'Nav__mobile-menu fixed col-12 bg-color-teal md:none z-2',
              {
                'Nav__mobile-menu--active': menuIsOpen,
              }
            )}
          >
            <div className="Nav__mobile-close">
              <Button
                variant="no-style"
                ariaLabel="close menu"
                onClick={this.closeMenu}
              >
                <Img
                  src="/assets/images/close-icon-white.svg"
                  alt="Close mobile menu"
                />
              </Button>
            </div>
            <ul className="flex flex-col items-start px1_5 py2 list-style-none">
              <li className="Nav__item-mobile mb1">
                <Button
                  className="col-12 teal"
                  variant="secondary--inverted"
                  ariaLabel="View All Products"
                  label="Shop Products"
                  to={RouteConstants.PRODUCTS.path}
                />
              </li>
              <li className="Nav__item-mobile mb1">
                <Button
                  className="col-12"
                  variant="secondary--inverted"
                  ariaLabel="View All Reads"
                  label="Stories"
                  to={RouteConstants.READS.path}
                />
              </li>
              <li className="Nav__item-mobile mb1">
                <Button
                  className="col-12"
                  variant="secondary--inverted"
                  label="Events"
                  ariaLabel="View All Events"
                  to={RouteConstants.EVENTS.path}
                />
              </li>
              <li className="Nav__item-mobile mb1">
                <Link className="cta white" to={RouteConstants.ABOUT.path}>
                  About
                </Link>
              </li>
              <li className="Nav__item-mobile">
                <Link className="cta white" to={RouteConstants.SIGN_UP.path}>
                  Sign Up
                </Link>
              </li>
            </ul>
          </div>
          <div
            className="Nav__menu relative none md:block"
            onMouseLeave={() => this.handleHover(null)}
          >
            <ul className="flex items-center">
              <li className="Nav__item mr1_5 inline">
                <Button
                  label="About"
                  variant="secondary"
                  ariaLabel="Go to About"
                  to={RouteConstants.ABOUT.path}
                />
              </li>
              <li className="Nav__item mr1_5 inline">
                <Button
                  label="Videos"
                  variant="secondary"
                  ariaLabel="View Videos"
                  to={RouteConstants.VIDEOS.path}
                />
              </li>
              <li className="Nav__item Nav__item--has-children mr1_5 inline">
                <Button
                  containerClassName="relative z-2"
                  className="flex items-center"
                  variant={
                    activeSubnav === 'events'
                      ? 'secondary--inverted'
                      : 'secondary'
                  }
                  ariaLabel="View All Events"
                  onMouseEnter={() => this.handleHover('events')}
                  onClick={(e: MouseEvent<HTMLElement>) =>
                    this.handleClick(e, 'events')
                  }
                  to={RouteConstants.EVENTS.path}
                >
                  <span>Events</span>
                </Button>
                <EventsSubnav isActive={activeSubnav === 'events'} />
              </li>
              <li className="Nav__item Nav__item--has-children mr1_5 inline">
                <Button
                  containerClassName="relative z-2"
                  className="flex items-center"
                  variant={
                    activeSubnav === 'reads'
                      ? 'secondary--inverted'
                      : 'secondary'
                  }
                  ariaLabel="View All Reads"
                  onMouseEnter={() => this.handleHover('reads')}
                  onClick={(e: MouseEvent<HTMLElement>) =>
                    this.handleClick(e, 'reads')
                  }
                  to={RouteConstants.READS.path}
                >
                  <span>Stories</span>
                </Button>
                <ReadsSubnav isActive={activeSubnav === 'reads'} />
              </li>
              <li className="Nav__item mr1_5 inline">
                <Button
                  label="Shop"
                  variant="secondary"
                  ariaLabel="Shop"
                  to={RouteConstants.PRODUCTS.path}
                />
              </li>
              <li className="Nav__item Nav__item--has-children inline">
                <Button
                  containerClassName="relative z-2"
                  className="flex items-center"
                  variant={
                    cartIsOpen || activeSubnav === 'cart'
                      ? 'secondary--inverted'
                      : 'secondary'
                  }
                  ariaLabel="View All Products"
                  onMouseEnter={() => this.handleHover('cart')}
                  onClick={(e: MouseEvent<HTMLElement>) => {
                    this.handleClick(e, 'cart');
                    this.handleCartOpen();
                  }}
                  to={RouteConstants.PRODUCTS.path}
                >
                  <BagIcon
                    className="mr_5"
                    fill={
                      cartIsOpen || activeSubnav === 'cart'
                        ? Color.BLACK
                        : Color.CREAM
                    }
                  />
                  <span>Cart</span>
                  {!!totalLineItemsCount && (
                    <div
                      className={cx('Nav__item-indicator absolute r0 t0', {
                        'Nav__item-indicator--changing': lineItemCountIsChanging,
                      })}
                    >
                      <StarNumber
                        color={Colors.YELLOW}
                        textColor="white"
                        quantity={totalLineItemsCount}
                      />
                    </div>
                  )}
                </Button>
                <CartSubnav isActive={cartIsOpen || activeSubnav === 'cart'} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  settings: state.settings,
  totalLineItemsCount: totalLineItemsCount(state),
  router: state.router,
});

export default connect(mapStateToProps)(Nav);
