import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

import ContainerBase from 'lib/ContainerBase';

import BlankView from 'views/BlankView';

type Props = RouteComponentProps;

class BlogRedirectContainer extends ContainerBase<
  Props,
  typeof BlankView,
  void
> {
  view = import('views/BlankView');
  beforeModel = () => {
    const slug = get(this, 'props.match.params.slug', '');
    this.props.history.push(
      `/reads/the-pantry/${slug.substring(1, slug.length - 1)}`
    );
  };
}

export default connect()(BlogRedirectContainer);
