import { createSelector } from 'reselect';
import getQuickAddProductVariants from 'utils/getQuickAddProductVariants';

import { RouteConstants } from 'constants/RouteMap';
import {
  GlobalState,
  Product,
  ShopifyProduct,
  ShopifyProducts,
  PrismicProduct,
  PrismicProducts,
} from 'types';

export default createSelector(
  (state: GlobalState): ShopifyProducts | null => {
    return state.products.shopifyProducts;
  },
  (state: GlobalState): PrismicProducts | null => {
    return state.products.prismicProducts;
  },
  (
    shopifyProducts: ShopifyProducts | null,
    prismicProducts: PrismicProducts | null
  ): { [key: string]: Product } | null => {
    if (!shopifyProducts || !prismicProducts) return null;

    return Object.values(prismicProducts)
      .map((product: PrismicProduct): Product | null => {
        const shopifyProduct: ShopifyProduct = shopifyProducts[product.handle];

        if (!shopifyProduct) return null;
        if (!product.quickAdd) return null;

        return {
          ...shopifyProduct,
          content: product,
          subscription: null, // TODO?
          url: `${RouteConstants.PRODUCTS.path}/${product.handle}`,
          variants: getQuickAddProductVariants(product, shopifyProduct),
        };
      })
      .reduce(
        (products: { [key: string]: Product }, product: Product | null) => {
          if (!product) return products;

          products[product.handle] = product;

          return products;
        },
        {}
      );
  }
);
