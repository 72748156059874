import React, { useState, useEffect } from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import EmailValidator from 'email-validator';
import cx from 'classnames';

// @ts-ignore
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import { RouteConstants } from 'constants/RouteMap';

import { Button, TextField } from 'components';

export interface IWholesaleLogin {
  type: 'wholesale_login';
  data: {
    title: string;
    description: string;
  };
}

interface Props {
  slice: IWholesaleLogin;
}

const WholesaleLogin: React.FC<Props> = ({ slice }) => {
  const {
    user,
    login,
    logout,
    urlToken,
    completeUrlTokenTwoStep,
    sendPasswordRecovery,
  } = useIdentityContext();

  const [currentUser, setCurrentUser] = useState(user);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [tokenType, setTokenType] = useState('');

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  useEffect(() => {
    if (urlToken) {
      setTokenType(urlToken.type);
    } else {
      setTokenType('');
    }
  }, [urlToken]);

  const handleLogin = () => {
    setMessage('');
    login({ email, password }).catch(() =>
      setMessage('Incorrect username or password.')
    );
  };

  const handleLogout = () => {
    logout();
    setCurrentUser(undefined);
    setTokenType('');
    setMessage('');
    setEmail('');
    setPassword('');
  };

  const handleSignup = () => {
    completeUrlTokenTwoStep({ password });
    setMessage('');
  };

  const handleForgotPassword = () => {
    const validEmail = EmailValidator.validate(email);

    if (validEmail) {
      sendPasswordRecovery({ email }).then(
        setMessage(`A password recovery link has been sent to ${email}.`)
      );
    } else {
      setMessage(`Please enter a valid email.`);
    }
  };

  return (
    <div className="SignUpForm col-12 flex flex-wrap container-width mxauto px1_5 md:px2 justify-center pt2 mb3 md:mb8 text-center">
      <div className="col-12 md:col-6 mxauto">
        <h2 className="SignUpForm__title mb1">{slice.data.title}</h2>
        {!!slice.data.description && !currentUser && !tokenType && (
          <div className="SignUpForm__description text-medium bell mb1">
            <PrismicRichText render={slice.data.description} />
          </div>
        )}
        <div className="flex flex-col items-center">
          {!currentUser && tokenType === 'invite' ? (
            <>
              <TextField
                className="col-12 mb1"
                name="password"
                ariaLabel="Enter password"
                label="Password"
                variant="primary"
                placeholder="password"
                type="password"
                onChange={(value) => setPassword(value as string)}
                value={password}
              />
              <Button
                className="col-12"
                onClick={handleSignup}
                variant="primary"
                ariaLabel="Signup"
                label="Complete signing up"
              />
            </>
          ) : currentUser && tokenType === 'passwordRecovery' ? (
            <>
              <TextField
                className="col-12 mb1"
                name="password"
                ariaLabel="Enter password"
                label="Password"
                variant="primary"
                placeholder="password"
                type="password"
                onChange={(value) => setPassword(value as string)}
                value={password}
              />
              <Button
                className="col-12"
                onClick={handleSignup}
                variant="primary"
                ariaLabel="Reset password"
                label="Reset password"
              />
            </>
          ) : currentUser ? (
            <>
              <Button
                containerClassName="w100"
                className="col-12 mb1"
                to={RouteConstants.WHOLESALE_PRODUCTS.path}
                variant="primary"
                ariaLabel="Shop wholesale products"
                label="Shop Wholesale Products"
              />
              <Button
                className="col-12"
                onClick={handleLogout}
                variant="primary"
                ariaLabel="Logout"
                label="Logout"
              />
            </>
          ) : (
            <>
              <TextField
                className="col-12 mb1"
                name="email"
                ariaLabel="Enter email"
                label="Email"
                variant="primary"
                placeholder="hello@example.com"
                type="email"
                onChange={(value) => setEmail(value as string)}
                value={email}
              />
              <TextField
                className="col-12 mb1"
                name="password"
                ariaLabel="Enter password"
                label="Password"
                variant="primary"
                placeholder="password"
                type="password"
                onChange={(value) => setPassword(value as string)}
                value={password}
              />
              <Button
                className="col-12"
                onClick={handleLogin}
                variant="primary"
                ariaLabel="Login"
                label="Login to access wholesale ordering"
              />
              <Button
                className="WholesaleLogin__forgot-password mt_5"
                onClick={handleForgotPassword}
                variant="no-style"
                ariaLabel="Forgot password"
                label="Forgot password"
              />
            </>
          )}
          <div
            className={cx('WholesaleLogin__message bell mt1', {
              'opacity-0': !message,
            })}
          >
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WholesaleLogin;
