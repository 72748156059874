export const fetchCheckout = `
  query fetchCheckout($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        id
        completedAt
        currencyCode
        note
        subtotalPrice {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        webUrl
        lineItems(first: 250) {
          edges {
            node {
              customAttributes {
                key
                value
              }
              id
              quantity
              title
              variant {
                id
                compareAtPrice {
                  amount
                  currencyCode
                }
                availableForSale
                title
                price {
                  amount
                  currencyCode
                }
                image {
                  id
                  altText
                  originalSrc
                }
                product {
                  id
                  handle
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const createCheckout = `
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      userErrors {
        field
        message
      }
      checkout {
        id
        completedAt
        currencyCode
        note
        subtotalPrice {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        webUrl
        lineItems(first: 250) {
          edges {
            node {
              customAttributes {
                key
                value
              }
              id
              quantity
              title
              variant {
                id
                compareAtPrice {
                  amount
                  currencyCode
                }
                availableForSale
                title
                price {
                  amount
                  currencyCode
                }
                image {
                  id
                  altText
                  originalSrc
                }
                product {
                  id
                  handle
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const updateCheckout = `
  mutation checkoutLineItemsReplace(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      userErrors {
        field
        message
      }
      checkout {
        id
        completedAt
        currencyCode
        note
        subtotalPrice {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        webUrl
        lineItems(first: 250) {
          edges {
            node {
              customAttributes {
                key
                value
              }
              id
              quantity
              title
              variant {
                id
                compareAtPrice {
                  amount
                  currencyCode
                }
                availableForSale
                title
                price {
                  amount
                  currencyCode
                }
                image {
                  id
                  altText
                  originalSrc
                }
                product {
                  id
                  handle
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const updateCartNote = `
  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      userErrors {
        field
        message
      }
      checkout {
        id
        completedAt
        currencyCode
        note
        subtotalPrice {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        webUrl
        lineItems(first: 250) {
          edges {
            node {
              customAttributes {
                key
                value
              }
              id
              quantity
              title
              variant {
                id
                compareAtPrice {
                  amount
                  currencyCode
                }
                availableForSale
                title
                price {
                  amount
                  currencyCode
                }
                image {
                  id
                  altText
                  originalSrc
                }
                product {
                  id
                  handle
                }
              }
            }
          }
        }
      }
    }
  }
`;
