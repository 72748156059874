import React from 'react';

import Colors from 'constants/Colors';
import { Img } from 'components';

interface Props {
  text: string;
  fill: string;
}

const StarSticker: React.FC<Props> = ({ text, fill }) => (
  <div className="StarSticker">
    <div className="StarSticker__container relative flex items-center text-center justify-center">
      <Img
        className="StarSticker__icon absolute col-12 t0 l0 z-0"
        src={
          fill === Colors.YELLOW
            ? '/assets/images/starburst-yellow.svg'
            : '/assets/images/starburst-blue.svg'
        }
        alt={`${text} icon`}
      />
      <span className="StarSticker__text color-white viksjoe text-small uppercase relative z-1">
        {text}
      </span>
    </div>
  </div>
);

export default StarSticker;
