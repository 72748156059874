import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import hashify from 'object-hash';
import gql from 'graphql-tag';
import * as Prismic from 'prismic-javascript/d.ts/client';
import { QueryOptions } from 'prismic-javascript/d.ts/ResolvedApi';

export enum ClientId {
  APOLLO = 'apollo',
  PRISMIC = 'prismic'
}

export interface ShopifyVariables {
  [variable: string]: any;
}

type ApiClientType = {
  cache: {
    query: {
      [hash: string]: unknown;
    };
    content: {
      [hash: string]: unknown;
    };
  };
  setRef: (
    clientId: ClientId,
    client: ApolloClient<NormalizedCacheObject> | Prismic.Client
  ) => void;
  query: (query: string, variables?: ShopifyVariables) => Promise<unknown>;
  mutate: (query: string, variables: ShopifyVariables) => Promise<unknown>;
  content: (query: any, options: QueryOptions) => Promise<unknown>;
  clients: {
    apollo: ApolloClient<NormalizedCacheObject> | null;
    prismic: Prismic.Client | null;
  };
};

const ApiClient: ApiClientType = {
  cache: {
    query: {},
    content: {}
  },
  clients: {
    apollo: null,
    prismic: null
  },
  setRef(
    clientId: ClientId,
    client: ApolloClient<NormalizedCacheObject> | Prismic.Client
  ) {
    if (clientId === ClientId.APOLLO) {
      this.clients[clientId] = client as ApolloClient<NormalizedCacheObject>;
    }

    if (clientId === ClientId.PRISMIC) {
      this.clients[clientId] = client as Prismic.Client;
    }
  },
  query(query: string, variables?: ShopifyVariables) {
    if (!this.clients.apollo)
      throw new Error('no apollo client found in ApiClient');

    const hashified = hashify(query);

    if (!!this.cache.query[hashified]) {
      return new Promise(resolve => resolve(this.cache.query[hashified]));
    }

    if (!!variables) {
      return this.clients.apollo
        .query({
          query: gql`
            ${query}
          `,
          variables
        })
        .then((val: unknown) => {
          this.cache.query[hashified] = val;

          return val;
        });
    }

    return this.clients.apollo
      .query({
        query: gql`
          ${query}
        `
      })
      .then((val: unknown) => {
        this.cache.query[hashified] = val;

        return val;
      });
  },
  mutate(query: string, variables: ShopifyVariables) {
    if (!this.clients.apollo)
      throw new Error('no apollo client found in ApiClient');

    return this.clients.apollo
      .mutate({
        mutation: gql`
          ${query}
        `,
        variables
      })
      .then((val: unknown) => {
        return val;
      });
  },
  content(query: any = '', options: QueryOptions) {
    const hashified = hashify({ query, options });

    if (!!this.cache.content[hashified]) {
      return new Promise(resolve => resolve(this.cache.content[hashified]));
    }

    if (this.clients.prismic) {
      return this.clients.prismic.query(query, options).then((val: unknown) => {
        this.cache.query[hashified] = val;

        return val;
      });
    }

    throw new Error('no prismic client found in ApiClient');
  }
};

export default ApiClient;
