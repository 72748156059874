import { createSelector } from 'reselect';
import get from 'lodash/get';

import {
  ShopifyProducts,
  ShopifyProductVariant,
  ShopifyProductOption,
  Image,
} from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): ShopifyProducts => {
    const products = get(response, 'data.products.edges', []).reduce(
      (sanitizedProducts: ShopifyProducts, product: unknown) => {
        const node = get(product, 'node', {});
        const id = get(node, 'id', '');
        const handle = get(node, 'handle', '');
        const title = get(node, 'title', '');
        const tags = get(node, 'tags', []);
        const options = get(node, 'options', []).reduce(
          (sanitizedOptions: ShopifyProductOption[], option: unknown) => {
            const id = get(option, 'id', '');
            const name = get(option, 'name', '').toLowerCase();
            const values = get(option, 'values', []).map((value: string) =>
              value.toLowerCase()
            );

            return sanitizedOptions.concat([
              {
                id,
                name,
                values,
              },
            ]);
          },
          []
        );
        const variants = get(node, 'variants.edges', []).reduce(
          (sanitizedVariants: ShopifyProductVariant[], variant: unknown) => {
            const variantNode = get(variant, 'node', {});
            const availableForSale = get(
              variantNode,
              'availableForSale',
              false
            );
            const id = get(variantNode, 'id', '');
            const price = parseFloat(get(variantNode, 'price.amount', '0.00'));
            const title = get(variantNode, 'title', '');
            const image = {
              src: get(variantNode, 'image.originalSrc', ''),
              credit: null,
              caption: get(variantNode, 'image.altText', ''),
            } as Image;
            const variantOptions = get(
              variantNode,
              'selectedOptions',
              []
            ).map((selectedOption: unknown) =>
              get(selectedOption, 'value', '')
            );
            const product = {
              handle: get(variantNode, 'product.handle', ''),
              id: get(variantNode, 'product.id', ''),
            };

            return sanitizedVariants.concat([
              {
                id,
                availableForSale,
                price,
                title,
                image,
                variantOptions,
                product,
              },
            ]);
          },
          []
        );

        sanitizedProducts[handle] = {
          id,
          handle,
          title,
          tags,
          options,
          variants,
        };

        return sanitizedProducts;
      },
      {}
    );

    return products;
  }
);
