import React from 'react';

import { Button } from 'components';

const ErrorPage: React.FC = () => (
  <div className="ErrorPage vh100 flex flex-col items-center justify-center text-center px1">
    <h2 className="col-12 md:col-5 mxauto mb1 text-large">
      Something's in the works
    </h2>
    <p className="col-12 md:col-4 mxauto mb3">
      You gotta break some eggs to make an omelette. Try refreshing in a little
      bit.
    </p>
    <Button
      onClick={() => window.location.reload()}
      ariaLabel="Reload"
      variant="primary"
      label="Reload"
    />
  </div>
);

export default ErrorPage;
