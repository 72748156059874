import { ShopifyProductVariant } from 'types';

const isSubscriptionVariant = (variant: ShopifyProductVariant) => {
  if (variant.title.toLowerCase().includes('subscription')) {
    return true;
  }

  return false;
};

export default isSubscriptionVariant;
