import React, { FC, useState } from 'react';

import { StarBurst, ProductListItem } from 'components';
import { Offer, Product, Checkout, ShopifyProductVariant } from 'types';

import parseOfferText from 'utils/parseOfferText';
import * as gtag from 'lib/gtag';

type Props = {
  offer: Offer;
  checkout: Checkout;
  products: { [key: string]: Product };
  onClick: (product: Product, variant?: string) => void;
};

const Upsell: FC<Props> = ({ offer, checkout, products, onClick }) => {
  const [hasClicked, setHasClicked] = useState(false);
  if (
    hasClicked ||
    !offer ||
    !products ||
    !checkout ||
    (offer.goal !== 0 && offer.goal <= checkout.totalPrice)
  ) {
    return null;
  }

  const variantsInCart = checkout.lineItems.map(
    (lineItem) => lineItem.variantId
  );
  const targetVariantsWithVariant: ShopifyProductVariant[] = offer.products.reduce(
    (targets: ShopifyProductVariant[], product) => {
      const variantTitle = product.variant;

      const selectedProduct = products[product.product.uid];
      if (!selectedProduct) return targets;

      const variant = selectedProduct.variants.find(
        (variant) => variant.title === variantTitle
      );

      return variant ? targets.concat([variant]) : targets;
    },
    []
  );
  const targetVariants: any[] = offer.products
    .filter((product) => !product.variant)
    .reduce((variants: ShopifyProductVariant[], product) => {
      return variants.concat(products[product.product.uid]?.variants);
    }, targetVariantsWithVariant);

  if (!targetVariants) return null;

  let variant = targetVariants.find(
    (variant) => !variantsInCart.includes(variant?.id || '')
  );

  const product = Object.values(products).find((product) => {
    const productVariants = product.variants.map((variant) => variant.id);

    return productVariants.includes(variant?.id || '');
  });

  if (product && !variant) variant = product.variants[0];
  if (!product || !variant) return null;

  const productTitle = product.title;
  const message = parseOfferText(
    offer.message,
    offer.goal,
    checkout.totalPrice,
    productTitle
  );

  return (
    <div className="Upsell absolute t0 l0 flex radius-xs">
      <div className="color-border color-border h100 w100">
        <div className="color-border__inner flex flex-col justify-between h100 pb1 overflow-hidden">
          <div className="Upsell__top-bar mxauto w100 overflow-hidden">
            <div className="Upsell__top-bar__marquee bg-color-teal">
              <div className="Upsell__top-bar__marquee__carousel flex width-auto items-center">
                <div className="Upsell__star-burst mr1">
                  <StarBurst />
                </div>
                <span className="text-medium color-cream transition uppercase viksjoe mr1">
                  Sale
                </span>
                <div className="Upsell__star-burst mr1">
                  <StarBurst />
                </div>
                <span className="text-medium color-cream transition uppercase viksjoe mr1">
                  Sale
                </span>
                <div className="Upsell__star-burst mr1">
                  <StarBurst />
                </div>
                <span className="text-medium color-cream transition uppercase viksjoe mr1">
                  Sale
                </span>
                <div className="Upsell__star-burst mr1">
                  <StarBurst />
                </div>
                <span className="text-medium color-cream transition uppercase viksjoe mr1">
                  Sale
                </span>
                <div className="Upsell__star-burst mr1">
                  <StarBurst />
                </div>
                <span className="text-medium color-cream transition uppercase viksjoe mr1">
                  Sale
                </span>
                <div className="Upsell__star-burst mr1">
                  <StarBurst />
                </div>
                <span className="text-medium color-cream transition uppercase viksjoe mr1">
                  Sale
                </span>
              </div>
            </div>
          </div>
          <div className="Upsell__info flex flex-col p1 items-center justify-center text-center">
            <p className="viksjoe uppercase">{message}</p>
          </div>
          <div className="px1">
            <ProductListItem
              product={product}
              variantId={variant?.id}
              trackClick={() => {
                gtag.event({
                  action: 'click',
                  category: 'upsell',
                  label: 'add',
                  value: product.title,
                });

                setHasClicked(true);
              }}
              addProduct={() => onClick(product, variant?.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upsell;
