import React, { Component } from 'react';
import Flickity from 'react-flickity-component';
import { RichText as PrismicRichText } from 'prismic-reactjs';

import { Img, Button } from 'components';
import { Image } from 'types';

export interface IRichTextPicks {
  type: 'rich_text_and_picks';
  data: {
    title: string;
    richText: any;
    picks: {
      image: Image;
      title: string;
      link: string;
    }[];
  };
}

interface Props {
  slice: IRichTextPicks;
}

/* @ts-ignore */
class RichTextPicks extends Component<Props> {
  private flickity: any = null;

  componentDidMount() {
    // hack to fix flickity
    setTimeout(() => {
      if (this && this.flickity && this.flickity.selectCell) {
        this.flickity.selectCell(0);
      }
    }, 0);
  }

  handleNext = (): void => {
    if (this.flickity && this.flickity.next) this.flickity.next();
  };

  handlePrevious = (): void => {
    if (this.flickity && this.flickity.previous) this.flickity.previous();
  };

  render() {
    const { slice } = this.props;

    return (
      <div className="RichTextPicks col-12 px1_5 md:px2 px1_5">
        <div className="RichTextPicks__container container-width mxauto flex flex-wrap">
          <div className="col-12 md:col-6 prismic-rich-text mb3">
            <PrismicRichText render={slice.data.richText} />
          </div>
          <div className="col-12 md:col-6 mb3">
            <div className="col-12 text-center mb2">
              <h2 className="text-medium-2">{slice.data.title}</h2>
            </div>
            <div className="col-12 overflow-hidden">
              <Flickity
                flickityRef={(c) => (this.flickity = c)}
                options={{
                  prevNextButtons: false,
                  pageDots: false,
                  wrapAround: true,
                  groupCells: 2,
                  adaptiveHeight: true,
                }}
              >
                {slice.data.picks.map((pick) => (
                  <div
                    className="RickTextPicks__pick col-6 px1 text-center"
                    key={pick.image.src}
                  >
                    <a
                      className="no-hover text-decoration-none"
                      href={pick.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="RichTextPicks__pick__img col-12 mb2">
                        <Img
                          className="block w100"
                          src={pick.image.src}
                          alt={pick.image.caption || ''}
                        />
                      </div>
                      <span className="viksjoe uppercase color-black underline">
                        {pick.title}
                      </span>
                    </a>
                  </div>
                ))}
              </Flickity>

              {slice.data.picks.length > 2 && (
                <div className="RichTextPicks__controls text-center my2">
                  <Button
                    className="FullWidthImageCarousel__controls__button FullWidthImageCarousel__controls__previous mx1"
                    variant="no-style"
                    ariaLabel="Go back to previous image"
                    onClick={this.handlePrevious}
                  >
                    <Img
                      alt="Left Arrow"
                      src="/assets/images/squared-arrow.svg"
                    />
                  </Button>
                  <Button
                    className="FullWidthImageCarousel__controls__button FullWidthImageCarousel__controls__next mx1"
                    variant="no-style"
                    ariaLabel="Advance to next image"
                    onClick={this.handleNext}
                  >
                    <Img
                      alt="Right Arrow"
                      src="/assets/images/squared-arrow.svg"
                    />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RichTextPicks;
