import React from 'react';

import { Img } from 'components';
import { Image } from 'types';

interface IconGridItem {
  text: string;
  image: Image;
}

export interface IIconGrid {
  type: 'icon_grid';
  data: {
    title: string;
    description: string;
    items: IconGridItem[];
  };
}

interface Props {
  slice: IIconGrid;
}

const IconGrid: React.FC<Props> = ({ slice }) => (
  <div className="IconGrid col-12 container-width px1 md:px2 mxauto mb3 md:mb6">
    <div className="col-12 text-center mb3">
      <h2 className="text-large mb1">{slice.data.title}</h2>
      <span className="mb1">{slice.data.description}</span>
    </div>
    <div className="IconGrid__icons col-12 flex justify-between items-center flex-wrap">
      {slice.data.items.map((item: IconGridItem) => (
        <div
          key={item.image.src}
          className="col-6 md:col-2 mb2 md:mb0 px_5 md:px0 text-center"
        >
          <div className="col-12 flex justify-center items-center mb1">
            <Img
              className="IconGrid__icon col-12"
              src={item.image.src}
              alt={item.image.caption || ''}
            />
          </div>
          <span className="text-label">{item.text}</span>
        </div>
      ))}
    </div>
  </div>
);

export default IconGrid;
