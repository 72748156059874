import { createSelector } from 'reselect';
import get from 'lodash/get';

import slices from 'state/serializers/slices';
import { Homepage, Slice } from 'types';

export default createSelector(
  (response: unknown) => response,
  (response: unknown): Homepage => {
    const result = get(response, 'results[0]', {});

    const body: Slice[] = slices(get(result, 'data.body', []));

    return {
      addHeroPadding: get(result, 'data.add_hero_padding', 'No') === 'No',
      slices: body
    };
  }
);
