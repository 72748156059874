import React, { Component } from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import Collapsible from 'react-collapsible';

interface Accordion {
  question: string;
  answer: string;
}

export interface IAccordions {
  type: 'accordions';
  data: {
    title: string;
    description: string;
    items: Accordion[];
  };
}

interface Props {
  slice: IAccordions;
}

class Accordions extends Component<Props> {
  render() {
    const { slice } = this.props;

    return (
      <div className="Accordions col-12 mxauto container-width px1_5 md:px2 mb3 md:mb6">
        {!!slice.data.title && (
          <div className="Accordions__info col-12 mb2 md:mb3">
            <h2 className="Accordions__title text-large mb_5 md:mb1">
              {slice.data.title}
            </h2>
            {!!slice.data.description && <p>{slice.data.description}</p>}
          </div>
        )}
        {slice.data.items.map((item: Accordion) => (
          <div className="Accordions__accordion mb1 md:mb1_5">
            <Collapsible
              triggerTagName="button"
              trigger={item.question}
              transitionTime={250}
              easing="ease-in-out"
              triggerClassName="Accordions__button teal Button--style-secondary hauto pointer text-medium viksjoe uppercase mb1 block"
              triggerOpenedClassName="Accordions__button Button--style-secondary--inverted hauto pointer text-medium viksjoe uppercase mb1 block"
            >
              <PrismicRichText render={item.answer} />
            </Collapsible>
          </div>
        ))}
      </div>
    );
  }
}

export default Accordions;
