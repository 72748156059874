import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import Prismic from 'prismic-javascript';
import get from 'lodash/get';

import ContainerBase from 'lib/ContainerBase';
import slugify from 'utils/slugify';

import BlankView from 'views/BlankView';

type Props = RouteComponentProps;

const linkResolver = (document: unknown) => {
  const type: string = get(document, 'type', '');
  const params = qs.parse(window.location.search.slice(1));

  if (type === 'read')
    return `/reads/${slugify(get(document, 'data.category', 'category'))}/${get(
      document,
      'uid',
      'uid'
    )}?token=${params.token}`;

  return '/404';
};

class PreviewContainer extends ContainerBase<Props, typeof BlankView, void> {
  view = import('views/BlankView');
  beforeModel = () => {
    const params = qs.parse(this.props.location.search.slice(1));

    return Prismic.getApi(process.env.REACT_APP_PRISMIC_ENDPOINT || '').then(
      (api) => {
        api
          .previewSession(params.token as string, linkResolver, '/')
          .then((url) => {
            this.props.history.push(url);
          });
      }
    );
  };
}

export default connect()(PreviewContainer);
